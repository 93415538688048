import { useCallback } from 'react';
import { ColumnTypeIdentifier, TableColumnType, TableRowType } from '../../../grid/reduxStore/table.types';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import PreviewEditableCellWrapper from './PreviewEditableCellWrapper';
import InputWithTooltips from '../Table/components/InputWithTooltips';
import { IsPricingCellWithRounding } from '../Table/helper';
import { isEligibleForStyling } from '../Table/useApplyCustomRulesToColumns';
import { TipTapRenderCellPreview } from '../../TiptapEditor/components/TipTapRenderCellPreview';

export function useApplyCustomRulesToColumnsInPreview(blockId: string, pricingDecimalPlaces: number) {
  const applyCustomRulesToColumnsInPreview = useCallback(
    (columns: TableColumnType[], updateRows: (updatedRow: TableRowType) => void, isDocumentLocked: boolean): TableColumnType[] => {
      let updatedColumns: TableColumnType[] = [...columns];
      updatedColumns = setPreviewCustomInputToDefaultColumn(updatedColumns, updateRows, isDocumentLocked);

      return updatedColumns;
    },
    []
  );

  const setPreviewCustomInputToDefaultColumn = (
    columns: TableColumnType[],
    updateRows: (updatedRow: TableRowType) => void,
    isDocumentLocked: boolean
  ): TableColumnType[] => {
    return columns.map((column) => ({
      ...column,
      renderEditCell: (params: GridCellParams) => {
        const { field, row } = params;
        if (IsPricingCellWithRounding(column.columnType, params.row.rowType)) {
          return (
            <InputWithTooltips
              onInputChange={(newValue) => {
                const updatedRow = { ...row, [field]: newValue };
                updateRows(updatedRow);
              }}
              params={params}
              blockId={blockId || ''}
              pricingDecimalPlaces={pricingDecimalPlaces}
            />
          );
        } else {
          return (
            <InputWithTooltips
              onInputChange={(newValue) => {
                const updatedRow = { ...row, [field]: newValue };
                updateRows(updatedRow);
              }}
              params={params}
              blockId={blockId || ''}
            />
          );
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const columnType = column.columnType;
        const isStylingApplicable = isEligibleForStyling(params.row.rowType, columnType);

        if (IsPricingCellWithRounding(columnType, params.row.rowType)) {
          return (
            <PreviewEditableCellWrapper
              updateRows={updateRows}
              params={params}
              isDocumentLocked={isDocumentLocked}
              columnType={params.colDef['columnType'] as ColumnTypeIdentifier}
              pricingDecimalPlaces={pricingDecimalPlaces}
            />
          );
        } else {
          if (isStylingApplicable && columnType !== ColumnTypeIdentifier.OPTIONAL) {
            return <TipTapRenderCellPreview isPreview id={params.id} row={params.row} field={params.field} value={params.value} />;
          }
          return (
            <PreviewEditableCellWrapper
              updateRows={updateRows}
              params={params}
              isDocumentLocked={isDocumentLocked}
              columnType={params.colDef['columnType'] as ColumnTypeIdentifier}
            />
          );
        }
      },
    }));
  };

  return { applyCustomRulesToColumnsInPreview };
}
