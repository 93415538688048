import { useTheme } from '@mui/material';
import { useEditor, EditorOptions, EditorEvents } from '@tiptap/react';
import { useEffect } from 'react';
import { getTipTapExtensions, setTipTapEditorConfig } from '../helpers/config';

import { ImageUploadOptions, ITextEditorOption } from '../types';
import { VariableEntity } from '../../../../../services/entities/VariablesEntity';
import { UsedVariable } from '../../../SidePanel/Variables/interfaces';
export type TextEditorProps = {
  placeholder?: string;
  blockId?: string;
  onChange?: (blockId: string, value: string) => void;
  value?: string;
  tab: 'editor' | 'preview';
  user?: ITextEditorOption;
  mentions?: ITextEditorOption[];
  userPathname?: string;
  uploadFileOptions?: Omit<ImageUploadOptions, 'type'>;
  variables?: VariableEntity[];
  onSetUsedVariables?: (variables: UsedVariable[]) => void;
} & Partial<EditorOptions>;

export const useTextEditor = ({
  blockId,
  onChange,
  value,
  tab,
  editable = true,
  variables = [],
  onSetUsedVariables,
  ...editorOptions
}: TextEditorProps) => {
  const theme = useTheme();

  const editor = useEditor({
    content: value,
    extensions: getTipTapExtensions({ variables, isPreview: tab === 'preview', onSetUsedVariables }),
    onUpdate: ({ editor }: EditorEvents['update']) => {
      if (blockId) {
        const html = editor.getHTML();
        onChange?.(blockId, html);
      }
    },
    ...editorOptions,
  });

  useEffect(() => {
    if (!editor) return;
    if (value && editor.isEmpty) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  useEffect(() => {
    setTipTapEditorConfig(editor, editable, tab);
  }, [editor, tab, editable, theme]);

  return editor;
};
