export const URLS = {
  document: '/editor',
  preview: '/preview',
  editDocument: function (documentId: string) {
    return `${this.document}/${documentId}`;
  },
  previewDocument: function (documentId: string) {
    return `${this.preview}/${documentId}`;
  },
  ssoConfiguration: '/settings/sso',
  pipeline: '/pipeline',
  cinderEditDocument: function (documentId: string) {
    return `/proposal/edit/${documentId}`;
  },
  library: '/library',
  templateEdit: function (templateId: string) {
    return `/editor/template/${templateId}`;
  },
  errorForbiddenAccess: '/errors/document-no-access',
};
