import TextField from '@mui/material/TextField';
import { Button, Paper, styled } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InputAdornment from '@mui/material/InputAdornment';
import { useRef, useState } from 'react';
import { useActiveEditor } from '../../ActiveEditorContext';
import { isEmailLike, isValidEmail, isValidURL } from 'components/editor/helpers/InputValidation';

export const TextFieldWrapper = styled(TextField)({
  border: '0',
  width: '352px',
  padding: '0px 0px',
  marginRight: '8px',
  '& .MuiInputBase-root': { height: '40px' },
});

interface HyperlinkInputProps {
  onClose: () => void;
}

const HyperlinkInput = ({ onClose }: HyperlinkInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { activeEditor: editor } = useActiveEditor();
  const [value, setValue] = useState(editor?.getAttributes('link').href || '');
  const [error, setError] = useState<string>('');
  if (!editor) return null;

  const errorModel = (url: string): boolean => {
    if (url === '') return false;

    if (!isValidURL(url) && !isEmailLike(url)) {
      setError('Please enter a valid URL.');
      return true;
    } else if (isEmailLike(url) && !isValidEmail(url)) {
      setError('Please enter a valid email.');
      return true;
    }

    setError('');

    return false;
  };

  const handleApplyLink = () => {
    if (inputRef.current) {
      let formattedUrl = '';
      const url = inputRef.current.value;

      if (!url) {
        editor.commands.unsetLink();
        return;
      }

      if (isEmailLike(url)) {
        formattedUrl = isEmailLike(url) && !url.startsWith('mailto:') ? `mailto:${url}` : url;
      } else {
        formattedUrl = inputRef.current.value.startsWith('https://') ? inputRef.current.value : `https://${inputRef.current.value}`;
      }

      const invalidInput = errorModel(formattedUrl);

      if (!invalidInput) {
        editor.chain().focus().extendMarkRange('link').setLink({ href: formattedUrl, target: '_blank' }).run();
        onClose();
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ width: '480px', height: '83px', padding: '12px', left: '0px' }} variant={'elevation'}>
      <TextFieldWrapper
        label="Link"
        inputRef={inputRef}
        id="outlined-start-adornment"
        placeholder="  https://proposify.com"
        helperText={error ? error : 'External links will open in a new tab.'}
        value={value}
        error={error !== ''}
        onInput={(e) => setValue((e.target as HTMLInputElement).value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <InsertLinkIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button onClick={handleApplyLink} variant="text" sx={{ width: '100px', height: '36px', padding: '6px 8px', borderRadius: '100px' }}>
        Apply link
      </Button>
    </Paper>
  );
};

export default HyperlinkInput;
