import { ListItemText, Menu, MenuItem } from '@mui/material';
import IcoMeatball from 'components/icon/icons/IcoMeatball';
import { useState } from 'react';
import { useOrderedSections } from './useOrderedSections';

type SectionMenuProps = {
  id: string;
  index: number;
  isActive: boolean;
};

export default function SectionMenu({ index, isActive, id }: SectionMenuProps) {
  const { reorder, sections } = useOrderedSections();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<SVGSVGElement | null>(null);

  const onMoveUp = () => {
    reorder(index, index - 1.5);
    setIsMenuOpen(false);
  };

  const onMoveDown = () => {
    reorder(index, index + 1.5);
    setIsMenuOpen(false);
  };
  return (
    <>
      <IcoMeatball
        data-testid={`section-${id}-meatball-icon`}
        className={`section-${id}-meatball-icon`}
        style={{
          marginLeft: '0.2rem',
          flexShrink: 0,
          width: '1.5rem',
          height: '1.5rem',
          display: 'block',
          opacity: isMenuOpen ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
          color: isActive ? '#fff' : '#353636',
        }}
        onClick={(e) => {
          e.stopPropagation();
          setMenuAnchorEl(e.currentTarget as SVGSVGElement);
          setIsMenuOpen(true);
        }}
      />
      <Menu data-testid="section-menu" open={isMenuOpen} onClose={() => setIsMenuOpen(false)} anchorEl={menuAnchorEl}>
        <MenuItem onClick={onMoveUp} disabled={index === 0} data-testid="move-up-button">
          <ListItemText>Move Up</ListItemText>
        </MenuItem>
        <MenuItem onClick={onMoveDown} disabled={index === sections.length - 1} data-testid="move-down-button">
          <ListItemText>Move Down</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
