import { usePermissionsContextValue } from 'providers/PermissionsProvider';
import { useState } from 'react';
import { Document } from 'services/documents/Document';
import { UserPermission } from '../../../services/permissions/entities/UserPermissions';
import { DocumentAvatar } from '../components/documents-list/DocumentAvatar';
import { DocumentCreatedDate } from '../components/documents-list/DocumentCreatedDate';
import { DocumentListItem as BaseDocumentListItem } from '../components/documents-list/DocumentListItem';
import { DocumentRightItems } from '../components/documents-list/DocumentRightItems';
import { DocumentTitle } from '../components/documents-list/DocumentTitle';
import { DocumentStatusChip } from '../components/DocumentStatusChip';
import { getAssignedProspect } from '../utils/getAssignedProspect';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import { useGoToDocument } from '../utils/useGoToDocument';
import { DuplicateDocumentAction } from './document-actions/DuplicateDocumentAction';
import { InsightsAction } from './document-actions/InsightsAction';
import { MoreDocumentAction } from './document-actions/MoreDocumentAction';
import { TrashDocumentAction } from './document-actions/TrashDocumentAction';

interface DocumentListItemProps {
  document: Document;
  refreshDocumentList: () => Promise<void>;
  viewCreatedDocument: (createdDocumentId: string) => void;
}

export function DocumentListItem({ document, refreshDocumentList, viewCreatedDocument }: DocumentListItemProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { formatDocumentDateTime } = useFormatDateTime();
  const { goToDocument } = useGoToDocument({ document });
  const { userPermissions } = usePermissionsContextValue();
  const hideActionsMenu = userPermissions.includes(UserPermission.isCollaborator);

  return (
    <BaseDocumentListItem
      dataTestId={`list-item-${document.id}`}
      status={document.status}
      setIsHovered={setIsHovered}
      onClick={goToDocument}
    >
      <DocumentTitle title={document.title} subtitle={getAssignedProspect(document)} isV2={document.editorVersion === 2} />
      <DocumentCreatedDate date={formatDocumentDateTime(document.created_at)} show={!isHovered && !hideActionsMenu} />
      <DocumentAvatar document={document} show={!isHovered && !hideActionsMenu} />

      {document.editorVersion === 2 ? (
        <DocumentRightItems>
          <DocumentStatusChip status={document.status} />
        </DocumentRightItems>
      ) : (
        <DocumentRightItems>
          {(!isHovered || hideActionsMenu) && <DocumentStatusChip status={document.status} />}
          {isHovered && !hideActionsMenu && <InsightsAction documentId={document.id} refreshDocumentList={refreshDocumentList} />}
          {isHovered && !hideActionsMenu && (
            <DuplicateDocumentAction documentToDuplicate={document} viewCreatedDocument={viewCreatedDocument} />
          )}
          {isHovered && !hideActionsMenu && <TrashDocumentAction documentToTrash={document} />}
          {!hideActionsMenu ? (
            <MoreDocumentAction
              documentId={document.id}
              documentStatus={document.status}
              documentTitle={document.title}
              refreshDocumentList={refreshDocumentList}
              onMenuClose={() => setIsHovered(false)}
            />
          ) : null}
          <></>
        </DocumentRightItems>
      )}
    </BaseDocumentListItem>
  );
}
