import React, { KeyboardEvent } from 'react';
import { InputNumber } from 'antd';
import './styles.less';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

interface Props {
  onChange: (value: number | null) => void;
  value: number;
  onBlur: React.FocusEventHandler;
  onError?: (value: string | null) => void;
  errorClass?: string | null;
  parseValue?: (value: string | undefined) => number;
  formatter?: (value: number | undefined) => string;
}

const SHADOW_INDEX = 1;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows ? theme.shadows[SHADOW_INDEX] : 'none',
      fontSize: 14,
    },
  })
);

const PriceInput = ({ onChange, errorClass, value, onBlur, formatter, parseValue }: Props) => {
  const { t } = useTranslation();
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const isNumericInput = /^-?\d*\.?\d*$/.test(event.key);
    const isAllowedSpecialKey = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Enter'].includes(event.key);

    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }

    if (
      !isNumericInput &&
      !isAllowedSpecialKey &&
      !(event.key === '.' && !value.includes('.')) &&
      !(event.key === '-' && !value.startsWith('-'))
    ) {
      event.preventDefault();
    }
  };

  return (
    <LightTooltip title={t('editor.editor_menu.pricing_total_tooltip')} placement="bottom">
      <InputNumber
        data-testid="document-price"
        placeholder="0.00"
        defaultValue={0}
        className={`price-input ${errorClass}`}
        onKeyDown={handleKeyDown}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={21}
        formatter={formatter}
        parser={parseValue}
        value={value}
      />
    </LightTooltip>
  );
};

PriceInput.displayName = 'PriceInput';

export default PriceInput;
