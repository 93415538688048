import { defaultFontFamily } from 'muiTheme/lightTheme';

export const TOOLBAR_SPACING = {
  padding: '6px 2px 6px 6px ',
  borderRadius: '8px',
};
export const TOOLBAR_DIMENSION = {
  width: '1440px',
  height: '32px',
};

export const TOOLBAR_COLORS = {
  secondary: 'rgba(0, 40, 58, 0.6)',
  primary: 'rgba(0, 40, 58, 0.87)',
  disabledFont: 'rgba(0, 40, 58, 0.38)',
  hoverColor: 'rgba(0, 40, 58, 0.04)',
  focusColor: 'rgba(0, 40, 58, 0.08)',
};

export const TOOLBAR_STATE = {
  '&:enabled': {
    backgroundColor: 'transparent',
    color: TOOLBAR_COLORS.secondary,
  },
  '&:hover': {
    backgroundColor: TOOLBAR_COLORS.hoverColor,
    color: TOOLBAR_COLORS.secondary,
  },
  '&:focus': {
    backgroundColor: TOOLBAR_COLORS.focusColor,
    color: TOOLBAR_COLORS.primary,
  },
  '&:active': {
    backgroundColor: TOOLBAR_COLORS.focusColor,
    color: TOOLBAR_COLORS.primary,
  },
  '&:disabled': {
    backgroundColor: 'transparent',
    color: TOOLBAR_COLORS.disabledFont,
  },
};

export const TOOLBAR_FONT = {
  fontFamily: `'${defaultFontFamily}', sans-serif`,
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: TOOLBAR_COLORS.secondary,
};
