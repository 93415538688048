import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useSeatsInviteForm,
  SubmitHandler,
  transformSeatsFieldValuesToApiPayload,
  SeatsInviteFormFieldValues,
} from '../utils/useSeatsInviteForm';
import FormField from '../utils/FormField';
import { useCallback, useContext } from 'react';
import { PlanSummaryContext } from './PlanSummaryProvider';
import { parseUnformattedApiErrors } from '../utils/utils';
import useSeatInviteMutation from 'services/api/seats/hooks/useSeatInviteMutation';
import { ClickAwayListener } from '@mui/material';

interface SeatsInviteFormProps {
  seatType: 'collaborator' | 'user';
  onCancel: () => void;
  onSuccess: () => void;
}

const SeatsInviteForm = ({ seatType, onSuccess, onCancel }: SeatsInviteFormProps) => {
  const { t } = useTranslation();

  const { accountId, refetchContact } = useContext(PlanSummaryContext);
  const { handleSubmit, control, setError, formState, getValues } = useSeatsInviteForm({
    email: '',
    accountId: accountId,
    seatType: seatType,
  });
  const { seatInvite, isLoading: isUpdating } = useSeatInviteMutation();

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformSeatsFieldValuesToApiPayload(submitData);
      const response = await seatInvite({ payload: payload, accountId });
      if (response.state) {
        refetchContact();
        onSuccess();
        return;
      }
      const parsedErrors = parseUnformattedApiErrors(response.message);
      const errorMessage = parsedErrors.map((err) => t(err.message)).join(', ');

      if (errorMessage) {
        setError('email', { message: errorMessage });
      }
    },
    [transformSeatsFieldValuesToApiPayload, seatInvite, onSuccess, accountId]
  );

  const getIsLoading = useCallback(() => {
    return isUpdating;
  }, [isUpdating]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => {
        Object.keys(formState.touchedFields).length === 0 && onCancel();
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} data-testid="seat-add-form">
        <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
          <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
            <FormField<SeatsInviteFormFieldValues>
              id="email"
              label={t('settings.summary.seat_invite_form.email')}
              placeholder={t('settings.summary.seat_invite_form.email')}
              control={control}
              shrinkLabel={true}
              rules={{
                required: t('required_field'),
                pattern: {
                  value: /[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,16})/g,
                  message: t('settings.summary.seat_invite_form.email_invalid'),
                },
              }}
              disabled={getIsLoading()}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignContent="center" pt={3} gap={1}>
          <Button variant="text" size="medium" onClick={onCancel} disabled={isUpdating} data-testid="seat-add-cancel">
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            onClick={() => (getValues('email') !== '' ? handleSubmit(onSubmit) : onCancel())}
            disabled={getIsLoading()}
            data-testid="seat-add-form-submit"
          >
            {isUpdating ? t('saving') : t('invite')}
          </Button>
        </Stack>
      </Box>
    </ClickAwayListener>
  );
};

export default SeatsInviteForm;
