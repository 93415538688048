import { PyMuiMenu, PyMuiMenuItem, PyMuiIconButton } from '../components/generalComponents';
import React, { useCallback, useRef, useState } from 'react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import HyperlinkInput from '../components/Link/HyperLinkInput.tsx';
import Icon from '../components/Icon.tsx';
import { useActiveEditor } from '../ActiveEditorContext.tsx';

const HyperLink = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { activeEditor: editor } = useActiveEditor();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleOpenMenu = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  if (!editor) return null;

  return (
    <>
      <PyMuiIconButton
        ref={buttonRef}
        attributeName={'link'}
        isMenuOpen={Boolean(anchorEl)}
        onClick={handleOpenMenu}
        isActive={editor.isActive('link')}
      >
        <Icon>
          <InsertLinkIcon />
        </Icon>
      </PyMuiIconButton>

      <PyMuiMenu
        testId="hyperlink-menu"
        id="select-hyperlink-menu"
        menuListProps="test"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleCloseMenu}
        sx={{ transform: 'translateX(-448px)', '& .MuiList-root': { padding: 0 } }}
        direction="row"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        overrideShadow={false}
      >
        <PyMuiMenuItem key={'hyperlink-menu-item'} testId={'hyperlink-menu-item'} menuItem={<HyperlinkInput onClose={handleCloseMenu} />} />
      </PyMuiMenu>
    </>
  );
};

export default HyperLink;
