import { GridRowId } from '@mui/x-data-grid-pro';
import { TableRowType } from '../../../grid/reduxStore/table.types';

import TextEditor from './TextEditor';
import { usePreviewVariables } from '../../../../../providers/PreviewVariablesContext';

interface CustomTableCellProps {
  id: GridRowId;
  row: TableRowType;
  field: string;
  value: string;
  isPreview: boolean;
}

export const TipTapRenderCellPreview = ({ id, field, value, row, isPreview }: CustomTableCellProps) => {
  const rowType = row.rowType.toLowerCase();
  const { variables } = usePreviewVariables();

  if (!id) {
    return <></>;
  }

  return (
    <div data-testid={`tiptap-render-cell-wrapper-${rowType}`} className={`editable-cell-wrapper editable-cell-${id} ${rowType}`}>
      <TextEditor isPreview={isPreview} editable={false} value={value} field={field} id={id as string} variables={variables} />
    </div>
  );
};
