import { CSSObject } from '@emotion/react';

export const tiptapDefaultStyle: CSSObject = {
  '.tiptap': {
    '&.ProseMirror': {
      width: '100%',

      '&.ProseMirror-focused': {
        outlineWidth: 0,
      },
      'p, h1, h2, h3, h4, h5, h6': {
        marginBottom: 12,
      },
    },
    mark: {
      padding: '0.1rem 0.3rem',
    },
  },
  '.editable-cell-wrapper': {
    '.tiptap': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
  },
};
