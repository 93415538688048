import { IconButton } from '@mui/material';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { TOOLBAR_COLORS, TOOLBAR_DIMENSION, TOOLBAR_SPACING, TOOLBAR_STATE } from '../../helpers/toolbarStyleSettings';

const getBackgroundColor = (active?: boolean) => {
  if (active) {
    return {
      backgroundColor: TOOLBAR_COLORS.focusColor,
      color: TOOLBAR_COLORS.primary,
    };
  }
  return TOOLBAR_STATE;
};

const classes = {
  buttonIcon: ({
    isActive,
    outlinedColorWhenActive,
    customCss,
  }: {
    isActive?: boolean;
    outlinedColorWhenActive?: string;
    customCss?: Record<string, string | number>;
  }) => {
    return css({
      borderRadius: TOOLBAR_SPACING.borderRadius,
      padding: '4px',
      height: TOOLBAR_DIMENSION.height,
      width: TOOLBAR_DIMENSION.height,
      ...getBackgroundColor(isActive && !outlinedColorWhenActive),
      ...customCss,
      ':enabled': {
        color: outlinedColorWhenActive && isActive ? outlinedColorWhenActive : TOOLBAR_COLORS.secondary,
      },
    });
  },
};

interface SpMuiIconButtonProps {
  isActive?: boolean;
  outlinedColorWhenActive?: string;
  attributeName?: string;
  onClick?: (e?) => void;
  disabled?: boolean;
  isMenuOpen?: boolean;
  children: React.ReactNode;
  customCss?: Record<string, string | number>;
}

const PyMuiIconButton = forwardRef<HTMLButtonElement, SpMuiIconButtonProps>(
  ({ customCss, onClick, disabled, children, isActive, attributeName, outlinedColorWhenActive }, ref) => {
    return (
      <IconButton
        data-testid={`toolbar-${attributeName}-button`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        sx={classes.buttonIcon({ isActive: isActive, outlinedColorWhenActive, customCss })}
      >
        {children}
      </IconButton>
    );
  }
);

PyMuiIconButton.displayName = 'PyMuiIconButton';

export default PyMuiIconButton;
