import TiptapLink, { isAllowedUri } from '@tiptap/extension-link';
import { mergeAttributes } from '@tiptap/react';

export const EditorLink = TiptapLink.extend({
  renderHTML({ HTMLAttributes }) {
    const clickAttribute = { onclick: 'event.preventDefault();' };
    // prevent XSS attacks
    if (
      !this.options.isAllowedUri(HTMLAttributes.href, {
        defaultValidate: (href) => !!isAllowedUri(href, this.options.protocols),
        protocols: this.options.protocols,
        defaultProtocol: this.options.defaultProtocol,
      })
    ) {
      // strip out the href
      return ['a', mergeAttributes(this.options.HTMLAttributes, { ...HTMLAttributes, href: '' }, clickAttribute), 0];
    }

    return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, clickAttribute), 0];
  },
}).configure({
  openOnClick: false,
  defaultProtocol: 'https',
});

export const PreviewLink = TiptapLink.configure({
  openOnClick: true,
  defaultProtocol: 'https',
});
