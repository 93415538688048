import { SubmitHandler as SubmitHandlerT, useForm } from 'react-hook-form';
import { UpdateWorkspaceEntity } from 'services/api/account/interfaces';

export type CreateYourWorkspaceFormFieldValues = {
  accountId: number;
  companyName: string;
  subdomainName: string;
};

export const transformCreateYourWorkspaceFieldValuesToApiPayload = ({
  accountId,
  companyName,
  subdomainName,
}: CreateYourWorkspaceFormFieldValues): UpdateWorkspaceEntity => {
  return {
    accountId,
    companyName,
    subdomainName,
  };
};

export type SubmitHandler = SubmitHandlerT<CreateYourWorkspaceFormFieldValues>;

export function useCreateYourWorkspaceForm(loadedValues?: CreateYourWorkspaceFormFieldValues) {
  return useForm<CreateYourWorkspaceFormFieldValues>({
    defaultValues: {
      companyName: '',
      subdomainName: '',
    },
    values: loadedValues || undefined,
  });
}
