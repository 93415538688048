import { Box, Drawer } from '@mui/material';
import { useSectionsDrawer } from '../SectionsDrawer/SectionsDrawerContext';

const TOP_BAR_HEIGHT = '7.7rem';

const SIDE_PANEL_Z_INDEX = 201; // because editor content's z-index is @z-index-level2 = 200

const RIGHT_PANEL_WIDTH = '318px';
const LEFT_PANEL_WIDTH = '13.75rem';

export function DocumentBuilderRightPanel({ children }: { children: React.ReactNode }) {
  return (
    <Drawer
      open
      className="editor-right-sider"
      sx={{
        width: RIGHT_PANEL_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          overflow: 'hidden',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          marginTop: TOP_BAR_HEIGHT,
          zIndex: SIDE_PANEL_Z_INDEX,
          borderLeft: 'none',
          width: RIGHT_PANEL_WIDTH,
        },
      }}
      variant="persistent"
      anchor="right"
    >
      {children}
    </Drawer>
  );
}

export function DocumentBuilderLeftPanel({ children }: { children: React.ReactNode }) {
  const { isSectionsDrawerOpen } = useSectionsDrawer();

  // note: do NOT return null for the open/close state. it will break the animation.

  return (
    <Drawer
      className="editor-left-sider"
      open={isSectionsDrawerOpen} // this is the way for the animation to work.
      sx={{
        width: LEFT_PANEL_WIDTH,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          backgroundColor: '#f3f3f6',
          marginTop: TOP_BAR_HEIGHT,
          zIndex: SIDE_PANEL_Z_INDEX,
          borderLeft: 'none',
          marginLeft: 'var(--sidebar-width)',
          width: LEFT_PANEL_WIDTH,
          boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10) inset',
          overflowX: 'hidden',
          '@media (max-width: 998px)': {
            marginLeft: 0,
          },
        },
      }}
      variant="persistent"
      anchor="left"
    >
      {children}
    </Drawer>
  );
}

export function DocumentBuilderMainContent({ children }: { children: React.ReactNode }) {
  const { isSectionsDrawerOpen } = useSectionsDrawer();

  return (
    <Box
      className="editor-document-builder"
      sx={{
        paddingTop: '140px',
        paddingBottom: '140px',
        marginRight: RIGHT_PANEL_WIDTH,
        marginLeft: isSectionsDrawerOpen ? LEFT_PANEL_WIDTH : 0,
        transition: 'margin-left 0.3s ease',
      }}
    >
      {children}
    </Box>
  );
}
