import { IconButton } from '@mui/material';

import { GridBlockType } from 'components/editor/shared/gridBlockType';
import IcoTune from '../../../icon/icons/IcoTune';
import { useOpenBlockSettings } from './useOpenBlockSettings';

interface BlockSettingsProps {
  type: GridBlockType;
  blockId: string | null;
}

function BlockSettings({ type, blockId }: BlockSettingsProps) {
  const classValue = type?.toUpperCase();
  const iconButtonSX = {
    width: '28px',
    height: '28px',
    color: 'white',
  };
  const { openBlockSettings } = useOpenBlockSettings();

  return (
    <IconButton
      size={'small'}
      sx={iconButtonSX}
      onClick={() => {
        openBlockSettings(type, blockId);
      }}
      data-testid={`${classValue}-settings-icon`}
    >
      <IcoTune fontSize="inherit" />
    </IconButton>
  );
}

export default BlockSettings;
