import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';
import NoAccess from './images/no-access-icon.png';
import { URLS } from '../../utils/urls';

const NoAccessDocumentErrorPage = () => {
  const { t } = useTranslation();

  return (
    <ErrorPage
      testId="no-access-document-error-page"
      title={t('errors.no_access_document.title')}
      content={t('errors.no_access_document.content')}
      image={<img src={NoAccess} width="100%" alt="No Access" />}
      action={{
        path: URLS.pipeline,
        text: t('errors.no_access_document.action'),
      }}
    />
  );
};

export default NoAccessDocumentErrorPage;
