import { ConsoleLogger } from 'utils/ConsoleLogger';
import { isEmpty } from 'lodash';
import { analyticsClient } from '../api';
import { AnalyticsRepository, AnalyticsResponse, TrackingPayload } from './AnalyticsRepository';
import { validateAnalyticsResponse } from './validation';

const getAnalyticsByDocumentId = async (documentId?: string): Promise<AnalyticsResponse> => {
  const response = await analyticsClient.get<AnalyticsResponse>(`/document/${documentId}`, {
    validateStatus: function (status) {
      return status >= 200 && status < 400; // Treat 2xx and 3xx as successful
    },
  });

  if (isEmpty(response)) {
    return {
      isEmpty: true,
      viewers: [],
      updatedAt: '',
    };
  }

  validateAnalyticsResponse(response);

  return response;
};

const postTracking = async (payload: TrackingPayload): Promise<void> => {
  ConsoleLogger.log('ApiAnalyticsRepository.postTracking ', JSON.stringify(payload, null, 2));

  try {
    await analyticsClient.post('/preview/events', payload);
  } catch (error) {
    ConsoleLogger.error('ApiAnalyticsRepository.postTracking error', error);
  }
};

export const ApiAnalyticsRepository: AnalyticsRepository = {
  getAnalyticsByDocumentId: getAnalyticsByDocumentId,
  postTracking,
};
