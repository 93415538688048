import { useEffect } from 'react';

export function openInsights(documentId: string) {
  document.dispatchEvent(new CustomEvent('insights:opened', { detail: { documentId } }));
}

export function useOnInsightsClose(callback: (isExpanded: boolean) => void) {
  useEffect(() => {
    document.addEventListener('insights:closed', (e) => callback(e.detail.isExpanded));
    return () => document.removeEventListener('insights:closed', (e) => callback(e.detail.isExpanded));
  }, []);
}
