import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CINDER_URLS } from 'utils/cinder-urls';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useNavigate } from 'react-router-dom';
import { CreateDocumentModal } from '../create-document-modal';
import { Menu, MenuItem, Button, Typography, Box } from '@mui/material';
import IcoAdd from 'components/icon/icons/IcoAdd';
import { BetaChip } from '../BetaChip';
import { isPageAllowed, isCollaboratorPermission } from 'services/permissions/helpers/PagesPermission';
import { usePermissionsContextValue } from '../../../../providers/PermissionsProvider';

interface DocumentButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CreateDocumentButton = forwardRef<HTMLButtonElement, DocumentButtonProps>(function DocumentButton({ onClick }, ref) {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="create-document-button"
      onClick={onClick}
      variant="contained"
      sx={{
        height: '60px',
        width: '187px',
        borderRadius: '16px',
        marginBottom: '32px',
        marginTop: '12px',
        gap: '8px',
        padding: '16px 20px 16px 20px',
      }}
      ref={ref}
    >
      <IcoAdd height={24} width={24} />
      <Typography variant="h6" fontSize="14px" color="#FFFFFF">
        {t('pipeline.create_document_button.title')}
      </Typography>
    </Button>
  );
});
export function CreateDocument() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userPermissions } = usePermissionsContextValue();

  const featureFlags = useFeatureFlag([FeatureFlags.editorV3]);
  const isEditorV3Enabled = featureFlags[FeatureFlags.editorV3];

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const toggleModal = () => setOpenModal((previousValue) => !previousValue);
  const documentButtonRef = useRef<HTMLButtonElement | null>(null);

  const isCollaborator = isPageAllowed({ userPermissions, allowedPermissions: isCollaboratorPermission });

  if (isCollaborator) {
    return null;
  }

  return isEditorV3Enabled ? (
    <>
      <CreateDocumentButton onClick={handleMenuOpen} ref={documentButtonRef} />
      <Menu data-testid="create-document-button-menu" anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
        <MenuItem data-testid="create-document-button-v2" onClick={() => navigate(CINDER_URLS.createDocument)} sx={{ paddingLeft: '70px' }}>
          <Typography variant="body1">{t('pipeline.create_document_button.dropdown_editor_v2')}</Typography>
        </MenuItem>
        <MenuItem data-testid="create-document-button-v3" onClick={toggleModal}>
          <Box display="flex" alignItems="center" gap="6px">
            <BetaChip />
            <Typography variant="body1">{t('pipeline.create_document_button.dropdown_editor_v3')}</Typography>
          </Box>
        </MenuItem>
      </Menu>
      <CreateDocumentModal open={openModal} handleClose={toggleModal} />
    </>
  ) : (
    <CreateDocumentButton onClick={() => navigate(CINDER_URLS.createDocument)} />
  );
}
