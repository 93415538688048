import React, { useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { Heading } from 'components/typography';
import { isAuthenticated } from 'utils/authentication';
import { useMediaQuery } from 'hooks/useMediaQuery';

import './styles.less';
import { PreviewActionMenu } from '../action-menu/action-menu';
import { ApiUserPermissionsRepository } from 'services/permissions/implementations/ApiUserPermissionsRepository';
import { UserPermission } from '../../../services/permissions/entities/UserPermissions';
import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';

interface PreviewMenuProps {
  documentId: string;
  documentTitle: string;
  documentProspectName: string;
  documentPrice: number;
  isDocumentBelongsToSameAccount: boolean;
}

const PreviewMenu: React.FC<PreviewMenuProps> = ({
  documentId,
  documentTitle,
  documentProspectName,
  documentPrice,
  isDocumentBelongsToSameAccount,
}) => {
  const { isMobile } = useMediaQuery();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { data: userPermissions } = useQuery<UserPermission[]>({
    queryKey: QueryKeys.currentUserPermissions,
    queryFn: () => new ApiUserPermissionsRepository().getUserPermissions(),
    enabled: isAuthenticated(),
  });

  const handleDocumentTitleClick = () => {
    setIsTooltipVisible(true);
  };

  const hideTooltipOnScroll = () => {
    setIsTooltipVisible(false);
  };

  const hideTooltipOnClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.preview__header')) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', hideTooltipOnScroll);
    document.addEventListener('touchmove', hideTooltipOnScroll);
    document.addEventListener('click', hideTooltipOnClick);

    return () => {
      document.removeEventListener('wheel', hideTooltipOnScroll);
      document.removeEventListener('touchmove', hideTooltipOnScroll);
      document.removeEventListener('click', hideTooltipOnClick);
    };
  }, []);
  const canShareDocument = userPermissions && !userPermissions.includes(UserPermission.isCollaborator);
  const canViewActionMenu = isAuthenticated() && isDocumentBelongsToSameAccount && canShareDocument;

  return (
    <Row justify="space-between" wrap={false} align="middle" className="preview__header__content">
      <Col className="preview_header__column--left">
        <Tooltip placement="bottom" title={documentTitle} open={isMobile ? isTooltipVisible : false}>
          <div onClick={handleDocumentTitleClick}>
            <Heading level={3}>{documentTitle}</Heading>
          </div>
        </Tooltip>
        <div className="preview_header__subheading">
          <div className="preview_header__subheading_block">
            {documentPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </div>
        </div>
      </Col>
      <Col className="preview_header__column--right">
        {canViewActionMenu ? (
          <PreviewActionMenu documentId={documentId} documentProspectName={documentProspectName} documentTitle={documentTitle} />
        ) : null}
      </Col>
    </Row>
  );
};

export default PreviewMenu;
