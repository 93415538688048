import { useSelector } from 'react-redux';
import { type Section } from '../Sections/Section.model';
import { useAppDispatch, type RootState } from '../grid/reduxStore/Store';
import { reorderSections } from '../grid/reduxStore/editorSlice';
import { usePublishSectionsReorder } from './usePublishSectionsReorder';

export type SimpleSection = {
  id: string;
  order: number;
  title: string;
};

type UseOrderedSectionsProps = {
  sections: Section[];
  reorder: (fromIndex: number, toIndex: number) => void;
};

const sectionsSelector = (state: RootState) => state.gridBlockReducer.sections;

export const useOrderedSections = (): UseOrderedSectionsProps => {
  const dispatch = useAppDispatch();
  const sections = useSelector(sectionsSelector);
  const publishReorder = usePublishSectionsReorder();

  /**
   * @param fromIndex Initial index of the section being dragged
   * @param toIndex Target position between two sections (e.g., 2.5 means between indices 2 and 3)
   */
  const reorder = (fromIndex: number, toIndex: number) => {
    if (toIndex % 1 === 0) throw new Error('toIndex must be a half-integer');
    if (fromIndex % 1 !== 0) throw new Error('fromIndex must be an integer');

    const newSectionsSequence = sections.map((section, index) => ({ id: section.id, index }));
    const draggedSection = newSectionsSequence[fromIndex];
    draggedSection.index = toIndex;
    newSectionsSequence.sort((a, b) => a.index - b.index);

    dispatch(reorderSections({ newSectionsSequence }));
    publishReorder({
      id: sections[fromIndex].id,
      title: sections[fromIndex].title,
      moveAfterSectionId: sections[toIndex - 0.5]?.id,
    });
  };

  return { sections, reorder };
};
