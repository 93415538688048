/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Editor } from '@tiptap/react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Node } from '@tiptap/pm/model';

import Icon from '../components/Icon';
import IcoArrowDropDownRounded from 'components/icon/icons/IcoArrowDropDownRounded';

import PyMuiButton from '../components/generalComponents/PyMuiButton';
import PyMuiMenu from '../components/generalComponents/PyMuiMenu';
import PyMuiMenuItem from '../components/generalComponents/PyMuiMenuItem';
import { useInstalledFonts } from '../../../../../hooks/useInstalledFonts';
import { createTextStyleHandler } from '../helpers/textStyleHelpers';
import { useEditorContent } from '../../../../../providers/EditorContentContext.tsx';
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_FAMILY_OPTIONS } from 'components/editor/grid/reduxStore/defaultTextDefaultValue.ts';
import PyMuiMenuLabel from '../components/generalComponents/PyMuiMenuLabel';
import { selectAllTextDefaultStyles } from 'components/editor/grid/reduxStore/textDefaultStylingSlice.ts';
import { useAppSelector } from 'components/editor/grid/reduxStore/Store.ts';

const isActive = (editor: Editor | null, fontFamily: string | undefined) => editor?.isActive('textStyle', { fontFamily }) ?? false;

const styles = {
  fontFamilyContainer: css({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
  }),
};

type Props = {
  editor: Editor;
};

const FontFamily = ({ editor }: Props) => {
  const { contentId } = useEditorContent();
  const { result: installedFonts } = useInstalledFonts(contentId);

  const allAvailableFonts = useMemo(
    () => Array.from(new Map([...DEFAULT_FONT_FAMILY_OPTIONS, ...installedFonts].map((font) => [font.name, font])).values()),
    [installedFonts]
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<string | undefined>(DEFAULT_FONT_FAMILY);
  const textDefaultStyles = useAppSelector(selectAllTextDefaultStyles);

  const selectedLabel = useMemo(() => {
    if (selected === undefined) return '';

    const matchedFont = allAvailableFonts.find((font) => font.name.toLowerCase() === selected.toLowerCase().trim());

    if (matchedFont) {
      return matchedFont.name;
    }
    return selected;
  }, [selected, allAvailableFonts]);

  const handleOpenMenu = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSelectFontFamily = useCallback(
    (fontFamily: string) => {
      editor?.chain().focus()[fontFamily ? 'setFontFamily' : 'unsetFontFamily'](fontFamily).run();

      setSelected(fontFamily || DEFAULT_FONT_FAMILY);
      handleClose();
    },
    [editor, handleClose]
  );

  const getFontFamilyParentAttribute = (parent: Node | null) => {
    if (!parent) {
      throw new Error('Parent node is required');
    }

    const nodeType = parent.type.name === 'heading' ? `h${parent.attrs.level}` : 'p';
    if (textDefaultStyles?.[nodeType]?.fontFamily) {
      return textDefaultStyles[nodeType].fontFamily;
    }
    return DEFAULT_FONT_FAMILY;
  };

  useEffect(() => {
    const updateSelectedFont = createTextStyleHandler({
      attributeName: 'fontFamily',
      getParentAttribute: getFontFamilyParentAttribute,
      parseValue: (value) => value.replace(/^["']|["']$/g, ''),
    });

    editor.on('transaction', (event) => updateSelectedFont(event, setSelected));
    return () => {
      editor.off('transaction', (event) => updateSelectedFont(event, setSelected));
    };
  }, [editor]);

  return (
    <span css={styles.fontFamilyContainer}>
      <PyMuiButton
        width={152}
        testId="font-family-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isActive(editor, selected)}
      >
        <PyMuiMenuLabel data-testid="font-family-label" style={{ fontFamily: selectedLabel }}>
          {selectedLabel}
        </PyMuiMenuLabel>
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>

      <PyMuiMenu
        testId="font-family-menu"
        id="select-font-family-menu"
        menuListProps="select-font-family-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleClose}
      >
        {allAvailableFonts.map(({ name: fontFamily }) => (
          <PyMuiMenuItem
            key={fontFamily}
            testId={`font-family-menu-item-${fontFamily.replace(/\s+/g, '-').toLowerCase()}`}
            onClick={() => handleSelectFontFamily(fontFamily)}
            isActive={selected === fontFamily}
            menuItem={fontFamily}
            isFontFamily
            customStyle={{ minWidth: 220 }}
          />
        ))}
      </PyMuiMenu>
    </span>
  );
};

export default FontFamily;
