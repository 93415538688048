import { useEditor } from '@tiptap/react';

import { EditorContent } from '@tiptap/react';
import { getTipTapExtensions } from '../helpers/config';
import { usePreviewVariables } from '../../../../../providers/PreviewVariablesContext';

export type GridBlockContentChangeHandler = (blockId: string, content: string) => void;

export interface TiptapEditorProps {
  blockId: string;
  documentContent: string;
}

const TipTapTextblockPreview = ({ documentContent: initialDocumentContent, blockId }: TiptapEditorProps) => {
  const editorId = `tiptap_editor_${blockId}`;

  const { variables } = usePreviewVariables();

  const editor = useEditor({
    content: initialDocumentContent ?? '',
    editable: false,
    editorProps: {
      attributes: {
        class: 'tiptap-textblock-editor tiptap-textblock-editor-preview',
      },
    },

    extensions: getTipTapExtensions({ variables: variables ?? [], isPreview: true }),
  });

  return editor ? (
    <EditorContent data-testid={`test_${editorId}`} className={`tiptap-textblock-wrapper ${editorId}`} editor={editor} />
  ) : null;
};

export default TipTapTextblockPreview;
