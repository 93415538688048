import { Extension } from '@tiptap/core';

import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state';

export const SelectionHighlight = Extension.create({
  name: 'selectionHighlight',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('selectionHighlight'),
        props: {
          decorations: (state: EditorState) => {
            const { empty, from, to } = state.selection;

            if (!this.editor.isEditable || empty) {
              return DecorationSet.empty;
            }

            const decorations = [
              Decoration.inline(from, to, {
                class: 'selection-highlight',
              }),
            ];

            return DecorationSet.create(state.doc, decorations);
          },
        },
      }),
    ];
  },
});
