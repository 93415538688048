import { useCallback } from 'react';
import { ConsoleLogger } from 'utils/ConsoleLogger';

export const scrollToSection = (id: string) => {
  const lazyLoadedSection = document.getElementsByClassName(`lazy-loaded-section-${id}`)[0] as HTMLElement;
  if (!lazyLoadedSection) return;

  if (!lazyLoadedSection.style.scrollMarginTop) {
    const headerHeight = document.getElementsByClassName('editor-header-wrapper')[0]?.clientHeight || 0;
    ConsoleLogger.log(`setting scroll margin top of ${headerHeight}px to .lazy-loaded-section-${id}`);
    lazyLoadedSection.style.scrollMarginTop = `${headerHeight}px`;
  }

  lazyLoadedSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const useScrollToSection = (id: string) => useCallback(() => scrollToSection(id), [id]);
