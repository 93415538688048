import { createContext, useContext, useMemo } from 'react';
import { VariableEntity } from '../services/entities/VariablesEntity';
import { useGetVariables } from '../components/editor/hooks/useVariables';
import { useGetPreviewByDocId } from '../pages/editor/useGetPreviewByDocId';
import { ContentType } from 'utils/getContentType';
type PreviewVariablesContextType = {
  variables: VariableEntity[];
};

export const PreviewVariablesContext = createContext<PreviewVariablesContextType | null>(null);

export const PreviewVariablesProvider = ({ children, documentId }: { children: React.ReactNode; documentId: string }) => {
  const { data: documentData } = useGetPreviewByDocId(documentId);

  const { data: variables } = useGetVariables(documentData?.contentId as string, {
    enabled: !!documentData?.contentId,
    contentType: ContentType.DOCUMENT,
  });

  const variablesArray = useMemo(() => (variables ? Object.values(variables).flat() : []), [variables]);

  return <PreviewVariablesContext.Provider value={{ variables: variablesArray }}>{children}</PreviewVariablesContext.Provider>;
};

export const usePreviewVariables = () => {
  const variablesContext = useContext(PreviewVariablesContext);
  if (!variablesContext) {
    throw new Error('usePreviewVariables must be used within a PreviewVariablesProvider');
  }
  return variablesContext;
};
