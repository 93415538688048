import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import IcoDrag from 'components/icon/icons/IcoDrag';
import { MutableRefObject, useContext } from 'react';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';
import SectionMenu from './SectionMenu';
import { useScrollToSection } from './useScrollToSection';
import { useSectionDragAndDrop } from './useSectionDragAndDrop';

type SectionProps = {
  id: string;
  index: number;
  title: string;
  isActive: boolean;
  onReorder: (fromIndex: number, toIndex: number) => void;
  activeDraggedSectionRef: MutableRefObject<{ sectionId: string; index: number } | null>;
};

export default function Section({ id, index, title, isActive, onReorder, activeDraggedSectionRef }: SectionProps) {
  const scrollToSection = useScrollToSection(id);
  const { sectionRef, dropIndicatorPosition, dragHandlers } = useSectionDragAndDrop({
    sectionId: id,
    index,
    onReorder,
    activeDraggedSectionRef,
  });
  const { handleSelectedBlockInfoByWrapper, handleSelectedBlockInfoByIcon } = useContext(SelectionContext);

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    handleSelectedBlockInfoByWrapper({ sectionId: null, blockId: null });
    handleSelectedBlockInfoByIcon({ sectionId: null, blockId: null });
  };

  return (
    <ListItem
      key={id}
      disablePadding
      data-testid="section-title-textfield-input"
      ref={sectionRef}
      {...dragHandlers}
      sx={{
        '&:hover': {
          [`& .section-${id}-drag-icon`]: {
            opacity: '1 !important',
            width: '100% !important',
          },
          [`& .section-${id}-meatball-icon`]: {
            opacity: '1 !important',
          },
        },
        borderTop: dropIndicatorPosition === 'top' ? '2px solid #353636' : 'none',
        borderBottom: dropIndicatorPosition === 'bottom' ? '2px solid #353636' : 'none',
      }}
    >
      <ListItemButton
        onClick={scrollToSection}
        onMouseDown={onMouseDown}
        sx={{
          padding: '0.5rem 0.625rem 0.5rem 0.5rem',
          borderRadius: '0rem 0.5rem 0.5rem 0rem',
          background: isActive ? '#353636' : 'transparent',
          boxShadow: isActive
            ? '0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
            : 'none',
          '&:hover': {
            background: isActive ? '#353636' : 'transparent',
          },
        }}
      >
        <IcoDrag
          className={`section-${id}-drag-icon`}
          style={{
            display: 'block',
            marginRight: '0.5rem',
            cursor: 'grab',
            opacity: 0,
            maxWidth: '1em',
            width: '0%',
            transition: 'opacity 0.2s ease-in-out, width 0.8s ease-in-out',
          }}
          color={isActive ? '#fff' : '#353636'}
        />
        <ListItemText
          data-section-id={id}
          sx={{ color: isActive ? '#fff' : '#000' }}
          primary={
            <Typography
              variant="body2"
              sx={{
                WebkitFontSmoothing: isActive ? 'subpixel-antialiased' : 'initial',
                fontFeatureSettings: "'liga' off, 'clig' off",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Typography>
          }
        />
        <SectionMenu id={id} index={index} isActive={isActive} />
      </ListItemButton>
    </ListItem>
  );
}
