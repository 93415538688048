import { Grid, SelectChangeEvent } from '@mui/material';
import { SecondaryMenuItemTitle } from './SecondaryMenuTitles';
import { SelectInputColumn } from './SelectInputColumn.tsx';

type SelectInputTwoColProps = {
  label?: string;
  menuItems: string[];
  isMultiple?: boolean;
  onChange: (event: SelectChangeEvent<string[]>) => void;
  testId: string;
  value?: string[];
};

export const SelectInputTwoCol = ({ menuItems, onChange, testId, label = '', value = [], isMultiple = false }: SelectInputTwoColProps) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValue = event.target.value;
    // For multiple select, ensure we're always working with arrays
    const newValue = typeof selectedValue === 'string' ? [selectedValue] : selectedValue;

    onChange({
      ...event,
      target: {
        ...event.target,
        name: 'drop-down-select',
        value: newValue,
      },
    } as SelectChangeEvent<string[]>);
  };

  return (
    <Grid data-testid={testId} container alignItems="center" justifyContent="space-between" gap={1}>
      <Grid item>
        <SecondaryMenuItemTitle>{label}</SecondaryMenuItemTitle>
      </Grid>

      <SelectInputColumn menuItems={menuItems} handleChange={handleChange} value={value} isMultiple={isMultiple} />
    </Grid>
  );
};
