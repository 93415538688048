export const Endpoints = {
  getCurrentUser: '/user/me',
  getUser: '/user',
  getCurrentAccount: '/account',
  getAccountPlan: (accountId: number) => `/accounts/${accountId}/plan`,
  applyPromocode: (accountId: number) => `/accounts/${accountId}/promos`,
  getBillingInfo: (accountId: number) => `/accounts/${accountId}/billing`,
  updateBillingInfo: (accountId: number) => `/accounts/${accountId}/billing`,
  getBillingHistory: (accountId: number) => `/accounts/${accountId}/billing-history`,
  getBillingOptions: (accountId: number) => `/accounts/${accountId}/billing-options`,
  getSubscription: (accountId: number) => `/accounts/${accountId}/subscription`,
  updatePlan: (accountId: number) => `/accounts/${accountId}/subscription`,
  validateCoupon: (accountId: number) => `/accounts/${accountId}/promos`,
  getUserIntegrations: (userId: number | null) => `/user/${userId}/integrations`,
  getIntegrationOauthData: (userId: number, integrationName: string) => `/user/${userId}/integrations/${integrationName}/connectors`,
  getUserPermissions: '/user/me/role',
  getProposalPreviewUrl: (proposalId: number) => `/preview_url/${proposalId}`,
  getProposalShareUrl: (proposalId: number) => `/proposal/${proposalId}/share_email`,
  getProposalById: (proposalId: number) => `/proposal/${proposalId}`,
  getUserFeatureFlagContext: (userId: string | number, featureFlag: string) => `user/${userId}/flag/${featureFlag}`,
  getPersonById: (personId: number) => `/person/${personId}`,
  getPersons: '/person',
  getCompanyById: (companyId: number) => `/company/${companyId}`,
  getCompanies: '/company',
  getProspects: '/prospects',
  getShareProposalDefaultTemplate: (proposalId: number) => `/proposal/${proposalId}/share_template`,
  getShareProposalTemplate: ({ proposalId, templateId }: { proposalId: number; templateId: string }) =>
    `/proposal/${proposalId}/share_template/${templateId}`,
  getAllEmailTemplates: '/email_template',
  markProposalAsSent: (proposalId: number) => `/proposal/${proposalId}/status/sent`,
  markProposalAsDraft: (proposalId: number) => `/proposal/${proposalId}/status/draft`,
  archiveProposal: (proposalId: number) => `/documents/${proposalId}/archive_schedule`,
  getPlanFeatures: (planId: number) => `/plan/${planId}/features`,
  refreshToken: '/authtoken',
  createPerson: '/person',
  documents: '/documents',
  documentDetail: function (documentId: string) {
    return `${this.documents}/${documentId}`;
  },
  previewDetails: (documentId: string) => `/preview/${documentId}`,
  sectionsBlocksForPreview: (documentId: string) => `/preview/${documentId}/grid`,
  documentImageLibraryImages: (contentId: string) => `/content/${contentId}/image-library`,
  documentUploadImage: (contentId: string) => `/content/${contentId}/image-library`,
  documentShare: function (documentId: string) {
    return `${this.documents}/${documentId}/share_email`;
  },
  accountSignees: (accountId: number) => `/accounts/${accountId}/signees`,
  getProposalContent: (proposalId: string) => `/documents/${proposalId}/content`,
  getDocumentPreviewPage: (documentId: string) => `/preview/${documentId}`,
  getSignaturesByDocumentId: (documentId: string) => `/documents/${documentId}/signatures`,
  ssoConfig: '/sso/oidc/config',
  updateSsoConfig: (providerId: string) => `/sso/oidc/config/${providerId}`,
  getSsoSchema: '/schemas/sso/openid_connect/config.json',
  getSsoRefSchema: (reference: string) => `/schemas/sso/openid_connect/${reference}`,
  discoverSso: '/sso/oidc/config/discover',
  ssoSettings: '/sso/settings',
  getRole: '/role',
  signSignature: () => '/signatures',
  getInstalledFonts: (contentId: string) => `/documents/${contentId}/fonts`,
  featureFlag: {
    optionalFeatures: '/optional_features',
    specificOptionalFeature: (flag: string) => `/optional_features/${flag}`,
  },
  getSalesforceObjectTypes: '/crm/salesforce/object_types',
  getSalesforceObjectTypesPrefix: (prefix: string) => `/crm/salesforce/object_types${prefix.length > 0 ? `/${prefix}` : ''}`,
  salesforceObjectSettings: '/crm/salesforce/settings',
  createDocument: '/proposal',
  getTemplateFolders: '/template/folder',
  uploadImage: '/upload/image',
  getLinkedAccounts: (accountId: number) => `/accounts/${accountId}/linked-accounts`,
  getWorkspaceMyAccounts: '/workspaces/my-accounts',
  postWorkspaceAccessUsage: '/workspaces/access-usages',
  postWorkspaceAccessAllowances: '/workspaces/access-allowances',
  getAccessAllowanceBySubdomain: (subdomain: string) => `/workspaces/access-allowances?account_subdomain=${subdomain}`,
  deleteAccessAllowanceBySubdomain: (uuid: string) => `/workspaces/access-allowances/${uuid}`,
  deleteDocument: (documentId: string) => `/documents/${documentId}`,
  documentsTrash: (documentId: string) => `/documents/${documentId}/trash`,
  getAPIClients: '/connect-api/apiclients',
  updateAPIClient: (id: string) => `/connect-api/apiclients/${id}`,
  rotateAPIClientSecret: (id: string) => `/connect-api/apiclients/${id}/rotate`,
  createAPIClient: '/connect-api/apiclients',
  getLocationData: '/listings/location_data',
  getPrismaticJWT: '/prismatic/embed-token',
  getCancelReasons: '/accounts/cancel-reasons',
  cancelAccountPlan: (accountId: number) => `/accounts/${accountId}/plan/cancel`,
  getPrismaticCredentials: '/prismatic/api-credentials',
  getPaymentAccount: (accountId: number) => `/accounts/${accountId}/payment-account`,
  updatePaymentAccount: (accountId: number) => `/accounts/${accountId}/payment-account`,
  updateWorkspace: '/register/updateWorkspace',
  registerAccount: '/register/signupAccount',
  acceptInvitation: '/register/accept',
  getInvitation: (token: string) => `/user/invite?token?=${token}`,
  getSeats: (accountId: number) => `/accounts/${accountId}/seats`,
  seatInvite: (accountId: number) => `/accounts/${accountId}/seats/invite`,
  addSeats: (accountId: number) => `/accounts/${accountId}/seats/update`,
  previewAddSeats: (accountId: number) => `/accounts/${accountId}/seats/preview`,
  unassignSeat: (accountId: number) => `/accounts/${accountId}/seats/unassign`,
  removeSeat: (accountId: number) => `/accounts/${accountId}/seats/remove`,
  getUserRoles: (accountId: number) => `/accounts/${accountId}/roles`,
  resendInvite: (accountId: number) => `/accounts/${accountId}/seats/resend-invite`,
  getDocumentCollaborators: (documentId: string) => `/proposal/${documentId}/collaborators`,
  documentInvite: (documentId: string) => `/proposal/${documentId}/collaborators`,
  documentRemoveAccess: (documentId: string) => `/proposal/${documentId}/collaborators`,
  getFlexDataTopLevelObjectTypes: '/flex-data/top-level',
  getFlexDataLinks: (documentId: string) => `/flex-data/document/${documentId}/link`,
  searchFlexDataLinks: '/flex-data/search',
  upsertFlexDataLink: (documentId: string) => `/flex-data/document/${documentId}/link`,
  unlinkFlexDataLink: (documentId: string) => `/flex-data/document/${documentId}/unlink`,
};
