import { useCallback, useContext, useEffect } from 'react';
import { ActiveEditorContext } from '../ActiveEditorContext';
import { Editor, EditorContent, EditorEvents, useEditor } from '@tiptap/react';
import { getTipTapExtensions } from '../helpers/config';
import { useSectionData } from '../../../Sections/useSectionContext.ts';
import { SelectionContext } from '../../SelectedBlockInfoProvider.tsx';
import { TableType } from '../../../grid/reduxStore/table.types.ts';
import { setOfRowsForPricingTable } from '../../Block/Table/defaultData.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../grid/reduxStore/Store.ts';
import { selectContentTable } from '../../../grid/reduxStore/editorSlice.ts';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler.ts';

export const TipTapPricingFooterTitle = ({ id, hasFocus }) => {
  const { activeEditor, setActiveEditor } = useContext(ActiveEditorContext);
  const { sectionId } = useSectionData();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);

  const blockId = selectedBlockIdByWrapper as string;
  const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;
  const totalTableRows = tableData?.totalRows || setOfRowsForPricingTable;
  const cellValue = totalTableRows?.find((row) => row.id === id)?.['title'];
  const handleInputChange = async (value: string) => {
    const updatedRows = totalTableRows?.map((row) => (row.id === id ? { ...row, ['title']: value } : row));

    const newTableData: TableType = {
      ...tableData,
      totalRows: updatedRows,
      metadata: {
        ...tableData?.metadata,
      },
    };

    await blockContentChangedHandler(blockId, sectionId, newTableData);
  };

  const updateEditorData = useCallback(
    async (editor: Editor) => {
      const html = editor.getHTML();
      await handleInputChange(html);
    },
    [handleInputChange]
  );

  const editor = useEditor({
    content: cellValue ?? '',
    extensions: getTipTapExtensions({ variables: [] }),
    onUpdate: ({ editor }: EditorEvents['update']) => updateEditorData(editor),
    onBlur: ({ editor }: EditorEvents['blur']) => updateEditorData(editor),
  });

  useEffect(() => {
    const isSetActiveEditor = hasFocus && editor && activeEditor !== editor;
    if (isSetActiveEditor) {
      setActiveEditor(editor);
    }
  }, [editor, activeEditor, setActiveEditor, hasFocus]);

  return editor ? <EditorContent editor={editor} /> : null;
};
