import { SelectInputColumn } from '../../component/SelectInputColumn.tsx';
import { SelectChangeEvent } from '@mui/material';

export const customSelectStyle = {
  fontSize: '12px',
  fontStyle: 'normal',
  color: 'rgba(0, 40, 58, 0.60)',
  fontWeight: 600,
  lineHeight: '166%',
  letterSpacing: '0.45px',
  '& > div:focus': {
    backgroundColor: 'transparent',
  },
};

type StyledComponentInputProps = {
  width: string;
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  menuItems: string[];
  value: string;
};

const StyledComponentInput = ({ width, handleChange, menuItems, value }: StyledComponentInputProps) => {
  return (
    <SelectInputColumn
      customSelectStyle={customSelectStyle}
      value={[value]}
      menuItems={menuItems}
      width={width}
      handleChange={handleChange}
    />
  );
};

export default StyledComponentInput;
