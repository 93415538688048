import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { selectAllVariablesItems } from '../../../../grid/reduxStore/allVariableSlice';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { formatVariable } from './utils';

export const VariableNodeView = ({ node, getPos, editor }: NodeViewProps) => {
  const variables = useSelector(selectAllVariablesItems);
  const variable = useMemo(() => variables.find((variable) => variable.name === node.attrs.name), [variables, node.attrs.name]);

  const valueToRender = formatVariable(node.attrs.name, variable);

  const onClick = () => {
    editor.chain().focus().setNodeSelection(getPos()).run();
  };

  return (
    <NodeViewWrapper as="span" className="tiptap-variable-node-view" onClick={onClick}>
      {valueToRender}
    </NodeViewWrapper>
  );
};
