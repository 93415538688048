import useFeatureFlag from 'hooks/useFeatureFlag';
import { type ReactNode, createContext, useContext, useState } from 'react';
import { FeatureFlags } from 'utils/featureFlags';

type SectionsDrawerContext = {
  isSectionsDrawerOpen: boolean;
  setIsSectionsDrawerOpen: (isSectionsDrawerOpen: boolean) => void;
};

const SectionsDrawerContext = createContext<SectionsDrawerContext | null>(null);

export const useSectionsDrawer = () => {
  const isSectionsReorderingEnabled = useFeatureFlag([FeatureFlags.editorSectionsReordering])[FeatureFlags.editorSectionsReordering];
  const context = useContext(SectionsDrawerContext);

  if (!isSectionsReorderingEnabled) {
    return {
      isSectionsDrawerOpen: false,
      setIsSectionsDrawerOpen: () => {
        // do nothing
      },
    };
  }

  if (!context) {
    throw new Error('SectionsDrawerContext must be used within a Provider');
  }
  return context;
};

export const SectionsDrawerProvider = ({ children }: { children: ReactNode }) => {
  const [isSectionsDrawerOpen, setIsSectionsDrawerOpen] = useState(false);

  return (
    <SectionsDrawerContext.Provider value={{ isSectionsDrawerOpen, setIsSectionsDrawerOpen }}>{children}</SectionsDrawerContext.Provider>
  );
};
