import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import SignatureSocketClient from './SignatureSocketClient';
import {
  BlockEvents,
  DocumentSettingsEvents,
  SectionEvents,
  SignatureEvents,
  SocketClientDefaultEvents,
  SocketConnectionEvents,
} from './SocketEvents';
import { SignatureBox, SignatureBoxApiResponse } from 'services/repositories/interfaces/SignatureRepository';

import { transformSignatureBoxApiResponse } from 'services/repositories/implementations/ApiSignatureRepository';
import { SectionsSignaturesType } from '../../components/editor/providers/SignaturesProvider';
import { v4 as uuidv4 } from 'uuid';

type AuthObject = {
  contentId: string | undefined;
  accessToken: string | undefined;
};

type GetAllSignaturesOnMountCallback = (data: SectionsSignaturesType) => void;
type SignatureInsertionType = SignatureBox & { sectionId: string };
export type SignaturePropertyUpdate = Pick<SignatureBox, 'signatureBoxId' | 'properties'>;

export type TSocketCallback = (data: any) => void;

export type Events =
  | DocumentSettingsEvents
  | SignatureEvents
  | BlockEvents
  | SectionEvents
  | SocketConnectionEvents
  | SocketClientDefaultEvents;

class SocketClient {
  #signatureSocketClient: SignatureSocketClient;
  #isConnected = false;
  #socketClient: Socket;
  #pendingRequests: Record<
    string,
    { event: Events; data: any; callback: TSocketCallback; options?: { shouldDisconnectAfterReplay: boolean } }
  > = {};

  #connectionParams: Partial<ManagerOptions & SocketOptions> & { auth: AuthObject } = {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 5,
    auth: {
      accessToken: undefined,
      contentId: undefined,
    },
    transports: ['websocket'],
  };
  constructor(serverURL: string, authParams: AuthObject) {
    this.#socketClient = this.getNewSocketClient(serverURL, authParams);
    this.#signatureSocketClient = new SignatureSocketClient(this);
  }

  private getNewSocketClient(serverURL: string, authParams: AuthObject): Socket {
    this.#connectionParams.auth = authParams;
    return io(serverURL, this.#connectionParams);
  }

  public isConnected(): boolean {
    return this.#isConnected;
  }

  private setIsConnected(): void {
    this.#isConnected = true;
  }

  public reconnectWithNewToken(accessToken: string): void {
    this.#socketClient.disconnect();
    this.#connectionParams.auth.accessToken = accessToken;
    this.#socketClient.auth = this.#connectionParams.auth;
    this.connect();
  }

  public connect(): void {
    this.subscribeOnce(SocketConnectionEvents.CONNECTED, () => {
      this.setIsConnected();
      this.replayPendingRequests();
    });
    this.#socketClient.connect();
  }

  public disconnect() {
    this.#socketClient.disconnect();
    this.#isConnected = false;
  }

  public getAllSignaturesOnMount(callback: GetAllSignaturesOnMountCallback) {
    this.subscribeOnce(SignatureEvents.SIGNATURE_GET_ALL, (response: { [sectionId: string]: SignatureBoxApiResponse[] } = {}) => {
      const data: SectionsSignaturesType = {};

      for (const [sectionId, signatures] of Object.entries(response)) {
        data[sectionId] = signatures.map((signature) => {
          return transformSignatureBoxApiResponse(signature);
        });
      }

      callback(data);
    });
  }

  public subscribe(event: Events, callback: any): SocketClient {
    this.#socketClient.on(event, callback);
    return this;
  }

  public subscribeOnce(event: Events, callback: any): SocketClient {
    this.#socketClient.once(event, callback);
    return this;
  }

  public publish(event: Events, data: any, callback: (resp: any) => any, options?: { force: boolean }): SocketClient {
    const requestId = uuidv4();
    let shouldDisconnectAfterReplay = false;
    if (options?.force && !this.isConnected()) {
      shouldDisconnectAfterReplay = true;
      this.connect();
    }

    this.#pendingRequests[requestId] = { event, data, callback, options: { shouldDisconnectAfterReplay } };

    if (this.isConnected()) {
      this.#socketClient.emit(event, data, (response: any) => {
        delete this.#pendingRequests[requestId];
        callback(response);
      });
    }

    return this;
  }

  public addSignatureContent(data: SignatureInsertionType, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_ADD, callback);
  }

  public updateSignatureContent(data: SignaturePropertyUpdate, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_UPDATE, callback);
  }

  public deleteSignatureContent(data: SignatureBox, callback: TSocketCallback) {
    this.#signatureSocketClient.publishSignatureContent(data, SignatureEvents.SIGNATURE_DELETE, callback);
  }

  private replayPendingRequests(): void {
    let shouldDisconnectAfterReplay = false;

    for (const requestId of Object.keys(this.#pendingRequests)) {
      const request = this.#pendingRequests[requestId];
      delete this.#pendingRequests[requestId];

      const numberOfPendingRequests = Object.keys(this.#pendingRequests).length;
      const { event, data, callback: requestCallback, options } = request;

      const callback: TSocketCallback = (response) => {
        if (options?.shouldDisconnectAfterReplay) {
          shouldDisconnectAfterReplay = true;
        }
        requestCallback(response);

        if (shouldDisconnectAfterReplay && numberOfPendingRequests === 0) {
          this.disconnect();
        }
      };

      this.publish(event, data, callback);
    }
  }
}

export default SocketClient;
