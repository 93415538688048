export enum UserPermission {
  isAdmin,
  isCollaborator,

  templatesEdit,

  librarySections,
  libraryFees,
  librarySnippets,
  libraryImages,

  settingsIntegrations,

  settingsTeam,
  settingsEmail,
  settingsFonts,
  settingsTags,
  settingsCurrency,
  settingsPreview,

  purchaseSeat,
}
