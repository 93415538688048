import { useEffect, useRef, useState } from 'react';
import { ColorPopup, ColorResult } from '../../../shared/components/ColorPicker';
import { useActiveEditor } from '../ActiveEditorContext';
import { PyMuiIconButton } from '../components/generalComponents';
import IcoFontColor from 'components/icon/icons/IcoFontColor';
import { Box, styled } from '@mui/material';
import { COLORS } from '../../../../../muiTheme/MuiDataGrid.ts';

const CustomFillWrapper = styled(Box)<{ color?: string }>`
  svg path:nth-of-type(1) {
    fill: ${(props) => props.color || COLORS.colorHeaderRow};
  }

  svg path:last-child {
    fill: rgba(0, 40, 58, 0.87);
  }
`;

const TextColor = () => {
  const { activeEditor: editor } = useActiveEditor();
  const iconRef = useRef<HTMLSpanElement>(null);
  const [currentColor, setCurrentColor] = useState<string>(COLORS.colorHeaderRow);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [buttonColor, setButtonColor] = useState<string>(COLORS.colorHeaderRow);

  useEffect(() => {
    if (!editor) return;
    const updateButtonColor = ({ editor }: { editor: any }) => {
      const color = editor.getAttributes('textStyle').color || COLORS.colorHeaderRow;
      setButtonColor(editor.isActive('textStyle', { color }) ? color : COLORS.colorHeaderRow);
    };

    editor.on('selectionUpdate', updateButtonColor);

    return () => {
      editor.off('selectionUpdate', updateButtonColor);
    };
  }, [editor]);

  if (!editor) {
    return null;
  }

  const applyColor = (color: string) => {
    editor.chain().focus().setColor(color).run();
  };

  const handleOpenMenu = () => {
    setIsModalOpen(true);
  };

  const handleCloseMenu = () => {
    setIsModalOpen(false);
  };

  function changeBlockBackgroundColor(color: string): void {
    applyColor(color);
    handleCloseMenu();
    setCurrentColor(color);
  }

  const onCloseHandler = (color: ColorResult) => {
    if (typeof color !== 'string') {
      changeBlockBackgroundColor(`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
    } else {
      if (!color.startsWith('#')) {
        changeBlockBackgroundColor('#' + color);
      } else {
        changeBlockBackgroundColor(color);
      }
    }
  };

  return (
    <>
      <PyMuiIconButton attributeName="text-color" onClick={() => handleOpenMenu()} isActive={isModalOpen}>
        <span ref={iconRef} data-testid={'text-color'}>
          <CustomFillWrapper color={buttonColor}>
            <IcoFontColor style={{ padding: '3px 2px 0 2px' }} />
          </CustomFillWrapper>
        </span>
      </PyMuiIconButton>
      {<ColorPopup buttonRef={iconRef} currentColor={currentColor} onClose={onCloseHandler} open={isModalOpen} />}
    </>
  );
};

export default TextColor;
