import { useContext } from 'react';
import { YoutubeVideo } from '../videos/youtubeVideo';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';
import { useBlockContentObjectChangedHandler } from './useBlockContentObjectChangedHandler';
import { VideoContent } from '../videos/video';

export const useVideoBlockSettings = () => {
  const { selectedBlockInfoByIcon } = useContext(SelectionContext);
  const contentObjectChanged = useBlockContentObjectChangedHandler();
  const blockId = selectedBlockInfoByIcon.blockId as string;
  const sectionId = selectedBlockInfoByIcon.sectionId as string;

  const updateVideoContentFromUrl = async (url: string) => {
    const youtubeVideo = new YoutubeVideo();
    const content = await youtubeVideo.getContentData(url);
    await updateVideoContent({ ...content, url });
  };

  const updateVideoContent = async (content: VideoContent) => {
    await contentObjectChanged(blockId, sectionId, content);
  };

  return {
    updateVideoContentFromUrl,
  };
};
