import { Helmet } from 'react-helmet';
const fontCdnDomain = globalThis.env.VITE_CI_STORAGE_CDN_FONTS || undefined;

interface LoadDefaultFontProps {
  defaultFonts: { path: string }[];
}

const DefaultFontLoader = ({ defaultFonts }: LoadDefaultFontProps) => {
  return (
    <Helmet>
      {fontCdnDomain &&
        defaultFonts
          ?.filter((font) => !(!font.path || font.path === ''))
          .map((font) => {
            const relation = 'stylesheet';
            const cssUrl = `${fontCdnDomain}/font/${font.path}/styles.css`;
            return <link key={font.path} href={cssUrl} rel={relation} />;
          })}
    </Helmet>
  );
};

export default DefaultFontLoader;
