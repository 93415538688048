import { PyMuiIconButton } from 'components/editor/GridDndEditor/TiptapEditor/components/generalComponents';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

const textAlignmentButtons = [
  { attributeName: 'right', icon: <FormatAlignRightIcon /> },
  { attributeName: 'center', icon: <FormatAlignCenterIcon /> },
  { attributeName: 'left', icon: <FormatAlignLeftIcon /> },
  { attributeName: 'justify', icon: <FormatAlignJustifyIcon /> },
];

const TextAlignmentButtons = ({ defaultAlignment }) => {
  return textAlignmentButtons.map(({ attributeName, icon }) => (
    <PyMuiIconButton
      key={`style-${attributeName}`}
      attributeName={attributeName}
      disabled={false}
      onClick={() => null}
      isActive={defaultAlignment === attributeName}
    >
      {icon}
    </PyMuiIconButton>
  ));
};

export default TextAlignmentButtons;
