import { IconButton, Tooltip } from '@mui/material';
import IcoInsights from 'components/icon/icons/IcoInsights';
import { useTranslation } from 'react-i18next';
import { openInsights, useOnInsightsClose } from './useOpenCloseInsights';

interface InsightsActionProps {
  documentId: string;
  refreshDocumentList: () => void;
}

export function InsightsAction({ documentId, refreshDocumentList }: InsightsActionProps) {
  const { t } = useTranslation();
  const handleOpenInsights = () => openInsights(documentId);
  useOnInsightsClose((isExpanded) => {
    if (!isExpanded) return;
    refreshDocumentList();
  });

  return (
    <Tooltip title={t('pipeline.insights.open_insights_button_tooltip')}>
      <IconButton data-testid={`insights-button-${documentId}`} color="primary" onClick={handleOpenInsights}>
        <IcoInsights />
      </IconButton>
    </Tooltip>
  );
}
