import { useContext } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { TableSettings } from '../../../component';
import SvgIcoTableRowPlugsAfter from 'components/icon/icons/IcoTableRowPlusAfter';
import SvgIcoTableRowPlugsBefore from 'components/icon/icons/IcoTableRowPlusBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';

import { EditorDefaultSidePanelType, setActiveSidePanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';
import { useTableRowTypeChange } from './useTableRowTypeChange';
import { TableRowAddPositions, useTableRowManipulation } from './useTableRowManipulation';

import { ColumnTypeIdentifier, RowTypesIdentifier } from 'components/editor/grid/reduxStore/table.types';
import { isRowBelongsToTotal } from '../../../../../../muiTheme/dataGridUtils';
import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import OptionalRowToggle from './OptionalRowToggle';
import MultiplierEditingToggle from './MultiplierEditingToggle';
import { Grid } from '@mui/material';

const RowSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const menuTitleInitialState = t('document.grid.table.setting.rows');
  const menuTitleTotalRows = t('document.grid.table.setting.total_rows');
  const { rowType, handleRowTypeChange } = useTableRowTypeChange();
  const { handleRowAdd, handleRowDuplication, handleDeleteRow } = useTableRowManipulation();

  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const selectedModel = toggledTableSettingsPanel?.tableApi.selectedModel || undefined;

  const { row: selectedRow } = selectedModel as GridCellParams;

  const isTotalsRow = isRowBelongsToTotal(selectedRow);
  const isOptionalToggleActive = rowType === RowTypesIdentifier.BODY;

  const columnType = selectedModel?.colDef?.columnType;
  const isEditingToggleActive = rowType === RowTypesIdentifier.BODY && columnType === ColumnTypeIdentifier.MULTIPLIER;

  const rowButtons = [
    {
      icon: <SvgIcoTableRowPlugsAfter />,
      arialLabel: 'row-plus-after',
      fn: () => handleRowAdd(TableRowAddPositions.AFTER),
    },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    { icon: <SvgIcoContentCopy />, arialLabel: 'content-copy', fn: handleRowDuplication },
    { icon: <SvgIcoTrash />, arialLabel: 'trash', fn: handleDeleteRow },
  ];

  const menuTitle = isTotalsRow ? menuTitleTotalRows : menuTitleInitialState;

  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  return (
    <TableSettings
      iconButtons={rowButtons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleRowTypeChange}
      helpText={t('document.grid.table.setting.update_select_row_type')}
      panelTitle={t('document.grid.table.setting.row_type')}
      selectId="select-row-type"
      selectOptions={RowTypesIdentifier}
      selectValue={rowType}
      menuTitle={menuTitle}
      testId="table-row-settings"
    >
      <Grid container alignItems="center" justifyContent="space-between">
        {isOptionalToggleActive && <OptionalRowToggle rowId={selectedRow.id} />}
        {isEditingToggleActive && <MultiplierEditingToggle />}
      </Grid>
    </TableSettings>
  );
};

export default RowSettings;
