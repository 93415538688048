import { InvalidVideo } from './videoErrors';

type VideoData = {
  type: 'youtube';
  title: string;
  author: string;
  thumbnail?: string;
  videoId: string;
};

export class YoutubeVideo {
  isValidUrl(url: string) {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)[\w-]+|youtu\.be\/[\w-]+)$/;
    return regex.test(url);
  }

  async getContentData(url: string): Promise<VideoData> {
    const videoId = this.getVideoId(url);
    const metadata = await this.getMetadataFromVideoId(videoId);

    return {
      type: 'youtube',
      title: metadata.title,
      author: metadata.author_name,
      thumbnail: metadata.thumbnail_url,
      videoId,
    };
  }

  private getVideoId(url: string) {
    const match = url.match(/(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|live\/)|youtu\.be\/)([\w-]{11})(?:\?|&|$)/);
    if (!match) {
      throw new Error('Could not extract video ID from YouTube URL');
    }

    return match[1];
  }

  private async getMetadataFromVideoId(id: string) {
    const urlShort = `https://youtu.be/${id}`;
    const apiUrl = `https://www.youtube.com/oembed?url=${encodeURIComponent(urlShort)}&format=json`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new InvalidVideo();

      return await response.json();
    } catch (error) {
      throw new InvalidVideo();
    }
  }
}
