import {
  SecondaryMenuTrigger,
  DropDownSection,
  NumInputBoxTwoCol,
  ColorPickerSection,
  SecondaryMenu,
  SecondaryMenuSectionTitle,
} from '../../../component';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { getBlockSidesFromString } from '../../../../GridDndEditor/Block/Helper/BlockSides';

import {
  EditorDefaultSidePanelType,
  setActiveSidePanel,
  setIsAdvancedSpacingModelOpen,
} from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { useBlockStyleSettings } from '../../../../hooks/useBlockSettings';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';

export function TextBlockSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    changeBlockBackgroundColor,
    getBlockStyle,
    changeBlockBorderColor,
    changeBlockBorderSide,
    changeBorderRadius,
    changeBorderWidth,
  } = useBlockStyleSettings();
  const {
    backgroundColor = '#ffffff00',
    borderColor = '#ffffffff',
    borderRadius = 0,
    currentBorderSide = [],
    borderWidth = 0,
  } = getBlockStyle();
  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  return (
    <SecondaryMenu
      testId={'textbox-settings-component'}
      menuTitle={t('document.grid.text.design.title')}
      backButtonOnClick={handleBackButtonClick}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.text.design.background')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={backgroundColor} handleColorChange={changeBlockBackgroundColor} />
      </Stack>
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.text.design.border')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={borderColor} handleColorChange={changeBlockBorderColor} />
        <DropDownSection
          currentSelection={currentBorderSide}
          handleSelectionChange={(borderSideStr) => {
            changeBlockBorderSide(getBlockSidesFromString(borderSideStr));
          }}
          testId={'border-dropdown'}
        />
        <NumInputBoxTwoCol
          inputValue={borderWidth}
          onChange={changeBorderWidth}
          title={t('document.grid.text.design.border_width')}
          testId={'border-width'}
          disableNegativeNum
        />
        <NumInputBoxTwoCol
          inputValue={borderRadius}
          onChange={changeBorderRadius}
          title={t('document.grid.text.design.border_radius')}
          testId={'border-radius'}
          disableNegativeNum
        />
      </Stack>
      <SecondaryMenuTrigger
        menuTriggerTitle={t('document.grid.text.advanced_spacing.title')}
        menTriggerDesc={t('document.grid.text.advanced_spacing.padding')}
        handleClick={() => {
          dispatch(setIsAdvancedSpacingModelOpen(true));
        }}
      />
    </SecondaryMenu>
  );
}
