import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SignaturesContext } from 'components/editor/providers/SignaturesProvider';
import FeatureFlagged from 'components/FeatureFlagged';
import FontLoader from 'components/font';
import { CONFIG } from 'config';
import Froala from 'froala-editor';
import useEditorRef from 'hooks/useEditorRef';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSocketClient } from 'providers/SocketContext';
import React, { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { FeatureFlags } from 'utils/featureFlags';
import ProposifyTheme from '../../../muiTheme/PyTheme';
import { SectionEvents } from '../../../services/socket/SocketEvents';
import DefaultFontLoader from '../../font/DefaultFontLoader.tsx';
import { dragDropManager } from '../dndContext';
import { setInitialVariablesState } from '../grid/reduxStore/allVariableSlice';
import { resetState, setInitialState } from '../grid/reduxStore/editorSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useEditorConfig } from '../GridDndEditor/Block/Text/EditorConfigs';
import { convertSectionsLoadDataToContentBlocks } from '../GridDndEditor/gridHelper';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';
import { useBlockStyleSettings } from '../hooks/useBlockSettings';
import { RenderEditorMenu } from '../RenderEditorMenu';
import Sections from '../Sections';
import { SectionContentCollectionType } from '../Sections/Section.model';
import { SectionsDrawerProvider } from '../SectionsDrawer/SectionsDrawerContext';
import SectionsList from '../SectionsDrawer/SectionsList';
import { EditorSidePanel } from '../SidePanel';
import { SidePanelProvider } from '../SidePanel/content';
import { DocumentSettingsProvider } from '../SidePanel/document-settings/DocumentDesignSettings/DocumentSettingsContext';
import '../styles.less';
import '../variables/variablesList.less';
import './DocumentBuilder.less';
import { DocumentBuilderLeftPanel, DocumentBuilderMainContent, DocumentBuilderRightPanel } from './DocumentBuilderLayout';

interface DocumentBuilderProps {
  documentId: string;
  contentId: string;
}

const sectionsDrawerFlags = [FeatureFlags.editorSectionsReordering];

const DocumentBuilder: React.FC<DocumentBuilderProps> = ({ documentId, contentId }) => {
  const { socketClient, connect } = useSocketClient();
  const isSocketConnected = socketClient.isConnected();

  const [editorConfig, setEditorConfig] = useState<any>(undefined);
  const { setSignaturesOnMount } = useContext(SignaturesContext);
  const { editorRef, setRef } = useEditorRef();

  const { setIsConfigLoaded, setIsSignatureLoaded } = useEditorConfig();
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.chatGPT]);
  const defaultTextStylingFonts = [
    {
      path: 'PlusJakartaSans',
    },
  ];

  const dispatch = useAppDispatch();
  const { loadBlockStyles } = useBlockStyleSettings();

  const { handleSelectedBlockInfoByIcon, handleSelectedBlockInfoByWrapper } = useContext(SelectionContext);

  const editorConfigHandler = (newConfig) => {
    setEditorConfig(newConfig);
    setIsConfigLoaded(true);
  };

  const sectionInitialLoadCallback = ({ content: sectionsLoadData }: { content: SectionContentCollectionType | null }) => {
    if (sectionsLoadData && sectionsLoadData.sections.length > 0) {
      const initialSection = sectionsLoadData.sections;

      loadBlockStyles(initialSection);
      const blocksState = convertSectionsLoadDataToContentBlocks(sectionsLoadData);
      dispatch(setInitialState(blocksState));
    } else {
      dispatch(setInitialState({ sections: [] }));
    }
  };

  useEffect(() => {
    socketClient.getAllSignaturesOnMount((data) => {
      setSignaturesOnMount(data);
      setIsSignatureLoaded(true);
    });

    connect();

    return () => {
      socketClient.disconnect();
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (isSocketConnected) {
      socketClient.publish(SectionEvents.SECTION_LOAD, null, sectionInitialLoadCallback);
    }
  }, [isSocketConnected]);

  useEffect(() => {
    //ChatGPT Button
    if (evaluatedFeatureFlags[FeatureFlags.chatGPT]) {
      Froala.DefineIcon('chatGPT', { NAME: 'search', SVG_KEY: 'search' });
      Froala.RegisterCommand('chatGPT', {
        title: 'Ask ChatGPT',
        icon: 'chatGPT',
        focus: false,
        undo: false,
        refreshAfterCallback: false,
        callback: async function callback() {
          const data = {
            model: 'gpt-3.5-turbo',
            max_tokens: 256,
            temperature: 0,
            messages: [
              {
                role: 'system',
                content: 'You are a world class sales leader and you help write sales proposals',
              },
              {
                role: 'user',
                content: this.selection.text(),
              },
            ],
          };
          // Make the API call to ChatGPT
          const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${CONFIG.CHAT_GPT_KEY}`,
            },
            body: JSON.stringify(data),
          });
          const { choices } = await response.json();
          // replace the suggestion into selected text
          this.html.insert(choices[0].message.content);
        },
      });
    }
  }, [evaluatedFeatureFlags[FeatureFlags.chatGPT]]);

  useEffect(() => {
    dispatch(setInitialVariablesState(contentId));
  }, []);

  const onMouseDown = () => {
    handleSelectedBlockInfoByWrapper({ sectionId: null, blockId: null });
    handleSelectedBlockInfoByIcon({ sectionId: null, blockId: null });
  };

  return (
    <ThemeProvider theme={ProposifyTheme}>
      <CssBaseline />
      <FeatureFlagged flags={sectionsDrawerFlags} Component={SectionsDrawerProvider}>
        <div className="editor-header-wrapper">
          <RenderEditorMenu documentId={documentId} assetId={documentId} />
          <DefaultFontLoader defaultFonts={defaultTextStylingFonts} />
          <FontLoader contentId={contentId} editorConfigHandler={editorConfigHandler} />
        </div>
        <DndProvider manager={dragDropManager}>
          <DocumentSettingsProvider>
            <SidePanelProvider>
              <FeatureFlagged flags={sectionsDrawerFlags} Component={DocumentBuilderLeftPanel} hideChildrenToo>
                <SectionsList />
              </FeatureFlagged>
              <DocumentBuilderMainContent>
                <Container data-testid="editor-document-main-content" onMouseDown={onMouseDown}>
                  <Sections documentId={documentId} setRef={setRef} editorConfig={editorConfig} />
                </Container>
              </DocumentBuilderMainContent>
              <DocumentBuilderRightPanel>
                <EditorSidePanel documentId={documentId} editorRef={editorRef} />
              </DocumentBuilderRightPanel>
            </SidePanelProvider>
          </DocumentSettingsProvider>
        </DndProvider>
      </FeatureFlagged>
    </ThemeProvider>
  );
};

export default DocumentBuilder;
