import { TotalRowTypesIdentifier } from '../../../grid/reduxStore/table.types';
import PricingTableTotalFooterRow from '../Table/PricingTotalsFooter/PricingTableTotalFooterRow';
import getTotalFooterRowValue from '../Table/PricingTotalsFooter/getTotalFooterRowValue';
import { FooterWrapper, FORMATTER_KEY, TITLE_KEY } from '../Table/PricingTotalsFooter/PricingTableTotalsFooter';
import { ToggledInputWrapper, TitleTextWrapper } from '../Table/PricingTotalsFooter/ToggledInput';
import TextEditor from '../../TiptapEditor/components/TextEditor.tsx';

interface PreviewTableFooterProps {
  updatedTotalRows: any[];
  sumOfSubtotalColumn: number;
  pricingDecimalPlaces: number;
}

function PreviewTableFooter({ updatedTotalRows, sumOfSubtotalColumn, pricingDecimalPlaces }: PreviewTableFooterProps) {
  return (
    <FooterWrapper>
      {updatedTotalRows?.map((row) => {
        const { isHidden, displayedValue } = getTotalFooterRowValue(row);
        const rowValue = row.rowType === TotalRowTypesIdentifier.SUBTOTAL ? sumOfSubtotalColumn : row.subtotal;
        const value = row[FORMATTER_KEY];
        const titleValue = row[TITLE_KEY];
        const isValueInTipTapFormat = typeof titleValue === 'string' && (titleValue.includes('<h') || titleValue.includes('<p'));
        return (
          <PricingTableTotalFooterRow
            key={row.id}
            title={row.title}
            rowType={row.rowType as TotalRowTypesIdentifier}
            subtotalValue={rowValue}
            priceValue={value}
            isRowSelected={false}
            pricingDecimalPlaces={pricingDecimalPlaces}
            calcInputComponent={
              <ToggledInputWrapper data-testid={`${row.title?.toLowerCase()}-preview`} isHidden={isHidden}>
                {!isHidden ? displayedValue : ''}
              </ToggledInputWrapper>
            }
            titleComponent={
              <ToggledInputWrapper>
                {isValueInTipTapFormat ? (
                  <TextEditor isPreview={true} editable={false} value={row.title} />
                ) : (
                  <TitleTextWrapper>{row.title}</TitleTextWrapper>
                )}
              </ToggledInputWrapper>
            }
          />
        );
      })}
    </FooterWrapper>
  );
}

export default PreviewTableFooter;
