/** @jsxImportSource @emotion/react */
import { Editor } from '@tiptap/react';
import { useEffect, useState, useCallback } from 'react';
import { Node } from '@tiptap/pm/model';

import Icon from '../components/Icon';
import IcoArrowDropDownRounded from 'components/icon/icons/IcoArrowDropDownRounded';

import PyMuiMenu from '../components/generalComponents/PyMuiMenu';
import PyMuiMenuItem from '../components/generalComponents/PyMuiMenuItem';
import { styled } from '@mui/material';
import { PyMuiButton } from '../components/generalComponents';

import { createTextStyleHandler } from '../helpers/textStyleHelpers';
import { DEFAULT_FONT_SIZES } from 'components/editor/grid/reduxStore/defaultTextDefaultValue';
import PyMuiMenuLabel from '../components/generalComponents/PyMuiMenuLabel';
import { useAppSelector } from 'components/editor/grid/reduxStore/Store';
import { selectAllTextDefaultStyles } from 'components/editor/grid/reduxStore/textDefaultStylingSlice';

const isActive = (editor: Editor | null, fontSize: string) => editor?.isActive('fontSize', { fontSize }) ?? false;

const FontSizeContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
});

type Props = {
  editor: Editor;
};

const FontSize = ({ editor }: Props) => {
  const textDefaultStyles = useAppSelector(selectAllTextDefaultStyles);

  const getDefaultFontSize = () => {
    const parent = editor.state.selection.$from.parent;
    const nodeType = parent.type.name === 'heading' ? `h${parent.attrs.level}` : 'p';

    return textDefaultStyles[nodeType]?.fontSize;
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<string>(getDefaultFontSize);

  const handleOpenMenu = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSelectFontSize = useCallback(
    (name: string, value: string) => {
      editor?.chain().focus()[value ? 'setFontSize' : 'unsetFontSize'](value).run();

      setSelected(name);
      handleClose();
    },
    [editor, handleClose]
  );

  useEffect(() => {
    const updateSelectedSize = createTextStyleHandler({
      attributeName: 'fontSize',
      getParentAttribute: getFontSizeParentAttribute,
      parseValue: (value) => parseInt(value),
    });

    editor.on('transaction', (event) => updateSelectedSize(event, setSelected));
    return () => {
      editor.off('transaction', (event) => updateSelectedSize(event, setSelected));
    };
  }, [editor]);

  const getFontSizeParentAttribute = (parent: Node | null) => {
    if (!parent) {
      throw new Error('Parent node is required');
    }
    switch (parent.type.name) {
      case 'heading': {
        const level = parent.attrs.level;
        return textDefaultStyles[`h${level}`]?.fontSize;
      }
      case 'paragraph': {
        return textDefaultStyles['p']?.fontSize;
      }
      default:
        throw new Error('Unsupported parent node type');
    }
  };

  return (
    <FontSizeContainer>
      <PyMuiButton
        testId="font-size-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isActive(editor, selected)}
        width={58}
      >
        <PyMuiMenuLabel style={{ minWidth: 'auto' }}>{selected}</PyMuiMenuLabel>
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>

      <PyMuiMenu
        testId="font-size-menu"
        id="select-font-family-menu"
        menuListProps="select-font-size-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleClose}
      >
        {DEFAULT_FONT_SIZES.map(({ name, value }) => (
          <PyMuiMenuItem
            key={name}
            testId={`font-size-menu-item-${value}`}
            onClick={() => handleSelectFontSize(name, value)}
            isActive={selected === name}
            menuItem={name}
            customStyle={{ minWidth: 58 }}
          />
        ))}
      </PyMuiMenu>
    </FontSizeContainer>
  );
};

export default FontSize;
