import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiEditorRepository } from '../../../services/repositories/implementations/ApiEditorRepository';
import { ContentType } from 'utils/getContentType';

type Options = {
  enabled?: boolean;
  contentType?: ContentType;
};

export function useGetVariables(documentId: string, options: Options = { enabled: true, contentType: ContentType.DOCUMENT }) {
  const editorRepository = new ApiEditorRepository();

  const getVariables = () => {
    if (options.contentType !== ContentType.DOCUMENT) {
      return { user: [], client: [], proposal: [] };
    }

    return editorRepository.getVariables(documentId);
  };

  const { data, isError } = useQuery(QueryKeys.variables, () => getVariables(), {
    enabled: options.enabled,
    notifyOnChangeProps: ['data', 'error'],
  });

  return {
    data,
    isError,
  };
}
