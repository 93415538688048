import { useParams } from 'react-router-dom';
import { SectionsProvider } from './useSectionsForPreview';
import { PreviewGridPage } from './previewGrid';
import { PreviewVariablesProvider } from '../../providers/PreviewVariablesContext';
export function PreviewGridWrapper() {
  const { documentId: documentIdFromParam } = useParams<{ documentId: string }>();
  const documentId = documentIdFromParam as string;
  return (
    <SectionsProvider documentId={documentId}>
      <PreviewVariablesProvider documentId={documentId}>
        <PreviewGridPage />
      </PreviewVariablesProvider>
    </SectionsProvider>
  );
}
