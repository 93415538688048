import { Box } from '@mui/material';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSelector } from 'react-redux';
import { FeatureFlags } from '../../../utils/featureFlags';
import { RootState } from '../grid/reduxStore/Store';
import { useActiveEditor } from '../GridDndEditor/TiptapEditor/ActiveEditorContext';
import TipTapBarMenu from '../GridDndEditor/TiptapEditor/TipTapBarMenu';
import SectionsToggleButton from '../SectionsDrawer/SectionsToggleButton';
import { TableSettingsTypes } from '../SidePanel/content/TableSettings/types';
import { EditorFroalaToolbar } from './EditorFroalaToolbar';
import { EditorUndoRedoToolbar } from './EditorUndoRedoToolbar';
import { useTextBlockSelected } from './useTextBlockSelected';

export function EditorToolbar() {
  const isTipTapEnabled = useFeatureFlag([FeatureFlags.tiptapTextBlockEnabled])[FeatureFlags.tiptapTextBlockEnabled];
  const isSectionsReorderingEnabled = useFeatureFlag([FeatureFlags.editorSectionsReordering])[FeatureFlags.editorSectionsReordering];

  const { activeEditor, isEditMode } = useActiveEditor();
  const { activeSidePanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const isActiveTextBlock = useTextBlockSelected();
  const isActiveRowsPanel = activeSidePanel === TableSettingsTypes.TABLE_ROWS || activeSidePanel === TableSettingsTypes.TABLE_FOOTER_ROWS;
  const isTableMenuBarActive = isActiveRowsPanel && activeEditor !== null;

  const isTextBlockMenuBarActive = isActiveTextBlock && isTipTapEnabled && activeEditor !== null;

  const isTipTapMenuBarActive = (isTableMenuBarActive || isTextBlockMenuBarActive) && isEditMode;

  return (
    <Box
      id="editor-toolbar-container"
      className={'editor-toolbar-container'}
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'normal',
        width: '100%',
        height: '50px',
        borderTop: '1px solid #f0f0f0',
        borderRadius: '5px',
        borderBottom: '1px solid #f0f0f0',
        gap: '1rem',
      }}
    >
      {isSectionsReorderingEnabled && <SectionsToggleButton />}
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          paddingLeft: isSectionsReorderingEnabled ? 0 : '10rem',
        }}
      >
        <EditorUndoRedoToolbar />
        {isTipTapMenuBarActive && <TipTapBarMenu />}
        <EditorFroalaToolbar />
      </Box>
    </Box>
  );
}
