import { Grid, Box, InputAdornment } from '@mui/material';
import { SecondaryMenuItemTitle } from './SecondaryMenuTitles';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactNode } from 'react';

type TextInputBoxSingleColProps = {
  title?: string | ReactNode;
  descriptionText?: string;
  placeholderText?: string;
  onChange: (val: string) => void;
  onBlur?: (val: string) => void;
  onPaste?: (val: string) => void;
  startAdornmentText?: string;
  endAdornmentText?: string;
  value?: string | number;
  error?: string;
  testId?: string;
};

export const TextInputBoxSingleCol = ({
  onChange,
  onBlur,
  onPaste,
  title = 'title',
  descriptionText,
  placeholderText,
  startAdornmentText = '',
  endAdornmentText = '',
  value,
  error,
  testId,
}: TextInputBoxSingleColProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!onBlur) return;
    const newValue = event.target.value;
    onBlur(newValue);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (!onPaste) return;
    const newValue = event.clipboardData.getData('text');
    onPaste(newValue);
  };

  const isError = !!error;

  return (
    <Grid container direction="column" rowGap={1}>
      <SecondaryMenuItemTitle>{title}</SecondaryMenuItemTitle>
      <Grid item width={'100%'} data-testid={testId}>
        <FormControl variant="outlined" size="small" fullWidth>
          <OutlinedInput
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={handlePaste}
            error={isError}
            value={value}
            placeholder={placeholderText}
            startAdornment={<InputAdornment position="start">{startAdornmentText}</InputAdornment>}
            endAdornment={<InputAdornment position="end">{endAdornmentText}</InputAdornment>}
          />
        </FormControl>
      </Grid>
      {descriptionText || error ? (
        <Box component="p" fontSize={12} lineHeight={1.5} letterSpacing={0.18} color={isError ? 'red' : '#353636'}>
          {isError ? error : descriptionText}
        </Box>
      ) : null}
    </Grid>
  );
};
