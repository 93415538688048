import { Box, ToggleButton, Typography } from '@mui/material';
import IcoListAltRounded from 'components/icon/icons/IcoListAltRounded';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSectionsDrawer } from './SectionsDrawerContext';

export default function SectionsToggleButton() {
  const { t } = useTranslation();
  const { isSectionsDrawerOpen, setIsSectionsDrawerOpen } = useSectionsDrawer();

  const toggleSectionsDrawer = useCallback(
    () => setIsSectionsDrawerOpen(!isSectionsDrawerOpen),
    [isSectionsDrawerOpen, setIsSectionsDrawerOpen]
  );

  return (
    <ToggleButton
      value="sections"
      data-testid="sections-reordering-toggle-button"
      sx={{
        flex: '0 0 auto',
        margin: '0.2rem 0',
        padding: '1rem 1.2rem',
        borderRadius: '4px',
        border: 'none',
      }}
      selected={isSectionsDrawerOpen}
      onChange={toggleSectionsDrawer}
    >
      <Box display="flex" alignItems="center" gap="0.5rem">
        <IcoListAltRounded style={{ width: '1.5rem', height: '1.5rem' }} />
        <Typography sx={{ fontWeight: '600' }} variant="labelSmall" textTransform="none">
          {t('editor.editor_menu.sections')}
        </Typography>
      </Box>
    </ToggleButton>
  );
}
