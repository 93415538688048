import * as React from 'react';
const SvgIcoPlayDisabledRounded = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M2.014 1.466a3.7 3.7 0 0 0 0 5.237L23.93 28.617v23.586c0 2.934 3.23 4.717 5.72 3.12l12.814-8.172 22.583 22.583a3.7 3.7 0 0 0 5.237 0 3.7 3.7 0 0 0 0-5.237L7.25 1.466a3.7 3.7 0 0 0-5.237 0M59.883 36.12c2.303-1.449 2.303-4.792 0-6.277L29.648 10.64c-1.002-.632-2.117-.706-3.12-.409l28.786 28.786z"
      fill="currentColor"
    />
  </svg>
);
export default SvgIcoPlayDisabledRounded;
