import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
  useRegistrationForm,
  SubmitHandler,
  transformRegistrationFieldValuesToApiPayload,
  RegistrationFormFieldValues,
} from './useRegistrationForm';
import FormField from '../settings/utils/FormField';
import useRegisterAccountMutation from 'services/api/account/hooks/useRegisterAccountMutation';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { GenericApiResponse, RegisterAccountApiResponse, RegisterAccountEntity } from 'services/api/account/interfaces';

interface RegistrationFormProps {
  onSuccess: (response: RegisterAccountApiResponse) => void;
  onFailure: (response: GenericApiResponse) => void;
  initialData?: RegisterAccountEntity;
}

const RegistrationForm = ({ onSuccess, onFailure, initialData }: RegistrationFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useRegistrationForm(initialData);
  const { registerAccount, isLoading: isUpdating } = useRegisterAccountMutation();

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformRegistrationFieldValuesToApiPayload(submitData);
      const response = await registerAccount(payload);
      if (!response.state) {
        onFailure(response);
        return;
      }

      onSuccess(response);
    },
    [transformRegistrationFieldValuesToApiPayload, registerAccount, onSuccess, onFailure]
  );

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} data-testid="registration-form" sx={{ minWidth: '408px', margin: '0 auto' }}>
      <Stack direction="column" sx={{ pt: '5px', gap: 3 }}>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<RegistrationFormFieldValues>
            id="email"
            label={t('signup.registration_form.email')}
            placeholder={t('signup.registration_form.email')}
            control={control}
            disabled={!!initialData?.email}
            rules={{
              required: t('required_field'),
              pattern: {
                value: /[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+\.){1,}([a-z]{2,16})/g,
                message: t('signup.registration_form.email_invalid'),
              },
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<RegistrationFormFieldValues>
            id="firstName"
            label={t('signup.registration_form.first_name')}
            placeholder={t('signup.registration_form.first_name')}
            control={control}
            rules={{
              required: t('required_field'),
              maxLength: { value: 100, message: 'Must be max 100 characters' },
            }}
          />
          <FormField<RegistrationFormFieldValues>
            id="lastName"
            label={t('signup.registration_form.last_name')}
            placeholder={t('signup.registration_form.last_name')}
            control={control}
            rules={{
              required: t('required_field'),
              maxLength: { value: 100, message: 'Must be max 100 characters' },
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<RegistrationFormFieldValues>
            id="password"
            type="password"
            label={t('signup.accept_invitation_form.password')}
            placeholder={t('signup.accept_invitation_form.password_help')}
            control={control}
            rules={{
              required: t('required_field'),
              minLength: { value: 8, message: 'Must be at least 8 characters long' },
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<RegistrationFormFieldValues>
            id="receiveEmails"
            type="checkbox"
            label={t('signup.registration_form.receive_emails')}
            control={control}
            shrinkLabel={false}
          />
        </Stack>
        <FormField<RegistrationFormFieldValues> id="token" type="hidden" control={control} />
        <FormField<RegistrationFormFieldValues> id="accountId" type="hidden" control={control} />
      </Stack>
      <Box>
        <Button type="submit" variant="contained" size="large" data-testid="registration-form-submit" sx={{ width: '100%' }}>
          {isUpdating ? <CircularProgress color="info" size="22px" /> : t('signup.registration_form.create_account')}
        </Button>
      </Box>
      <Box mt="12px" textAlign="center">
        <Typography variant="caption" color="text.primary">
          <Trans
            i18nKey="signup.registration_form.terms_of_service"
            components={{
              terms: (
                <Link
                  style={{ fontWeight: 400 }}
                  title="Terms of service link"
                  to="https://www.proposify.com/company/terms-of-services"
                  target="_blank"
                />
              ),
              policy: (
                <Link
                  style={{ fontWeight: 400 }}
                  title="Privacy policy link"
                  to="https://www.proposify.com/company/privacy"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default RegistrationForm;
