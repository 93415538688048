import * as React from 'react';
const SvgIcoPlayCircleFilledRounded = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#a)">
      <rect width={112} height={112} fill="white" rx={56} x={18} y={17} />
      <path
        d="M74 26.333C48.24 26.333 27.333 47.24 27.333 73S48.24 119.666 74 119.666 120.667 98.76 120.667 73 99.76 26.333 74 26.333m-9.333 63V56.666c0-1.913 2.193-3.033 3.733-1.866l21.793 16.333c1.26.933 1.26 2.8 0 3.733L68.4 91.2c-1.54 1.166-3.733.046-3.733-1.867"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter width={148} height={148} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" id="a" x={0} y={0}>
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={9} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_642_33383" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
        <feBlend in2="effect1_dropShadow_642_33383" result="effect2_dropShadow_642_33383" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feMorphology in="SourceAlpha" radius={1} result="effect3_dropShadow_642_33383" />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="effect2_dropShadow_642_33383" result="effect3_dropShadow_642_33383" />
        <feBlend in="SourceGraphic" in2="effect3_dropShadow_642_33383" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default SvgIcoPlayCircleFilledRounded;
