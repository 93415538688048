import { useSelector } from 'react-redux';
import { BlockContent, selectBlockContent } from '../grid/reduxStore/editorSlice';
import { RootState } from '../grid/reduxStore/Store';
import { GridBlockType } from '../shared/gridBlockType';
import { useContext } from 'react';
import { SelectionContext } from '../GridDndEditor/SelectedBlockInfoProvider';

export function useTextBlockSelected() {
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const blockId = selectedBlockIdByWrapper as string;
  const blocksContent = useSelector(
    (state: RootState) => selectedSectionId && selectBlockContent(state, selectedSectionId, blockId)
  ) as BlockContent;

  return blocksContent?.type == GridBlockType.TEXT;
}
