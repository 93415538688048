import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';
import { ApiEditorRepository } from '../services/repositories/implementations/ApiEditorRepository';

export function useInstalledFonts(contentId: string) {
  const editorRepository = new ApiEditorRepository();
  const { data, isError, isFetched } = useQuery(QueryKeys.fonts, () => editorRepository.getInstalledFonts(contentId), {
    staleTime: Infinity,
  });
  const result = !data ? [] : data;
  return {
    result,
    isError,
    isFetched,
  };
}
