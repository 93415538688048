import { Tabs, Tooltip } from 'antd';
import Icon from 'components/icon';
import { IconNamesType } from 'components/icon/icons';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ContentType, getContentType } from 'utils/getContentType';
import { EditorRefType } from '../../../hooks/useEditorRef';
import { RootState, useAppDispatch } from '../grid/reduxStore/Store';
import { EditorDefaultSidePanelType, setActiveSidePanel } from '../grid/reduxStore/blockStyleSettingsSlice';
import { GridBlockType } from '../shared/gridBlockType.ts';
import EditorToolbar from '../toolbar/toolbar';
import { RenderEditorSidePanelSettings } from './RenderEditorSidePanelSettings';
import { Signatures } from './Signatures';
import { Variables } from './Variables';
import { SidePanelModels, SidePanelProviderContext } from './content';
import { TableSettingsTypes } from './content/TableSettings/types.ts';
import './styles.less';

interface Props {
  editorRef: EditorRefType;
  documentId: string;
}

export function EditorSidePanel({ editorRef, documentId }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState('document_tab');
  const { activeSidePanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const { setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const isEditorToolbarDisplayed =
    TableSettingsTypes[activeSidePanel] ||
    GridBlockType[activeSidePanel] ||
    activeSidePanel === EditorDefaultSidePanelType.DEFAULT_STYLE_SETTINGS_PANEL;

  const handleTabChange = (key: string) => {
    if (key != 'content_tab') {
      dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
      setToggledTableSettingsPanel(null);
    }
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
    setActiveTab(key);
  };

  useEffect(() => {
    if (isEditorToolbarDisplayed) {
      setActiveTab('content_tab');
    }
  }, [activeSidePanel]);

  const contentType = getContentType();

  const navItems: { key: string; label: string; icon: IconNamesType; children?: React.ReactNode }[] = [
    {
      key: 'document_tab',
      label: contentType === ContentType.DOCUMENT ? t('editor.nav_tab.document') : t('editor.nav_tab.template'),
      icon: 'IcoSettingsLibrary',
      children: <RenderEditorSidePanelSettings documentId={documentId} activeTab={activeTab} />,
    },
    {
      key: 'content_tab',
      label: t('editor.nav_tab.content'),
      icon: 'IcoAddShape',
      children: (
        <>
          <SidePanelModels />
          {activeSidePanel === EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL && <EditorToolbar editorRef={editorRef} />}
        </>
      ),
    },
    {
      key: 'signatures_tab',
      label: t('editor.nav_tab.signatures'),
      icon: 'IcoSignaturePen',
      children: <Signatures />,
    },
    {
      key: 'variables_tab',
      label: t('editor.nav_tab.variables'),
      icon: 'IcoVariables',
      children: <Variables />,
    },
  ];

  const navItemMemo = useMemo(
    () =>
      navItems.map(({ key, label, icon, children }) => ({
        label: <NavItem id={key} label={label} icon={icon} />,
        key: key,
        children: children,
      })),
    [activeTab, navItems]
  );

  return (
    <Tabs
      className={'editor-sidepanel-nav'}
      tabPosition="left"
      defaultActiveKey="content"
      activeKey={activeTab}
      onChange={handleTabChange}
      items={navItemMemo}
      style={{
        height: '100%',
      }}
    />
  );
}

function NavItem({ id, label, icon }: { id: string; label: string; icon: IconNamesType }) {
  const [isOpenTab, setIsOpenTab] = useState(false);
  const dispatch = useAppDispatch();

  const handleMouseEnter = () => {
    setIsOpenTab(true);
  };

  const handleOnClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  const handleMouseLeave = () => {
    setIsOpenTab(false);
  };

  useEffect(() => {
    const hideTooltip = () => {
      setIsOpenTab(false);
    };
    window.addEventListener('scroll', hideTooltip);
    return () => {
      window.removeEventListener('scroll', hideTooltip);
    };
  }, []);

  return (
    <Tooltip placement="left" title={label} open={isOpenTab}>
      <span
        id={id}
        onClick={handleOnClick}
        className="editor-sidepanel-nav__icon-wrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon name={icon} />
      </span>
    </Tooltip>
  );
}
