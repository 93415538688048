import { Fade, Menu } from '@mui/material';
import { TOOLBAR_SPACING } from '../../helpers/toolbarStyleSettings';
import React from 'react';

const classes = {
  menu: (direction?: 'row' | 'column', overrideShadow?: boolean) => ({
    '& .MuiPaper-root': {
      borderRadius: TOOLBAR_SPACING.borderRadius,
      ...(overrideShadow && {
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }),
    },
    '& .MuiList-root': {
      ...(direction === 'row' && {
        display: 'flex',
        flexDirection: 'row',
        padding: '4px',
      }),
    },
    ...(direction === 'row' && {
      '& .MuiMenuItem-root': {
        padding: '0px',
        marginRight: '1px',
      },
    }),
  }),
};

interface SpMuiMenuProps {
  anchorEl: HTMLElement | null;
  id: string;
  menuListProps: string;
  isMenuOpen: boolean;
  isActive?: boolean;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  testId: string;
  children: React.ReactNode;
  direction?: 'row' | 'column';
  sx?: Record<string, any>;
  anchorOrigin?: any;
  overrideShadow?: boolean;
}

const PyMuiMenu = ({
  id,
  anchorEl,
  isMenuOpen,
  handleClose,
  menuListProps,
  testId,
  children,
  direction = 'column',
  sx,
  anchorOrigin,
  overrideShadow = true,
}: SpMuiMenuProps) => {
  return (
    <Menu
      sx={{ ...classes.menu(direction, overrideShadow), ...sx }}
      data-testid={testId}
      id={id}
      MenuListProps={{
        'aria-labelledby': `${menuListProps}`,
      }}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleClose}
      TransitionComponent={Fade}
      anchorOrigin={anchorOrigin}
      onClick={(e) => e.stopPropagation()}
    >
      <div style={{ display: direction === 'row' ? 'flex' : 'block' }}>{children}</div>
    </Menu>
  );
};

export default PyMuiMenu;
