import { useEffect, useState } from 'react';
import { Editor, EditorEvents } from '@tiptap/react';
import { Level } from '@tiptap/extension-heading';

type SelectedLevel = Level | 0 | undefined;

export const useSpotStyleChecker = (editor: Editor) => {
  const [selectedLevel, setSelectedLevel] = useState<SelectedLevel>(0);
  const [isSpotStyleActive, setIsSpotStyleActive] = useState<boolean>(false);

  useEffect(() => {
    const updateSelectedLevel = ({ editor }: EditorEvents['transaction']) => {
      const { from, to } = editor.state.selection;
      const headingsInSelection: (Level | 0)[] = [];
      let styleName = false;
      let hasMarks = false;

      if (editor.state.selection.empty) {
        const parent = editor.state.selection.$from.parent;
        if (parent.type.name === 'heading') {
          headingsInSelection.push(parent.attrs.level);
        } else if (parent.type.name === 'paragraph') {
          headingsInSelection.push(0);
        }
      } else {
        // For non-empty selection, check all nodes between selection
        editor.state.doc.nodesBetween(from, to, (node) => {
          if (node.type.name === 'heading') {
            headingsInSelection.push(node.attrs.level);
          } else if (node.type.name === 'paragraph') {
            headingsInSelection.push(0);
          }
          if (node.marks.length > 0) {
            hasMarks = true;
          }
        });
      }
      if (hasMarks) {
        styleName = true;
      }

      setIsSpotStyleActive(styleName);

      const headingsSet = new Set(headingsInSelection);
      if (headingsSet.size === 1) {
        setSelectedLevel(Array.from(headingsSet)[0]);
      } else {
        setSelectedLevel(undefined);
      }
    };

    editor.on('transaction', updateSelectedLevel);
    return () => {
      editor.off('transaction', updateSelectedLevel);
    };
  }, [editor]);

  return { isSpotStyleActive, selectedLevel, setSelectedLevel };
};
