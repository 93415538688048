import { useCallback, useEffect } from 'react';

import { handleAllUsedVariables } from '../../../grid/reduxStore/usedVariablesSlice';
import { UsedVariable } from '../../../SidePanel/Variables/interfaces';
import { useAppDispatch } from '../../../grid/reduxStore/Store';

export const useUsedVariablesUpdate = (id: string) => {
  const dispatch = useAppDispatch();

  const onSetUsedVariables = useCallback(
    (usedVariables: UsedVariable[]) => {
      dispatch(handleAllUsedVariables({ targetId: id, usedVariables }));
    },
    [dispatch, id]
  );

  useEffect(() => {
    return () => {
      onSetUsedVariables([]);
    };
  }, [dispatch, id, onSetUsedVariables]);

  return { onSetUsedVariables };
};
