import { Editor } from '@tiptap/react';
import { UndoRedoCommandInterface } from '../../../UndoRedo/UndoRedoCommand.ts';

export class TiptapUndoRedoCommand implements UndoRedoCommandInterface {
  private editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }

  async undo(): Promise<boolean> {
    if (!this.editor.can().undo()) return false;
    this.editor.commands.undo();
    return true;
  }

  async redo(): Promise<boolean> {
    if (!this.editor.can().redo()) return false;
    this.editor.commands.redo();
    return true;
  }
}
