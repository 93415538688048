export const ConsoleLogger = {
  isEnabled: false,
  enable: function () {
    this.isEnabled = true;
  },
  disable: function () {
    this.isEnabled = false;
  },
  log: function (...args: any) {
    if (this.isEnabled) {
      window.console.log(...args);
    }
  },
  table: function (...args: any) {
    if (this.isEnabled) {
      window.console.table(...args);
    }
  },
  error: function (...args: any) {
    if (this.isEnabled) {
      window.console.error(...args);
    }
  },
  warn: function (...args: any) {
    if (this.isEnabled) {
      window.console.warn(...args);
    }
  },
};

if (typeof window !== 'undefined' && !globalThis.env.PROD) {
  // in non production environments, you can open the browser console
  // and enable tracking logs by prompting ConsoleLogger.enable()
  globalThis.ConsoleLogger = ConsoleLogger;
}
