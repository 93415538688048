import { Box, List } from '@mui/material';
import { useRef } from 'react';
import Section from './Section';
import useActiveSection from './useActiveSection';
import { useOrderedSections } from './useOrderedSections';

export default function SectionsList() {
  const { sections, reorder } = useOrderedSections();
  const { activeSection } = useActiveSection();

  const activeDraggedSectionRef = useRef<{ sectionId: string; index: number } | null>(null);

  return (
    <Box data-testid="sections-list-side-panel" width="100%" height="100%" paddingTop="1.25rem" paddingRight="0.6rem">
      <List>
        {sections.map((section, index) => (
          <Section
            index={index}
            key={section.id}
            title={section.title}
            id={section.id}
            isActive={activeSection === section.id}
            onReorder={reorder}
            activeDraggedSectionRef={activeDraggedSectionRef}
          />
        ))}
      </List>
    </Box>
  );
}
