import { Grid } from '@mui/material';
import { IconButton } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { Heading } from 'components/typography';

interface DocumentDesignSettingsProps {
  menuTitle: string;
  backButtonOnClick: () => void;
  children: React.ReactNode;
  closeButtonOnClick?: () => void;
  sxObj?: object;
  testId?: string;
  rowGap?: string;
}

export default function SecondaryMenu({
  rowGap = '20px',
  menuTitle,
  children,
  backButtonOnClick,
  testId,
  closeButtonOnClick,
}: DocumentDesignSettingsProps) {
  return (
    <Grid className="secondary-menu" container mt={2} data-testid={testId} rowGap="16px">
      <Grid container justifyContent="space-between" alignItems="center">
        <IconButton data-testid="side-panel-back-button" size="small" onClick={backButtonOnClick}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <Grid flex={1} ml={1}>
          <Heading level={5} style={{ marginBlock: 0 }}>
            {menuTitle}
          </Heading>
        </Grid>
        <IconButton size="small" onClick={closeButtonOnClick ?? backButtonOnClick}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid rowGap={rowGap} container alignItems="center" justifyContent="start">
        {children}
      </Grid>
    </Grid>
  );
}
