import { createTheme, ThemeOptions, alpha } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

const themeOptions: ThemeOptions = {
  palette: {
    background: {
      default: '#F3F8FC',
    },
    primary: {
      main: '#00283a',
      light: alpha('#00283a', 0.6),
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00b8d1',
      contrastText: '#ffffff',
    },
    error: {
      main: '#f23d5e',
    },
    warning: {
      main: '#ffbf0a',
    },
    info: {
      main: '#303F9F',
    },
    success: {
      main: '#12a75c',
    },
    divider: alpha('#00283a', 0.15),
    text: {
      primary: alpha('#00283a', 0.87),
      secondary: alpha('#00283a', 0.6),
    },
    action: {
      active: '#00283A8F',
    },
  },
  typography: {
    fontFamily: 'gellix',
    h1: {
      fontSize: '6rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '1.5px',
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '0.5px',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '0.5px',
    },
    h4: {
      fontSize: '2.124rem',
      fontWeight: 400,
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '133.4%',
      letterSpacing: '0.25px',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.25px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '0.2px',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '175%',
      letterSpacing: '0.15px',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '28px',
          maxWidth: '706px',
          width: '100%',
          padding: '0',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          boxShadow: 'none',
          textTransform: 'inherit',
          fontWeight: 600,
          '&.Mui-disabled': {
            opacity: 0.6,
            cursor: 'inherit',
          },
        },
        sizeSmall: {
          fontWeight: 600,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          background: '#E5F6FD',
          color: '#014361',
          '& .MuiAlert-icon': {
            color: '#303F9F',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: alpha('#00283a', 0.6),
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '.17px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        select: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        displayedRows: {
          marginBottom: 0,
        },
      },
    },
  },
};

const SettingsTheme = createTheme(themeOptions);

export default SettingsTheme;
