/** @jsxImportSource @emotion/react */
import TipTapToolbar from './TipTapToolbar';

import { TOOLBAR_DIMENSION, TOOLBAR_FONT } from '../helpers/toolbarStyleSettings';
import { COLORS } from '../../../../../muiTheme/MuiDataGrid';
import { styled } from '@mui/material/styles';

const ToolBarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: COLORS.white,
  padding: '4px 0 4px 5px',
  width: '100%',
  maxWidth: TOOLBAR_DIMENSION.width,
  height: '40px',
  ...TOOLBAR_FONT,
});

const TipTapBarMenu = () => {
  return (
    <ToolBarWrapper data-testid="tiptap-toolbar-wrapper">
      <TipTapToolbar />
    </ToolBarWrapper>
  );
};

export default TipTapBarMenu;
