import { useQuery } from 'react-query';
import { ApiEditorRepository } from '../../services/repositories/implementations/ApiEditorRepository.ts';

export const useGetContentStyleByContentId = (contentId: string) => {
  const editorRepository = new ApiEditorRepository();
  return useQuery(['contentStyleSettings', contentId], () => editorRepository.getContentStyleSettings(contentId), {
    staleTime: Infinity,
    enabled: !!contentId,
  });
};
