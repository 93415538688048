import { useAppDispatch } from '../grid/reduxStore/Store';
import { setSaveStatus, setSaveStatusBasedOnApiResponse } from '../grid/reduxStore/saveStatusSlice';
import { useSocketClient } from '../../../providers/SocketContext';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { DefaultSocketResponseType } from '../../../services/socket/SocketEvents';
import { updateGridContentObjectBlockState } from '../grid/reduxStore/editorSlice';
import { saveContentObjectWithSocket } from '../grid/reduxStore/saveHandlers';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';

export function useBlockContentObjectChangedHandler() {
  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { data } = useCurrentUser();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const userId = data.id;

  const blockContentChangedCallback = (
    gridBlockContentChangedSocketResponse: DefaultSocketResponseType,
    blockId: string,
    sectionId: string,
    newContent: Record<string, unknown>
  ) => {
    dispatch(setSaveStatusBasedOnApiResponse(gridBlockContentChangedSocketResponse));
    checkDocumentLockStatus(gridBlockContentChangedSocketResponse.errorCode);

    const payload = {
      blockId: blockId,
      content: newContent,
      sectionId: sectionId,
    };
    dispatch(updateGridContentObjectBlockState(payload));
  };

  return async (blockId: string, sectionId: string, newContent: Record<string, unknown>) => {
    dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    return dispatch(
      saveContentObjectWithSocket({
        blockId: blockId,
        content: newContent,
        userId: userId,
        socketClient: socketClient,
        callback: (blockContentChangedSocketResponse) => {
          blockContentChangedCallback(blockContentChangedSocketResponse, blockId, sectionId, newContent);
        },
      })
    ).unwrap();
  };
}
