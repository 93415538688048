import { ApiAnalyticsRepository } from 'services/analytics/ApiAnalyticsRepository';
import { PreviewTrackingIndexedDB } from './PreviewTrackingIndexedDB';
import { ConsoleLogger } from 'utils/ConsoleLogger';

const NOTIFICATION_FREQUENCY = 5000; // milliseconds

type ViewingInfo = {
  email: string;
  id: string;
  accountId: string;
  documentId: string;
  sessionId: string;
};

export class TrackingService {
  private data: Map<string, number>; // time in seconds
  private startedAt: number;
  private intervalId: NodeJS.Timeout | undefined;
  private viewingInfo: ViewingInfo;

  constructor(viewingInfo: ViewingInfo) {
    this.update = this.update.bind(this);
    this.persist = this.persist.bind(this);
    this.restore = this.restore.bind(this);
    this.teardown = this.teardown.bind(this);

    this.data = new Map();
    this.startedAt = Date.now();
    this.viewingInfo = viewingInfo;
    this.restore();

    this.intervalId = setInterval(() => this.persist(), NOTIFICATION_FREQUENCY);
  }

  update(id: string, time: number) {
    const previousTime = this.data.get(id) || 0;
    const newTime = Math.round(time / 1000 + previousTime);
    ConsoleLogger.log(`TrackingService.update ${id.substring(0, 5)}... ${newTime}s`);
    this.data.set(id, newTime);
  }

  teardown() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  private restore() {
    PreviewTrackingIndexedDB.read(`session-${this.viewingInfo.sessionId}`, (data) => {
      this.data = data.viewTimes;
      this.startedAt = data.startedAt;
    });
  }

  private persist() {
    PreviewTrackingIndexedDB.write({
      id: `session-${this.viewingInfo.sessionId}`,
      viewTimes: this.data,
      startedAt: this.startedAt,
    });

    ApiAnalyticsRepository.postTracking({
      events: [
        {
          viewer: {
            email: this.viewingInfo.email,
            id: this.viewingInfo.id,
          },
          accountId: this.viewingInfo.accountId,
          documentId: this.viewingInfo.documentId,
          sessionId: this.viewingInfo.sessionId,
          createdAt: new Date().toISOString(),
          totalViewedTime: Math.round((Date.now() - this.startedAt) / 1000),
          viewTimes: Array.from(this.data.entries()).map(([sectionId, time]) => ({ sectionId, time })),
        },
      ],
    });
  }
}
