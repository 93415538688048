import styled from '@emotion/styled';

const PyMuiMenuLabel = styled.span<{ 'data-testid'?: string }>({
  minWidth: 120,
  textAlign: 'left',
  padding: '6px 2px 6px 6px',
  cursor: 'pointer',
  fontSize: 12,
  fontWeight: 600,
  lineHeight: '150%',
});

export default PyMuiMenuLabel;
