import { useEffect, useRef, useState } from 'react';
import { trackAction, trackError } from 'vendor/datadog';
import { useOrderedSections } from './useOrderedSections';

const SECTION_WRAPPER = '.lazy-loaded-section';
const SECTION_ID_REGEX = /lazy-loaded-section-(\S*)/;
type SectionIndex = string;

export default function useActiveSection() {
  const { sections: s } = useOrderedSections();
  const numberOfSections = s.length;

  const [activeSection, setActiveSection] = useState<string | undefined>();
  const [sections, setSections] = useState<Element[]>([]);
  const visibilityMap = useRef<Record<SectionIndex, number>>({});

  useEffect(() => {
    if (sections.length === numberOfSections) return;

    let attempts = 0;
    let delay = 1000;
    let timeoutId: NodeJS.Timeout | null = null;

    const verifyIfPageIsReady = () => {
      const detectedSections = Array.from(document.querySelectorAll(SECTION_WRAPPER));
      if (detectedSections.length > 0) {
        setSections(
          detectedSections.map((section) => {
            section.id = section.className.match(SECTION_ID_REGEX)?.[1] ?? '';
            return section;
          })
        );
        setActiveSection(detectedSections[0].id);
        trackAction('editor.sections_drawer.detect_sections', { numberOfSections: detectedSections.length, attempts, delay });
      } else if (attempts < 10) {
        attempts += 1;
        delay *= 1.5;
        timeoutId = setTimeout(verifyIfPageIsReady, delay);
      } else {
        trackError(new Error(`editor.sections_drawer.detect_sections: no sections found after ${attempts} attempts`));
      }
    };

    verifyIfPageIsReady();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };
  }, [numberOfSections]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entry) => {
        entry.forEach(({ target, intersectionRatio: newVisibility }: IntersectionObserverEntry) => {
          // Verify if that section is scrolled in (visibility increases)
          const previousVisibility = visibilityMap.current[target.id];
          const variation = newVisibility - previousVisibility;
          if (previousVisibility && variation > 0) {
            // Only set active section if this section has highest visibility
            const maxVisibility = Math.max(...Object.values(visibilityMap.current));
            if (newVisibility >= maxVisibility) {
              setActiveSection(target.id);
            }
          }
          visibilityMap.current[target.id] = newVisibility;
        });
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      }
    );

    sections.forEach((target) => observer.observe(target));

    return () => {
      observer.disconnect();
    };
  }, [sections.length]);

  return { activeSection, setActiveSection };
}
