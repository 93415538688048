import React from 'react';
import { useParams } from 'react-router-dom';

import { EditorContentWrapper } from './editorContentWrapper';
import PageLoader from 'components/page-loader/page-loader';

import { useQuery } from 'react-query';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';
import { DocumentEntity } from 'services/documents/entities';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'utils/urls';

const EditorPage: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const navigate = useNavigate();

  if (!documentId) {
    throw new Error('Document id missing');
  }

  const {
    data: documentData,
    isFetching,
    isFetched,
    status: documentLoadingStatus,
  } = useQuery({
    queryKey: `editor_document_index_get_by_id_${documentId}_call`,
    queryFn: () => apiDocumentRepository.getDocumentById(documentId),
    enabled: !!documentId,
  });

  if (documentLoadingStatus === 'error') {
    navigate(URLS.errorForbiddenAccess);
    return null;
  }

  return (
    <PageLoader isLoading={isFetching} isLoaded={!isFetching && isFetched}>
      <DocumentEditor document={documentData} />
    </PageLoader>
  );
};

const DocumentEditor = ({ document }: { document?: DocumentEntity }) => {
  if (!document || !document?.contentId) {
    throw new Error('Document not found');
  }

  return <EditorContentWrapper id={document.id} contentId={document.contentId} isDocumentLocked={document.isLocked} />;
};

export default EditorPage;
