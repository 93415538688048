import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    clearFormatting: {
      /**
       * Clear all formatting, including marks and block attributes
       */
      clearFormatting: () => ReturnType;
    };
  }
}

export const ClearFormatting = Extension.create({
  name: 'clearFormatting',

  addCommands() {
    return {
      clearFormatting: () => {
        return ({ chain, editor }) => {
          const { selection } = editor.state;
          const { from, to } = selection;

          // Get the parent block node type
          const nodeType = editor.view.state.doc.resolve(from).parent.type.name;

          return chain()
            .focus()
            .unsetAllMarks()
            .setTextSelection({ from, to })
            .updateAttributes('textStyle', {})
            .updateAttributes(nodeType, nodeType.startsWith('heading') ? {} : { level: null })
            .run();
        };
      },
    };
  },
});
