import { RowTypesIdentifier, TableType, TableRowType, TableTypeIdentifier } from '../../../grid/reduxStore/table.types';
import { rootStore, useAppDispatch } from '../../../grid/reduxStore/Store';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from '../../../hooks/UseBlockDimensionChangedHandler';
import { GridApiCommon, GridApiPro, GridCellParams } from '@mui/x-data-grid-pro';

import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import {
  updateGridTableBlockState,
  updateGridDimensionConfig,
  BlockContent,
  selectSectionById,
} from '../../../grid/reduxStore/editorSlice';
import { getSanitizedDataForTableFooter } from '../../../../../utils/getSanitizedDataForTableFooter';
import { setOfRowsForPricingTable } from './defaultData';

export const useTableManipulation = () => {
  const dispatch = useAppDispatch();
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const getBlockContent = (blockId: string, sectionId: string): BlockContent | null => {
    return { ...(selectSectionById(rootStore.getState(), sectionId)?.blocksContent[blockId] as BlockContent) };
  };

  const getTableData = (blockId: string, sectionId: string): TableType | undefined => {
    const blockContent = getBlockContent(blockId, sectionId);
    if (!blockContent) return undefined;
    return blockContent.contentTable;
  };

  const getUpdatedRowsTableDataFromCellEdit = (cellParams: GridCellParams, newHTML: string, blockId: string, sectionId: string) => {
    const tableData = getTableData(blockId, sectionId);
    if (!tableData) return;

    const updatedRows: TableRowType[] = tableData.rows.map((row): TableRowType => {
      if (row.id === cellParams.id && row.rowType !== RowTypesIdentifier.FOOTER) {
        return { ...row, [cellParams.field]: newHTML };
      } else if (row.id === cellParams.id && row.rowType === RowTypesIdentifier.FOOTER) {
        const convertedRowToFooterData = getSanitizedDataForTableFooter(row, tableData.columns, newHTML);
        return { ...convertedRowToFooterData, rowType: RowTypesIdentifier.FOOTER };
      } else {
        return { ...row };
      }
    });

    return { ...tableData, rows: updatedRows };
  };

  const getTableUpdatedDimensions = (tableApi: GridApiPro | GridApiCommon) => {
    if (!tableApi || !tableApi.rootElementRef.current) return undefined;
    const visibleColumns = tableApi.getVisibleColumns();
    const columnsTotalWidth = visibleColumns.reduce((width, column) => width + (column.width ?? 0), 0);

    return {
      width: columnsTotalWidth,
      height: tableApi.rootElementRef.current.offsetHeight as number,
    };
  };

  const updateBlockDimensionsWithTableDimensions = async (blockId: string, sectionId: string, tableApi: GridApiPro | GridApiCommon) => {
    const dimensions = getTableUpdatedDimensions(tableApi);
    if (!dimensions) return;

    await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, { widthPx: dimensions.width, heightPx: dimensions.height });
  };

  const setTableDecimalPlaces = async (blockId: string, sectionId: string, updatedDecimalPlaces: number) => {
    const tableData = getTableData(blockId, sectionId);
    if (!tableData) return;
    const { metadata } = tableData;
    const updatedTableData = {
      ...tableData,
      metadata: {
        ...metadata,
        pricingDecimalPlaces: updatedDecimalPlaces,
      },
    } as TableType;

    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const addCustomFieldToRow = ({
    blockId,
    sectionId,
    field,
    id,
    cellConfigObject,
  }: {
    blockId: string;
    sectionId: string;
    field: string;
    id: string;
    cellConfigObject: Record<string, string | number | boolean>;
  }) => {
    const tableContentData = getTableData(blockId, sectionId);
    if (!tableContentData) return;

    const updatedRowsData: TableRowType[] = tableContentData.rows.map((row): TableRowType => {
      const customField = row.cellConfig && { ...row.cellConfig?.[field] };
      return row.id === id ? { ...row, cellConfig: { ...row.cellConfig, [field]: { ...customField, ...cellConfigObject } } } : row;
    });
    return {
      ...tableContentData,
      rows: updatedRowsData,
    };
  };

  const addSlotRowField = ({
    blockId,
    sectionId,
    field,
    id,
    cellConfig,
  }: {
    blockId: string;
    sectionId: string;
    field: string;
    id: string;
    cellConfig: Record<string, string | number | boolean>;
  }): TableRowType[] | undefined => {
    const totalRows = getTableData(blockId, sectionId)?.totalRows;
    if (!totalRows) return;

    return totalRows.map((row): TableRowType => {
      return row.id === id ? { ...row, cellConfig: { ...row.cellConfig, price: { ...row.cellConfig?.[field], ...cellConfig } } } : row;
    });
  };

  const toggleTableType = (blockId: string, sectionId: string) => {
    const tableContentData = getTableData(blockId, sectionId);

    if (!tableContentData) return;

    const { metadata } = tableContentData;
    const isPricingActive = metadata?.tableType === TableTypeIdentifier.PRICING_TABLE;

    return {
      ...tableContentData,
      totalRows: isPricingActive ? [] : setOfRowsForPricingTable,
      metadata: {
        ...tableContentData.metadata,
        tableType: isPricingActive ? TableTypeIdentifier.TEXT_TABLE : TableTypeIdentifier.PRICING_TABLE,
      },
    };
  };

  const updateTableBlockContentStoreState = (blockId: string, sectionId: string, tableData: TableType) => {
    const payload = {
      sectionId: sectionId,
      blockId: blockId,
      contentTable: tableData,
    };
    return dispatch(updateGridTableBlockState(payload));
  };

  const updateTableBlockDimensionsStoreState = (
    blockId: string,
    sectionId: string,
    { width, height }: { width: number; height: number }
  ) => {
    dispatch(updateGridDimensionConfig({ sectionId, blockId, width, height }));
  };

  return {
    getBlockContent,
    getTableData,
    getTableUpdatedDimensions,
    updateBlockDimensionsWithTableDimensions,
    toggleTableType,
    setTableDecimalPlaces,
    updateTableBlockContentStoreState,
    updateTableBlockDimensionsStoreState,
    getUpdatedRowsTableDataFromCellEdit,
    addCustomFieldToRow,
    addSlotRowField,
  };
};
