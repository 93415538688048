import { useSocketClient } from 'providers/SocketContext';
import { useCallback } from 'react';
import { DefaultSocketResponseType, SectionEvents } from 'services/socket/SocketEvents';
import { setSaveStatus, setSaveStatusBasedOnApiResponse } from '../grid/reduxStore/saveStatusSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';

type SectionsReorderPayload = {
  id: string;
  title: string;
  moveAfterSectionId: string;
};

export function usePublishSectionsReorder() {
  const { socketClient } = useSocketClient();
  const isSocketConnected = socketClient.isConnected();
  const dispatch = useAppDispatch();
  const { checkDocumentLockStatus } = useDocumentLockedModal();

  return useCallback(
    (payload: SectionsReorderPayload) => {
      if (isSocketConnected) {
        dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
        socketClient.publish(SectionEvents.SECTION_CHANGED, payload, (response: DefaultSocketResponseType) => {
          dispatch(setSaveStatusBasedOnApiResponse(response));
        });
      }
    },
    [socketClient, isSocketConnected, dispatch, checkDocumentLockStatus]
  );
}
