import { ReactNode } from 'react';
import { BlockContent } from 'components/editor/grid/reduxStore/editorSlice';
import { PreviewBlock } from 'components/editor/GridDndEditor/Block/Preview/PreviewBlock';
import EmptyVideoPlaceholder from 'components/editor/GridDndEditor/Block/Video/EmptyVideoPlaceholder';
import { gridPixelSize } from 'components/editor/shared/gridConfig';
import { VideoContent } from 'components/editor/videos/video';
import YouTubeEmbed from 'components/editor/videos/youtubeEmbed';

type Props = {
  block: BlockContent;
};

export const PreviewVideo = ({ block }: Props) => {
  const { type, videoId, title, author } = block.contentObject as VideoContent;
  const { width, height } = block.blockConfig;

  let videoContent: ReactNode;
  if (videoId) {
    switch (type) {
      case 'youtube':
        videoContent = <YouTubeEmbed videoId={videoId} width={width} height={height} title={`${title} by ${author}`} />;
        break;
      default:
        videoContent = <EmptyVideoPlaceholder dimension={{ width, height }} />;
    }
  } else {
    videoContent = <EmptyVideoPlaceholder dimension={{ width, height }} />;
  }

  return (
    <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
      {videoContent}
    </PreviewBlock>
  );
};
