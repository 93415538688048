import { datadogRum } from '@datadog/browser-rum';
import { ConsoleLogger } from 'utils/ConsoleLogger';
import { isProduction } from 'utils/getEnvironment';

export { DatatogTracker } from './DatadogTracker';

export const trackError = (error: Error) => {
  if (!isProduction()) {
    ConsoleLogger.log(`trackError: ${error}`);
    return;
  }
  datadogRum.addError(error);
};

export const trackAction = (action: string, data: Record<string, unknown>) => {
  if (!isProduction()) {
    ConsoleLogger.log(`trackAction: ${action}`, data);
    return;
  }
  datadogRum.addAction(action, data);
};

export const setUserData = (data: Record<string, unknown>) => {
  if (!isProduction()) {
    ConsoleLogger.log(`setUserData: ${JSON.stringify(data)}`);
    return;
  }
  datadogRum.setUser({ ...datadogRum.getUser(), ...data });
};
