import * as React from 'react';
const SvgIcoVideo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M20 21.8H4c-.7 0-1.4-.3-1.9-.8s-.9-1.3-.9-2V5c0-.7.3-1.4.8-1.9.6-.6 1.3-.9 2-.9h16a2.73 2.73 0 0 1 2.7 2.7v14c0 .7-.3 1.4-.8 1.9s-1.2 1-1.9 1M4 3.8c-.3 0-.6.1-.9.4-.2.2-.3.5-.3.8v14c0 .3.1.6.4.9.2.2.6.4.9.4h16c.3 0 .7-.1.9-.4s.4-.6.4-.9V5c0-.3-.1-.6-.4-.9-.2-.2-.5-.4-.9-.4H4z" />
      <path d="M22 8.8H2c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h20c.4 0 .8.3.8.8s-.4.8-.8.8" />
      <path d="M6 8.8c-.1 0-.2 0-.3-.1-.4-.2-.6-.6-.4-1l2-5c.2-.4.6-.6 1-.4s.6.6.4 1l-2 5c-.1.3-.4.5-.7.5M11 8.8c-.1 0-.2 0-.3-.1-.4-.2-.6-.6-.4-1l2-5c.2-.4.6-.6 1-.4s.6.6.4 1l-2 5c-.1.3-.4.5-.7.5M16 8.8c-.1 0-.2 0-.3-.1-.4-.2-.6-.6-.4-1l2-5c.2-.4.6-.6 1-.4s.6.6.4 1l-2 5c-.1.3-.4.5-.7.5M10.8 17.7c-.2 0-.5-.1-.7-.2-.4-.2-.7-.7-.7-1.2v-3.7c0-.5.3-1 .7-1.2s1-.2 1.4 0l3.2 1.8c.4.2.7.7.7 1.2s-.3 1-.7 1.2l-3.2 1.8c-.2.3-.5.3-.7.3m0-4.9v3.4l2.9-1.7z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h24v24H0z" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoVideo;
