import { Editor } from '@tiptap/core';
import { VariableEntity } from '../../../../../../services/entities/VariablesEntity';
import { formatVariableValueWithPricing } from '../../../../helpers/formatVariableValueWithPricing';
import { UsedVariable } from '../../../../SidePanel/Variables/interfaces';
import { throttle } from '../../../../../../utils/throttle';

export const formatVariable = (name: string, variable?: VariableEntity) => {
  if (!variable) {
    return name;
  }

  const formattedValue = formatVariableValueWithPricing(variable);

  return formattedValue || variable.value || variable.friendlyName || name;
};

export const updateUsedVariables = (editor: Editor, setUsedVariables: (variables: UsedVariable[]) => void) => {
  const usedVariables: Record<string, UsedVariable> = {};
  editor.state.doc.descendants((node) => {
    if (node.type.name === 'variable') {
      const allVariables: VariableEntity[] = editor.storage.variable.variables;

      const variable = allVariables.find((variable) => variable.name === node.attrs.name);
      if (variable) {
        usedVariables[variable.name] = { name: variable.name, type: variable.type };
      }
    }
  });
  setUsedVariables(Object.values(usedVariables));
};

export const updateUsedVariablesThrottled = throttle(updateUsedVariables, 500);
