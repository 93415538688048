import * as React from 'react';
const SvgIcoFontColor = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18 17H2c-1.1 0-2 .9-2 2s.9 2 2 2h16c1.1 0 2-.9 2-2s-.9-2-2-2" fill="currentColor" />
    <path
      d="M5.11 14c.48 0 .91-.3 1.06-.75l1.01-2.83h5.65l.99 2.82c.16.46.59.76 1.07.76.79 0 1.33-.79 1.05-1.52L11.69 1.17C11.43.47 10.75 0 10 0S8.57.47 8.31 1.17L4.06 12.48c-.28.73.27 1.52 1.05 1.52M9.94 2.6h.12l2.03 5.79H7.91z"
      fill="currentColor"
      fillOpacity={0.54}
    />
  </svg>
);
export default SvgIcoFontColor;
