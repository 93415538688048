import { useSelector } from 'react-redux';
import { useSectionId } from './SectionIdProvider';
import { type RootState, rootStore } from '../grid/reduxStore/Store';
import { type BlockContent, type BlocksContentCollection, selectSectionById } from '../grid/reduxStore/editorSlice';
import { useCallback } from 'react';
import { type EditorConfig } from '../helpers/config';

export const useSectionData = () => {
  const sectionId = useSectionId();
  const section = useSelector((state: RootState) => selectSectionById(state, sectionId));

  const getBlockContent = (blockId: string) => {
    return section?.blocksContent[blockId] as BlockContent;
  };

  const getSectionBlocksLayer = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return rootStore.getState().gridBlockReducer.sections.find((section) => section.id === sectionId)!.blocksLayer;
  }, [sectionId]);

  return {
    blocksContent: section?.blocksContent as BlocksContentCollection,
    editorConfig: section?.editorConfig as EditorConfig,
    blocksMetadata: section?.blocksMetadata,
    sectionId: sectionId,
    getSectionBlocksLayer,
    getBlockContent,
  };
};
