import { useForm, SubmitHandler as SubmitHandlerT } from 'react-hook-form';
import { RegisterAccountEntity } from 'services/api/account/interfaces';

export type RegistrationFormFieldValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  receiveEmails: boolean;
  token?: string;
  accountId?: number;
};

export type SubmitHandler = SubmitHandlerT<RegistrationFormFieldValues>;

// everything is currently the same, but could change based on needs later on
export const transformRegistrationFieldValuesToApiPayload = ({
  email,
  firstName,
  lastName,
  password,
  receiveEmails,
}: RegistrationFormFieldValues): RegisterAccountEntity => {
  return {
    email,
    firstName,
    lastName,
    password,
    receiveEmails,
  };
};

export function useRegistrationForm(loadedValues?: RegistrationFormFieldValues) {
  return useForm<RegistrationFormFieldValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      receiveEmails: false,
      accountId: undefined,
      token: undefined,
    },
    mode: 'onSubmit',
    values: loadedValues || undefined,
  });
}
