import SettingsTheme from 'muiTheme/SettingsTheme';
import { Box, Card, ThemeProvider, Typography, ListItem, Button, List, ListItemIcon, Grid } from '@mui/material';
import PageLoader from 'components/page-loader/page-loader';
import ProposifyLogo from '../../images/proposify-logo.png';
import BillingCardHeader from 'pages/settings/utils/BillingCardHeader';
import { useTranslation, Trans } from 'react-i18next';
import FormatShapesRounded from '@mui/icons-material/FormatShapesRounded';
import FolderSpecialRounded from '@mui/icons-material/FolderSpecialRounded';
import BoltRounded from '@mui/icons-material/BoltRounded';
import OnboardingAnimation from '../../videos/Proposify Onboarding Animation.mp4';
import { ChevronLeftRounded } from '@mui/icons-material';

const FirstTemplatePage = () => {
  const { t } = useTranslation();
  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <Box sx={{ backgroundColor: '#F3F8FC' }} data-testid="first-template-page-container" px={3}>
          <img
            src={ProposifyLogo}
            data-testid="proposify-logo"
            width="120px"
            alt="Proposify Logo"
            style={{ margin: '0 auto', display: 'block', marginTop: '36px', marginBottom: '24px' }}
          />
          <Card
            data-testid="first-template-page-content"
            sx={{
              borderRadius: '24px',
              boxShadow: 'none',
              padding: '40px 40px 16px',
              margin: '0 auto 36px',
              maxWidth: '600px',
            }}
          >
            <BillingCardHeader title={t('signup.first_template.title')} />
            <Grid container alignItems="stretch">
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <p>
                  <Typography> {<Trans i18nKey="signup.first_template.sub_header" transSupportBasicHtmlNodes />}</Typography>
                </p>
                <Typography sx={{ textAlign: 'left' }}>
                  {<Trans i18nKey="signup.first_template.templates" transSupportBasicHtmlNodes />}
                </Typography>
                <List sx={{ width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                  <ListItem>
                    <ListItemIcon>
                      <FormatShapesRounded
                        sx={{ fontSize: 35, padding: 0.5, backgroundColor: '#E6F8FA', borderRadius: '10%' }}
                        htmlColor="#00b8d1"
                      />
                    </ListItemIcon>
                    <Typography variant="body1">{<Trans i18nKey="signup.first_template.bullet_1" transSupportBasicHtmlNodes />}</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FolderSpecialRounded
                        htmlColor="#00b8d1"
                        sx={{ fontSize: 35, padding: 0.5, backgroundColor: '#E6F8FA', borderRadius: '10%' }}
                      />
                    </ListItemIcon>
                    <Typography variant="body1">{<Trans i18nKey="signup.first_template.bullet_2" transSupportBasicHtmlNodes />}</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <BoltRounded
                        htmlColor="#00b8d1"
                        sx={{ fontSize: 35, padding: 0.5, border: '10px', backgroundColor: '#E6F8FA', borderRadius: '10%' }}
                      />
                    </ListItemIcon>
                    <Typography variant="body1">{<Trans i18nKey="signup.first_template.bullet_3" transSupportBasicHtmlNodes />}</Typography>
                  </ListItem>
                </List>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <video style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} autoPlay loop muted>
                    <source src={OnboardingAnimation} type="video/mp4" />
                  </video>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, alignItems: 'center' }}>
              <Button variant="text">
                <ChevronLeftRounded /> Back
              </Button>
              <Button variant="contained" color="primary" sx={{ borderRadius: 5, px: 4 }}>
                {t('signup.first_template.continue_button')}
              </Button>
            </Box>
          </Card>
        </Box>
      </ThemeProvider>
    </PageLoader>
  );
};

export default FirstTemplatePage;
