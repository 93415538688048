import IcoPlayDisabledRounded from 'components/icon/icons/IcoPlayDisabledRounded';

export default function EmptyVideoPlaceholder({ dimension }: { dimension: { width: number; height: number } }) {
  return (
    <div
      data-testid="empty-video-placeholder"
      style={{
        width: `${dimension.width}px`,
        height: `${dimension.height}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#D9DADC',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderRadius: '50%',
          width: '10rem',
          height: '10rem',
        }}
      >
        <IcoPlayDisabledRounded color="#757677" width="6rem" height="6rem" />
      </div>
    </div>
  );
}
