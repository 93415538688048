import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EditorDefaultSidePanelType, setActiveSidePanel } from '../../../grid/reduxStore/blockStyleSettingsSlice.ts';
import { SecondaryMenu } from '../../component/index.ts';

import {
  DEFAULT_FONT_FAMILY_OPTIONS,
  DEFAULT_FONT_SIZES,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_LINE_HEIGHT_OPTIONS,
} from 'components/editor/grid/reduxStore/defaultTextDefaultValue.ts';
import { useAppSelector } from 'components/editor/grid/reduxStore/Store.ts';
import { useEffect, useMemo, useState } from 'react';
import { useInstalledFonts } from '../../../../../hooks/useInstalledFonts.ts';
import { useEditorContent } from '../../../../../providers/EditorContentContext.tsx';
import { selectAllTextDefaultStyles, TextDefaultStylingState } from '../../../grid/reduxStore/textDefaultStylingSlice.ts';
import ColorPicker from '../../../shared/components/ColorPicker/ColorPicker.tsx';
import SidePanelAccordion from './SidePanelAccordion.tsx';
import StyledComponentInput from './StyledComponentInput.tsx';
import TextAlignmentButtons from './TextAlignmentButtons.tsx';
import TextStyleButtons from './TextStyleButtons.tsx';

const fontSizeOptions = DEFAULT_FONT_SIZES.map((option) => option.name);
const lineHeightMenuItems = DEFAULT_LINE_HEIGHT_OPTIONS.map(({ name }) => name);
const lineHeightMenuItemsVal = Object.fromEntries(DEFAULT_LINE_HEIGHT_OPTIONS.map(({ name, level }) => [level, name]));

const generateAccordionData = ({
  allAvailableFonts,
  translation,
  textDefaultStyles,
}: {
  allAvailableFonts: string[];
  translation: (value: string) => string;
  textDefaultStyles: TextDefaultStylingState;
}) => {
  const textTypes = Object.keys(textDefaultStyles).map((key) => {
    const value = `document.grid.default_styles.${key}`;
    return { title: translation(value), type: key };
  });
  const matchedFont = (type: string) => {
    const fontFamily = textDefaultStyles[type]?.fontFamily;
    const isFontAvailable = allAvailableFonts.find((font) => font.toLowerCase() === fontFamily?.toLowerCase());
    return isFontAvailable;
  };

  return textTypes.map(({ title, type }) => ({
    title,
    content: [
      {
        componentTitle: translation('document.grid.default_styles.font'),
        components: [
          <StyledComponentInput
            handleChange={() => null}
            value={matchedFont(type) || textDefaultStyles[type]?.fontFamily}
            width="144px"
            key={`font-${title}`}
            menuItems={allAvailableFonts}
          />,
        ],
      },
      {
        componentTitle: translation('document.grid.default_styles.font_size'),
        components: [
          <StyledComponentInput
            handleChange={() => null}
            value={textDefaultStyles[type]?.fontSize}
            width="50px"
            key={`font-${title}`}
            menuItems={fontSizeOptions}
          />,
        ],
      },
      {
        componentTitle: translation('document.grid.default_styles.color'),
        components: [<ColorPicker key={`color-${title}`} currentColor={textDefaultStyles[type]?.color} handleColorChange={() => null} />],
      },
      {
        componentTitle: translation('document.grid.default_styles.text_style'),
        components: <TextStyleButtons textDefaultStyles={textDefaultStyles[type]} />,
      },
      {
        componentTitle: translation('document.grid.default_styles.line_spacing'),
        components: [
          <StyledComponentInput
            key={`line-spacing-${title}`}
            value={lineHeightMenuItemsVal[textDefaultStyles[type]?.lineHeight || DEFAULT_LINE_HEIGHT]}
            menuItems={lineHeightMenuItems}
            handleChange={() => null}
            width="84px"
          />,
        ],
      },
      {
        componentTitle: translation('document.grid.default_styles.text_alignment'),
        components: <TextAlignmentButtons defaultAlignment={textDefaultStyles[type]?.textAlign} />,
      },
    ],
  }));
};

export function DefaultStyleSettings() {
  const [expandedSection, setExpandedSection] = useState<string>('');

  const textDefaultStyles = useAppSelector(selectAllTextDefaultStyles);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { contentId } = useEditorContent();

  const { result: installedFonts } = useInstalledFonts(contentId);

  const allAvailableFonts = useMemo(
    () => [...DEFAULT_FONT_FAMILY_OPTIONS, ...(installedFonts || [])].map((font) => font.name),
    [installedFonts]
  );

  const accordionData = useMemo(
    () => generateAccordionData({ allAvailableFonts, translation: t, textDefaultStyles }),
    [allAvailableFonts, t, textDefaultStyles]
  );

  useEffect(() => {
    if (accordionData.length > 0) {
      setExpandedSection(accordionData[0].title.toLowerCase().replace(/\s/g, '-'));
    }
  }, [accordionData]);

  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  return (
    <SecondaryMenu
      testId={'default-styles-settings'}
      menuTitle={t('document.grid.default_styles.title')}
      backButtonOnClick={handleBackButtonClick}
      rowGap="0px"
    >
      {accordionData?.map((accordion, index) => (
        <SidePanelAccordion
          key={`accordion-${index}`}
          accordion={accordion}
          expanded={expandedSection}
          onChange={(value) => setExpandedSection(value)}
        />
      ))}
    </SecondaryMenu>
  );
}
