import { configureStore } from '@reduxjs/toolkit';
import { gridBlockReducer } from './editorSlice';
import { usedVariablesReducer } from './usedVariablesSlice';
import { allVariables } from './allVariableSlice';
import { blockStyleSettings } from './blockStyleSettingsSlice';
import { saveStatus } from './saveStatusSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { textDefaultStylingReducer } from './textDefaultStylingSlice.ts';

export const rootStore = configureStore({
  reducer: {
    gridBlockReducer,
    usedVariablesReducer,
    textDefaultStylingReducer,
    allVariables,
    blockStyleSettings,
    saveStatus,
  },
  devTools: globalThis.env.DEV,
});

export type AppDispatch = typeof rootStore.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof rootStore.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
