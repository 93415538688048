import { Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSocketClient } from '../../../providers/SocketContext';
import { AcknowledgmentResponseStatus, DefaultSocketResponseType, SectionEvents } from '../../../services/socket/SocketEvents';
import { type RootState, rootStore, useAppDispatch } from '../grid/reduxStore/Store';
import { selectSectionTitleById, updateSectionTitle } from '../grid/reduxStore/editorSlice';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';

const SectionTitleInput = ({ sectionId }: { sectionId: string }) => {
  const sectionTitle = useSelector((state: RootState) => selectSectionTitleById(state, sectionId));
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const latestSectionTitlePersistedRef = useRef<string | undefined>(undefined);
  const latestSectionTitleStateRef = useRef<string>(sectionTitle || '');
  const canUpdateInternalState = () => selectSectionTitleById(rootStore.getState(), sectionId) !== undefined;

  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const { t } = useTranslation();

  const textFieldStyles = useMemo(
    () => ({
      '& .MuiOutlinedInput-root': {
        padding: '8px 12px',
        fontSize: '20px',
        fontWeight: 600,
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0000003B',
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    }),
    []
  );

  const setSectionTitleState = (title: string) => {
    dispatch(
      updateSectionTitle({
        sectionId: sectionId,
        sectionTitle: title,
      })
    );
    latestSectionTitleStateRef.current = title;
  };

  const handleTitleChangeSocketCallback = (response: DefaultSocketResponseType, newTitle: string) => {
    const oldTitle = latestSectionTitlePersistedRef.current;
    if (response.status === AcknowledgmentResponseStatus.OK) {
      latestSectionTitlePersistedRef.current = newTitle;
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
      return;
    }

    if (oldTitle !== undefined && canUpdateInternalState()) {
      setSectionTitleState(oldTitle);
      dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED }));
      checkDocumentLockStatus(response.errorCode);
    }
  };

  const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      inputFieldRef.current?.blur();
    }
  };

  const getTrimmedOrDefaultTitleIfEmpty = (title: string): string => {
    const trimmedTitle = title.trim();
    return trimmedTitle || t('editor.default_content_section_title');
  };

  const persistSectionTitle = (title: string) => {
    if (canUpdateInternalState()) {
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    }

    socketClient.publish(
      SectionEvents.SECTION_CHANGED,
      { id: sectionId, title: title },
      (response) => handleTitleChangeSocketCallback(response, title),
      { force: true }
    );
  };

  const persistLastKnownSectionTitle = () => {
    const newTitle = getTrimmedOrDefaultTitleIfEmpty(latestSectionTitleStateRef.current);

    if (canUpdateInternalState()) {
      setSectionTitleState(newTitle);
    }

    const shouldPersistSectionTitle = latestSectionTitlePersistedRef.current !== newTitle;
    if (shouldPersistSectionTitle) persistSectionTitle(newTitle);
  };

  useEffect(() => {
    // We use this to persist the section title when the page is refreshed but the section wasn't sent to the server
    window.addEventListener('beforeunload', persistLastKnownSectionTitle, { once: true, passive: false });

    latestSectionTitlePersistedRef.current = sectionTitle;

    return () => {
      window.removeEventListener('beforeunload', persistLastKnownSectionTitle);
      // This is to account for lazy loading while scrolling the sections and the click in the back button of the browser;
      persistLastKnownSectionTitle();
    };
  }, []);

  return (
    <Tooltip title={sectionTitle}>
      <TextField
        inputRef={inputFieldRef}
        sx={textFieldStyles}
        variant="outlined"
        size="small"
        fullWidth
        data-testid="section-title-textfield"
        onChange={(e) => setSectionTitleState(e.target.value)}
        onKeyDown={blurOnEnter}
        inputProps={{ maxLength: 100, 'data-testid': 'section-title-textfield-input' }}
        placeholder={t('editor.default_content_section_title')}
        onBlur={persistLastKnownSectionTitle}
        value={sectionTitle}
      />
    </Tooltip>
  );
};

export default SectionTitleInput;
