import { saveConfigWithSocket } from '../grid/reduxStore/saveHandlers';
import { updateGridDimensionConfig } from '../grid/reduxStore/editorSlice';
import { setSaveStatus, setSaveStatusBasedOnApiResponse } from '../grid/reduxStore/saveStatusSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';
import { useCurrentUser } from '../../../providers/UserProvider';
import { useSocketClient } from '../../../providers/SocketContext';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { UndoRedoManager } from '../UndoRedo/UndoRedoManager';
import { UndoRedoCommandForBlocks } from '../UndoRedo/UndoRedoCommand';
import { BlockEvents, DefaultSocketResponseType } from '../../../services/socket/SocketEvents';
import {
  BlockPositionChangedHandlerWithoutUndoRedoType,
  useBlockPositionChangedHandlerWithoutUndoRedo,
} from './UseBlockPositionChangedHandler';
import { getRoundedValue } from '../GridDndEditor/gridHelper.tsx';

type DimensionPositionStateChangedType = {
  widthPx: number;
  heightPx: number;
  yAxisPx: number;
  xAxisPx: number;
};

type DimensionStateChangedType = {
  widthPx: number;
  heightPx: number;
};

export const ALLOWED_DECIMAL_PLACES_FOR_DIMENSION = 2;

export type BlockDimensionChangedHandlerType = (sectionId: string, blockId: string, blockState: DimensionStateChangedType) => Promise<void>;

const useUndoRedoDimensionChangedHandler = (
  blockDimensionChangedHandlerWithoutUndoRedo: BlockDimensionChangedHandlerType,
  blockPositionChangedHandlerWithoutUndoRedo: BlockPositionChangedHandlerWithoutUndoRedoType
) => {
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();

  return (
    sectionId: string,
    blockId: string,
    stateBeforeResizing: DimensionPositionStateChangedType,
    stateAfterResizing: DimensionPositionStateChangedType
  ) => {
    const wasPositionChanged =
      stateBeforeResizing.yAxisPx !== stateAfterResizing.yAxisPx || stateAfterResizing.xAxisPx !== stateAfterResizing.xAxisPx;
    const undoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        const { widthPx, heightPx, yAxisPx, xAxisPx } = stateBeforeResizing;
        await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, mappedBlockId, { widthPx, heightPx });
        if (wasPositionChanged) {
          await blockPositionChangedHandlerWithoutUndoRedo(sectionId, mappedBlockId, { xAxisPx, yAxisPx });
        }
      } catch (error) {
        return false;
      }
      return true;
    };
    const redoCallback = async (undoRedoCommand: UndoRedoCommandForBlocks): Promise<boolean> => {
      try {
        const mappedBlockId = undoRedoCommand.getMappedBlockId(blockId);
        const { widthPx, heightPx, yAxisPx, xAxisPx } = stateAfterResizing;
        await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, mappedBlockId, { widthPx, heightPx });
        if (wasPositionChanged) {
          await blockPositionChangedHandlerWithoutUndoRedo(sectionId, mappedBlockId, { xAxisPx, yAxisPx });
        }
      } catch (error) {
        return false;
      }
      return true;
    };
    undoRedoManager.pushUndoRedoCommands(new UndoRedoCommandForBlocks(blockId, undoCallback, redoCallback));
  };
};

function useBlockDimensionChangedHandlerForSocket(): BlockDimensionChangedHandlerType {
  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { data } = useCurrentUser();
  const userId = data.id;
  const { checkDocumentLockStatus } = useDocumentLockedModal();

  const blockDimensionChangedCallback = (blockDimensionChangedSocketResponse: DefaultSocketResponseType) => {
    dispatch(setSaveStatusBasedOnApiResponse(blockDimensionChangedSocketResponse));
    checkDocumentLockStatus(blockDimensionChangedSocketResponse.errorCode);
  };

  return async (sectionId: string, blockId: string, { widthPx, heightPx }: DimensionStateChangedType) => {
    await dispatch(
      saveConfigWithSocket({
        sectionId,
        blockId,
        width: widthPx,
        height: heightPx,
        userId,
        eventType: BlockEvents.BLOCK_DIMENSION_CHANGED,
        socketClient,
        callback: blockDimensionChangedCallback,
      })
    ).unwrap();
  };
}

export function useBlockDimensionChangedHandlerWithoutUndoRedo(): BlockDimensionChangedHandlerType {
  const dispatch = useAppDispatch();

  const blockDimensionChangedHandlerForSocket = useBlockDimensionChangedHandlerForSocket();

  return async (sectionId: string, blockId: string, { widthPx, heightPx }: DimensionStateChangedType): Promise<void> => {
    const roundedWidth = getRoundedValue(widthPx, ALLOWED_DECIMAL_PLACES_FOR_DIMENSION);
    const roundedHeight = getRoundedValue(heightPx, ALLOWED_DECIMAL_PLACES_FOR_DIMENSION);

    const payload = {
      sectionId,
      blockId,
      width: roundedWidth,
      height: roundedHeight,
    };
    dispatch(updateGridDimensionConfig(payload));
    return await blockDimensionChangedHandlerForSocket(sectionId, blockId, { widthPx: roundedWidth, heightPx: roundedHeight });
  };
}

export function useBlockDimensionChangedHandler() {
  const dispatch = useAppDispatch();
  const blockPositionChangedHandlerWithoutUndoRedo = useBlockPositionChangedHandlerWithoutUndoRedo();
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();

  const undoRedoDimensionChangeHandler = useUndoRedoDimensionChangedHandler(
    blockDimensionChangedHandlerWithoutUndoRedo,
    blockPositionChangedHandlerWithoutUndoRedo
  );

  return async (
    sectionId: string,
    blockId: string,
    stateBeforeResizing: DimensionPositionStateChangedType,
    stateAfterResizing: DimensionPositionStateChangedType
  ): Promise<void> => {
    dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
    if (stateBeforeResizing.yAxisPx !== stateAfterResizing.yAxisPx || stateBeforeResizing.xAxisPx !== stateAfterResizing.xAxisPx) {
      const { xAxisPx, yAxisPx } = stateAfterResizing;
      await blockPositionChangedHandlerWithoutUndoRedo(sectionId, blockId, { xAxisPx, yAxisPx });
    }

    const blockDimensionChangedSocketResponse = await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, stateAfterResizing);

    undoRedoDimensionChangeHandler(sectionId, blockId, stateBeforeResizing, stateAfterResizing);
    return blockDimensionChangedSocketResponse;
  };
}
