import SettingsTheme from 'muiTheme/SettingsTheme';
import { Box, Card, CardContent, Stack, ThemeProvider, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PageLoader from 'components/page-loader/page-loader';
import ProposifyLogo from '../../images/proposify-logo.png';
import BillingCardHeader from 'pages/settings/utils/BillingCardHeader';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationsContext } from 'components/notifications/NotificationsProvider';
import RegistrationForm from './RegistrationForm';

const SignUpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotifications } = useContext(NotificationsContext);

  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <Box sx={{ backgroundColor: '#F3F8FC' }} data-testid="signup-page-container" px={3}>
          <img
            src={ProposifyLogo}
            data-testid="proposify-logo"
            width="120px"
            alt="Proposify Logo"
            style={{ margin: '0 auto', display: 'block', marginTop: '36px', marginBottom: '24px' }}
          />
          <Card
            data-testid="signup-page-content"
            sx={{
              borderRadius: '24px',
              boxShadow: 'none',
              padding: '24px 24px 24px 24px',
              margin: '0 auto 24px',
              maxWidth: '600px',
            }}
          >
            <BillingCardHeader title={t('signup.signup_form.title')} subheader={t('signup.signup_form.sub_header')} />
            <CardContent sx={{ pt: 2, px: 0 }}>
              <Stack direction={{ sm: 'column', md: 'row' }} gap={6}>
                <RegistrationForm
                  onSuccess={(response) => {
                    navigate('/createYourWorkspace', { state: response });
                  }}
                  onFailure={(response) => {
                    setNotifications({
                      severity: 'error',
                      content: response.message || t('signup.generic_error'),
                      autoHide: false,
                    });
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1" color="text.primary">
            <Trans
              i18nKey="signup.already_have_account"
              components={{
                signIn: <Link to="/login" title="Sign in" target="_self" />,
              }}
            />
          </Typography>
        </Box>
      </ThemeProvider>
    </PageLoader>
  );
};

export default SignUpPage;
