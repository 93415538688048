import { PyMuiIconButton } from 'components/editor/GridDndEditor/TiptapEditor/components/generalComponents';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';

interface TextStyle {
  attributeName: 'bold' | 'italic' | 'underline';
  icon: JSX.Element;
}

interface TextDefaultStyles {
  fontWeight?: string;
  fontStyle?: string;
  textDecoration?: string;
}

interface TextStyleButtonsProps {
  textDefaultStyles: TextDefaultStyles;
}

const textStyleButtons: TextStyle[] = [
  { attributeName: 'bold', icon: <FormatBoldIcon /> },
  { attributeName: 'italic', icon: <FormatItalicIcon /> },
  { attributeName: 'underline', icon: <FormatUnderlined /> },
];

const getTextStyleState = (attributeName: TextStyle['attributeName'], styles: TextDefaultStyles): boolean => {
  const styleMap = {
    bold: styles.fontWeight === 'bold',
    italic: styles.fontStyle === 'italic',
    underline: styles.textDecoration === 'underline',
  };
  return styleMap[attributeName] || false;
};

const TextStyleButtons = ({ textDefaultStyles }: TextStyleButtonsProps) => {
  return textStyleButtons.map(({ attributeName, icon }) => {
    return (
      <PyMuiIconButton
        key={`style-${attributeName}`}
        attributeName={attributeName}
        disabled={false}
        isActive={getTextStyleState(attributeName, textDefaultStyles)}
      >
        {icon}
      </PyMuiIconButton>
    );
  });
};

export default TextStyleButtons;
