type Props = {
  videoId: string;
  width: number | string;
  height: number | string;
  title: string;
  autoPlay?: boolean;
};

const YouTubeEmbed: React.FC<Props> = ({ videoId, width, height, title, autoPlay = false }) => {
  return (
    <div className="video-container" style={{ width, height }}>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}${autoPlay ? '?autoplay=1' : ''}`}
        title={title}
        style={{ border: 0 }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;
