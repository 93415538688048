import React from 'react';
import { useDrag } from 'react-dnd';
import { FeatureFlags } from 'utils/featureFlags';
import { Paragraph } from '../..';
import IcoDrag from '../../icon/icons/IcoDrag';
import IcoVideo from '../../icon/icons/IcoVideo';
import './blockSourceButton.less';

interface VideoBlockButtonProps {
  title: string;
}

const VideoBlockButton: React.FunctionComponent<VideoBlockButtonProps> = ({ title }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'VIDEO',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <button ref={drag} draggable className="block-source" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="block-source-icon">
        <IcoVideo />
      </div>
      <div className="block-source-drag-icon">
        <IcoDrag />
      </div>
      <Paragraph size="sm">{title}</Paragraph>
    </button>
  );
};

export default VideoBlockButton;
VideoBlockButton.displayName = 'VideoBlockButton';

export const VideoBlockButtonRequiredFlags = [FeatureFlags.editorVideoBlock];
