/** @jsxImportSource @emotion/react */
import { Editor } from '@tiptap/react';
import { useEffect, useState, useCallback } from 'react';

import FormatLineSpacingRoundedIcon from '@mui/icons-material/FormatLineSpacingRounded';
import PyMuiMenu from '../components/generalComponents/PyMuiMenu';
import PyMuiMenuItem from '../components/generalComponents/PyMuiMenuItem';
import { PyMuiIconButton } from '../components/generalComponents';
import { useActiveEditor } from '../ActiveEditorContext';
import { styled } from '@mui/material';

const DEFAULT_LINEHEIGHT = 'inherit';

export const defaultLineHeightOptions = [
  { name: 'Default', level: DEFAULT_LINEHEIGHT },
  { name: 'Single', level: '1' },
  { name: '1.15', level: '1.15' },
  { name: '1.5', level: '1.5' },
  { name: 'Double', level: '2' },
];

const isActive = (editor: Editor | null, lineHeight: string) => editor?.isActive('lineHeight', { lineHeight }) ?? false;

const LineHeightContainer = styled('span')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const LineHeight = () => {
  const { activeEditor: editor } = useActiveEditor();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<string>(DEFAULT_LINEHEIGHT);

  const handleOpenMenu = useCallback<React.MouseEventHandler<HTMLElement>>((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleSelectLineHeight = useCallback(
    (lineHeight: string, title: string) => {
      editor?.chain().focus().setLineHeight(lineHeight).run();

      setSelected(title || DEFAULT_LINEHEIGHT);
      handleClose();
    },
    [editor, handleClose]
  );

  useEffect(() => {
    const activeType = editor?.isActive('heading') ? 'heading' : 'paragraph';
    const lineHeight = editor?.getAttributes(activeType)?.lineHeight ?? DEFAULT_LINEHEIGHT;
    if (!editor?.isEditable) return;

    setSelected(lineHeight || DEFAULT_LINEHEIGHT);
  }, [editor?.state]);

  return (
    <LineHeightContainer>
      <PyMuiIconButton
        attributeName="lineheight"
        isMenuOpen={Boolean(anchorEl)}
        onClick={(e) => handleOpenMenu(e)}
        isActive={isActive(editor, selected)}
      >
        <FormatLineSpacingRoundedIcon />
      </PyMuiIconButton>

      <PyMuiMenu
        testId="lineheight-menu"
        id="select-lineheight-menu"
        menuListProps="select-lineheight-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleClose}
      >
        {defaultLineHeightOptions.map(({ name, level }) => (
          <PyMuiMenuItem
            key={name}
            testId={`lineheight-menu-item-${name.replace(/\s+/g, '-').toLowerCase()}`}
            onClick={() => handleSelectLineHeight(level, name)}
            isActive={selected === level}
            menuItem={name}
          />
        ))}
      </PyMuiMenu>
    </LineHeightContainer>
  );
};

export default LineHeight;
