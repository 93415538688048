import { GridProps, styled } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { COLORS, SIZES, SPACING } from 'muiTheme/MuiDataGrid';
import { formatPriceWithDollarSign } from '../helper';
import { TotalRowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import { Tooltip } from '@mui/material';
import { getRoundedValueTooltipTitle } from '../components/CustomTableCell';
const pseudoElement = {
  content: "''",
  display: 'block',
  position: 'absolute',
  width: '100%',
  height: '1px',
};

const FooterRowWrapper = styled(Grid)(({ theme }) => ({
  columnSpacing: 2,
  minHeight: SIZES.defaultRowHeight,
  justifyContent: 'center',
  alignItems: 'stretch',
  borderLeft: `0.1px solid ${COLORS.borderBottomColor}`,
  borderRight: `0.1px solid ${COLORS.borderBottomColor}`,
  backgroundColor: COLORS.subtotalTaxDiscountBg,
  position: 'relative',
  '.tiptap p, &': {
    fontWeight: 600,
    color: COLORS.subtotalTaxDiscount,
  },
  '&.py-hide-total-row, &.py-hide-total-row:hover': {
    backgroundColor: COLORS.greyedBackgroundColor,
  },

  '&:last-child': {
    backgroundColor: COLORS.footerHeader,
    borderBottom: 0,
    borderColor: 'transparent',
    '.tiptap p, &': {
      color: theme.palette.primary.contrastText,
    },
  },

  '&.selected-row, &.selected-row + .unselected-row': {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)',
  },

  '&.selected-row': {
    '.py-active-row': {
      '&::before': {
        ...pseudoElement,
        backgroundColor: `${COLORS.HoverNFocus}`,
        top: 0,
        left: 0,
      },
      '&::after': {
        ...pseudoElement,
        backgroundColor: `${COLORS.HoverNFocus}`,
        bottom: 1,
        left: 0,
      },
      borderRight: `1px solid ${COLORS.HoverNFocus}`,
      borderLeft: `1px solid ${COLORS.HoverNFocus}`,
      position: 'relative',
    },
  },
  '&::before, &::after': {
    ...pseudoElement,
  },

  '&:hover::before, &:hover::after': {
    backgroundColor: COLORS.HoverNFocus,
  },
  '&::before': {
    top: 0,
  },
  '&::after': {
    bottom: 0,
    backgroundColor: COLORS.borderBottomColor,
  },
}));

const FooterRowItem = ({
  children,
  testId,
  onClick,
  className,
  ...props
}: {
  children?: ReactNode;
  testId?: string;
  className?: string;
  onClick?: MouseEventHandler;
} & GridProps) => (
  <Grid
    item
    md={3}
    alignItems="center"
    className={className}
    data-testid={testId}
    display="flex"
    justifyContent="flex-end"
    onClick={onClick}
    textAlign="right"
    width="100%"
    {...props}
  >
    {children}
  </Grid>
);

type PricingTableTotalFooterRowProps = {
  title: string;
  rowType: TotalRowTypesIdentifier;
  subtotalValue: number;
  onClick?: MouseEventHandler;
  isRowSelected: boolean;
  priceValue: string;
  pricingDecimalPlaces: number;
  calcInputComponent: ReactNode;
  titleComponent?: ReactNode;
};

type FooterRowItem = {
  key: string;
  testId?: string;
  children?: ReactNode;
  props?: GridProps;
};

const PricingTableTotalFooterRow = ({
  title,
  rowType,
  subtotalValue,
  onClick,
  isRowSelected,
  priceValue,
  pricingDecimalPlaces,
  calcInputComponent,
  titleComponent,
}: PricingTableTotalFooterRowProps) => {
  const computeFormattedValue = () => {
    const hasValidSubtotalAndPrice = typeof subtotalValue === 'number' && !Number.isNaN(subtotalValue) && priceValue !== '';
    const isCalculatedRow = rowType === TotalRowTypesIdentifier.SUBTOTAL || rowType === TotalRowTypesIdentifier.TOTAL;
    const shouldFormat = isCalculatedRow || hasValidSubtotalAndPrice;

    return shouldFormat ? formatPriceWithDollarSign(subtotalValue, pricingDecimalPlaces) : '';
  };

  const testIdValue = title.toLowerCase();

  const footerItems: FooterRowItem[] = [
    { key: 'empty' },
    {
      key: 'title',
      testId: `title-${testIdValue}`,
      children: titleComponent,
    },
    {
      key: 'calcInput',
      testId: `input-${testIdValue}`,
      children: calcInputComponent,
    },
    {
      key: 'value',
      testId: `value-${testIdValue}`,
      children: (
        <Tooltip
          data-testid="footer-cell-tooltip"
          title={getRoundedValueTooltipTitle({ value: subtotalValue, numberOfDecimalPlaces: pricingDecimalPlaces })}
          placement="bottom"
          followCursor
        >
          <span>{computeFormattedValue()}</span>
        </Tooltip>
      ),
      props: { paddingLeft: SPACING.sm, paddingRight: SPACING.sm },
    },
  ];

  return (
    <FooterRowWrapper
      container
      data-testid={`${testIdValue}-row`}
      onClick={onClick}
      className={`${isRowSelected ? 'selected-row' : 'unselected-row'} py-total-row`}
    >
      {footerItems.map(({ key, testId, children, props = {} }) => (
        <FooterRowItem key={key} testId={testId} {...props}>
          {children}
        </FooterRowItem>
      ))}
    </FooterRowWrapper>
  );
};

export default PricingTableTotalFooterRow;
