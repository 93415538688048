import { NavigationItemsProps } from 'utils/navigationItems';
import {
  templatePageAllowedUserPermissions,
  settingsPageAllowedUserPermissions,
  libraryPageAllowedUserPermissions,
  integrationsPageAllowedUserPermissions,
  isPageAllowed,
} from './PagesPermission';
import { UserPermission } from '../entities/UserPermissions';

export const getNavigationItemsWithPermissions = (
  items: NavigationItemsProps[],
  userPermissions: UserPermission[]
): NavigationItemsProps[] => {
  const itemsWithPermissions = items.map((item) => {
    const pagePermissionMap: Record<string, UserPermission[]> = {
      '/template/saved': templatePageAllowedUserPermissions,
      '/library/section': libraryPageAllowedUserPermissions,
      '/integrations': integrationsPageAllowedUserPermissions,
      '/settings': settingsPageAllowedUserPermissions,
    };

    const matchedPath = Object.keys(pagePermissionMap).find((path) => item.href.endsWith(path));
    const pagePermissions: UserPermission[] | null = matchedPath ? pagePermissionMap[matchedPath] : null;

    if (pagePermissions) {
      item.hidden = !isPageAllowed({ userPermissions, allowedPermissions: pagePermissions });
    } else {
      item.hidden = item.hidden ?? false;
    }

    return item;
  });

  return itemsWithPermissions;
};
