import { EditorContent } from '@tiptap/react';
import { useContext, useEffect, useRef } from 'react';
import { useTextEditor } from '../hooks/useTextEditor';
import { ActiveEditorContext } from '../ActiveEditorContext';

import { TextEditorProps } from '../types';
import { UndoRedoManager } from '../../../UndoRedo/UndoRedoManager.ts';
import { TiptapUndoRedoCommand } from '../TiptapUndoRedo/TiptapUndoRedoCommand.ts';

const TextEditor = ({
  placeholder,
  onChange,
  inputClassName,
  value,
  columnType,
  rootClassName,
  userPathname,
  uploadFileOptions,
  isPreview = false,
  editable = true,
  variables,
  onSetUsedVariables,
  ...editorOptions
}: TextEditorProps) => {
  const { setActiveEditor } = useContext(ActiveEditorContext);
  const editorRef = useRef(
    useTextEditor({
      placeholder,
      onChange,
      value,
      tab: isPreview ? 'preview' : 'editor',
      editable,
      userPathname,
      uploadFileOptions,
      parseOptions: {
        preserveWhitespace: 'full',
      },
      variables,
      onSetUsedVariables,
      ...editorOptions,
    })
  );

  const editor = editorRef.current;
  const undoRedoManager = UndoRedoManager.getUndoRedoManager();

  useEffect(() => {
    if (editor && editor.getHTML() !== value) {
      editor.commands.setContent(value || '', false);
    }
  }, [value, editor]);

  useEffect(() => {
    if (!editor) return;

    const updateUndoRedoState = () => {
      if (!editor.can().undo() && !editor.can().redo()) return;

      undoRedoManager.pushUndoRedoCommands(new TiptapUndoRedoCommand(editor));
    };

    editor.on('update', updateUndoRedoState);

    return () => {
      editor.off('update', updateUndoRedoState);
    };
  }, [editor]);

  useEffect(() => {
    const shouldLoadEditor = editable && editor && columnType !== 'number' && !isPreview;
    if (shouldLoadEditor) {
      setActiveEditor(editor);
    }
  }, [editor, setActiveEditor, editable, columnType, isPreview]);

  if (!editable) {
    return <EditorContent editor={editor} className={inputClassName} />;
  }

  return (
    <div className={rootClassName}>
      <EditorContent editor={editor} className={inputClassName} />
    </div>
  );
};

export default TextEditor;
