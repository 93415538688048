import { Editor } from '@tiptap/react';
import { Level } from '@tiptap/extension-heading';
import { Fragment, MouseEvent, useMemo, useState } from 'react';
import Icon from '../components/Icon';
import { PyMuiButton, PyMuiMenu, PyMuiMenuItem } from '../components/generalComponents';
import IcoArrowDropDownRounded from '../../../../icon/icons/IcoArrowDropDownRounded';
import { DEFAULT_SIZE, HEADING_FONT_SIZES } from 'components/editor/grid/reduxStore/defaultTextDefaultValue';
import { Box } from '@mui/material';
import useFeatureFlag from '../../../../../hooks/useFeatureFlag.ts';
import { FeatureFlags } from '../../../../../utils/featureFlags.ts';
import { EditorDefaultSidePanelType, setActiveSidePanel } from '../../../grid/reduxStore/blockStyleSettingsSlice.ts';
import { useAppDispatch } from '../../../grid/reduxStore/Store.ts';
import { useTranslation } from 'react-i18next';
import { useSpotStyleChecker } from '../hooks/useSpotStyleChecker.ts';

export const headingLevels: Level[] = [1, 2, 3, 4];
export const isHeadingActive = (editor: Editor) => headingLevels.some((level) => editor.isActive('heading', { level }));

type Props = {
  editor: Editor;
};

const Heading = ({ editor }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isSpotStyleActive, selectedLevel, setSelectedLevel } = useSpotStyleChecker(editor);
  const isTextDefaultAvailable = useFeatureFlag([FeatureFlags.spTextDesignDefault])[FeatureFlags.spTextDesignDefault];

  const selectedLevelLabel = useMemo(() => {
    if (selectedLevel === undefined) return '';
    const label = selectedLevel ? `Heading ${selectedLevel}` : t('editor.toolbar.normal_text');
    return isSpotStyleActive ? `${label}*` : label;
  }, [selectedLevel, isSpotStyleActive]);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectHeading = (level: Level) => {
    editor.chain().focus().toggleHeading({ level }).run();
    setSelectedLevel(level);
    handleCloseMenu();
  };

  const handleDefaultStyleClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.DEFAULT_STYLE_SETTINGS_PANEL }));
  };

  const handleSelectNormalText = () => {
    editor.chain().focus().setParagraph().run();
    setSelectedLevel(0);
    handleCloseMenu();
  };

  return (
    <Box component="span" display="flex" alignItems="center" alignSelf="stretch" flexDirection="row" justifyContent="flex-start">
      <PyMuiButton
        testId="heading-button"
        isMenuOpen={Boolean(anchorEl)}
        onClickAction={handleOpenMenu}
        isActive={isHeadingActive(editor)}
        width={144}
      >
        <span style={{ fontSize: 12, minWidth: 112, textAlign: 'left' }}>{selectedLevelLabel}</span>
        <Icon>
          <IcoArrowDropDownRounded />
        </Icon>
      </PyMuiButton>
      <PyMuiMenu
        testId="heading-menu"
        id="select-heading-menu"
        menuListProps="select-heading-button"
        anchorEl={anchorEl}
        isMenuOpen={Boolean(anchorEl)}
        handleClose={handleCloseMenu}
      >
        <PyMuiMenuItem
          testId="normal-text"
          menuItem={t('editor.toolbar.normal_text')}
          onClick={handleSelectNormalText}
          fontSize={parseInt(DEFAULT_SIZE)}
        />
        {headingLevels.map((level, index, arr) => (
          <PyMuiMenuItem
            testId={`heading-menu-item-${level}`}
            key={level}
            onClick={() => handleSelectHeading(level)}
            isActive={editor.isActive('heading', { level })}
            fontSize={HEADING_FONT_SIZES[level]}
            isFontWeightBold={level !== arr[arr.length - 1]}
            menuItem={`Heading ${level}`}
          />
        ))}
        {isTextDefaultAvailable && (
          <Fragment>
            <li
              style={{
                backgroundColor: 'rgba(0, 150, 136, 0.12)',
                borderTop: '1px dashed #009688',
                borderBottom: '1px dashed #009688',
                height: '8px',
                display: 'block',
              }}
            ></li>
            <PyMuiMenuItem
              testId="edit-default-style"
              menuItem={t('editor.toolbar.editor_default_style')}
              onClick={handleDefaultStyleClick}
            />
          </Fragment>
        )}
      </PyMuiMenu>
    </Box>
  );
};

export default Heading;
