import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CreateYourWorkspaceFormFieldValues,
  SubmitHandler,
  transformCreateYourWorkspaceFieldValuesToApiPayload,
  useCreateYourWorkspaceForm,
} from './useCreateYourWorkspaceForm';
import FormField from '../settings/utils/FormField';
import { useCallback, useEffect } from 'react';
import { GenericApiResponse, UpdateWorkspaceEntity } from 'services/api/account/interfaces';
import useUpdateWorkspaceMutation from 'services/api/account/hooks/useUpdateWorkspaceMutation';

interface CreateYourWorkspaceFormProps {
  onSuccess: () => void;
  onFailure: (response: GenericApiResponse) => void;
  initialData?: UpdateWorkspaceEntity;
}

const CreateYourWorkspaceForm = ({ onSuccess, onFailure, initialData }: CreateYourWorkspaceFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, watch, setValue } = useCreateYourWorkspaceForm(initialData);
  const { updateWorkspace } = useUpdateWorkspaceMutation();

  const companyName = watch('companyName');

  useEffect(() => {
    if (companyName) {
      const sanitizedSubdomain = companyName.toLowerCase().replace(/[^a-z0-9]/g, '');
      setValue('subdomainName', sanitizedSubdomain);
    }
  }, [companyName, setValue]);

  const onSubmit: SubmitHandler = useCallback(
    async (submitData) => {
      const payload = transformCreateYourWorkspaceFieldValuesToApiPayload(submitData);
      const response = await updateWorkspace(payload);
      if (!response.state) {
        onFailure(response);
        return;
      }
      onSuccess();
    },
    [updateWorkspace, onSuccess, onFailure]
  );

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} data-testid="accept-invitation-form" sx={{ minWidth: '408px' }}>
      <Stack direction="column" sx={{ gap: 3 }}>
        <Stack direction="row" gap={2} justifyContent="space-between" alignContent="center">
          <FormField<CreateYourWorkspaceFormFieldValues>
            id="companyName"
            label={t('signup.create_your_workspace.company_name')}
            placeholder={t('signup.create_your_workspace.company_name')}
            control={control}
            rules={{
              required: t('required_field'),
              maxLength: { value: 100, message: 'Must be max 100 characters' },
            }}
          />
        </Stack>
        <Stack direction="row" gap={2} alignContent="center" alignItems="center" justifyContent="center">
          <FormField<CreateYourWorkspaceFormFieldValues>
            id="subdomainName"
            label={t('signup.create_your_workspace.subdomain_name')}
            placeholder={t('signup.create_your_workspace.subdomain_name')}
            control={control}
            rules={{
              required: t('required_field'),
              maxLength: { value: 100, message: 'Must be max 100 characters' },
            }}
          />
          <Typography variant="body1" fontWeight="400">
            {t('signup.url')}
          </Typography>
        </Stack>
        <Box pt={3}>
          <Button type="submit" variant="contained" size="large" data-testid="create-your-workspace-form-submit" sx={{ width: '100%' }}>
            {t('continue')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateYourWorkspaceForm;
