import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item';
import FontFamily from '@tiptap/extension-font-family';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Blockquote from '@tiptap/extension-blockquote';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import TextAlign from '@tiptap/extension-text-align';
import Color from '@tiptap/extension-color';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';

import { FontSize } from '../extensions/font-size';
import { BulletList } from '../extensions/bullet-list';
import { OrderedList } from '../extensions/ordered-list';
import { LineHeight } from '../extensions/line-height';
import { EditorVariables, PreviewVariables } from '../extensions/variables';
import { VariableEntity } from '../../../../../services/entities/VariablesEntity';
import { UsedVariable } from '../../../SidePanel/Variables/interfaces';
import { ClearFormatting } from '../extensions/clear-formatting';
import { editorConfig } from '../../../helpers/config';
import { EditorLink, PreviewLink } from '../extensions/link';
import { SelectionHighlight } from '../extensions/selection-highlight';

const staticExtensions = [
  TextStyle,
  FontFamily,
  Blockquote,
  Bold,
  Underline,
  BulletList,
  Code,
  CodeBlock,
  Document,
  Dropcursor,
  Gapcursor,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  Text,
  FontSize,
  LineHeight,
  ClearFormatting,
  Subscript,
  Superscript,
  TextAlign.configure({ types: [Paragraph.name, Heading.name] }),
  Highlight,
  Color,
];

type TiptapExtensionsConfig = {
  variables?: VariableEntity[];
  onSetUsedVariables?: (usedVariables: UsedVariable[]) => void;
  isPreview?: boolean;
};

export const getTipTapExtensions = ({ variables, isPreview = false, onSetUsedVariables }: TiptapExtensionsConfig) => {
  const VariablesExtensions = isPreview ? PreviewVariables : EditorVariables;

  const EditorOnlyExtensions = [
    Placeholder.configure({
      placeholder: editorConfig.placeholderText,
      showOnlyWhenEditable: false,
    }),
    SelectionHighlight,
    EditorLink,
  ];
  const PreviewOnlyExtensions = [PreviewLink];
  return [
    ...staticExtensions,

    VariablesExtensions.configure({ variables, onSetUsedVariables }),
    ...(isPreview ? PreviewOnlyExtensions : EditorOnlyExtensions),
  ];
};

export const setTipTapEditorConfig = (editor: ReturnType<typeof useEditor> | null, editable: boolean, tab: 'editor' | 'preview') => {
  if (!editor) return;

  const className = tab === 'editor' ? 'py-mui-tiptap-input' : 'py-mui-tiptap-input py-mui-tiptap-input-preview';

  editor.setOptions({
    editable: tab === 'editor' && editable,
    editorProps: {
      attributes: {
        class: className,
      },
    },
  });
};
