import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../../../grid/reduxStore/Store';

import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IcoLock from '../../../../../icon/icons/IcoLock';
import IcoLockOpen from '../../../../../icon/icons/IcoLockOpen';

import {
  EditorDefaultSidePanelType,
  setActiveSidePanel,
  setIsAdvancedSpacingModelOpen,
} from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { selectSectionIdByBlockId } from 'components/editor/grid/reduxStore/editorSlice';

import {
  SecondaryMenu,
  SecondaryMenuSectionTitle,
  ColorPickerSection,
  NumInputBoxTwoCol,
  TextInputBoxSingleCol,
  DropDownSection,
  SecondaryMenuTrigger,
  NumberInputBoxSingleCol,
} from '../../../component';

import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { useBlockStyleSettings } from '../../../../hooks/useBlockSettings';

import { getBlockSidesFromString } from '../../../../GridDndEditor/Block/Helper/BlockSides';
import { cleanUrl } from '../../../../../../utils/cleanUrl';
import { useBlockContentWithSectionId } from 'components/editor/Sections/useBlockContent';
import { useAspectRatio } from './useAspectRatio';

export function ImageBlockSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    getBlockStyle,
    displayChange,
    changeBlockBorderColor,
    changeBlockBorderSide,
    changeBorderRadius,
    changeBorderWidth,
    changeAspectRatioLock,
  } = useBlockStyleSettings();

  const { selectedBlockIdByIcon } = useContext(SelectionContext);
  const blockId = selectedBlockIdByIcon as string;
  const sectionId = useSelector((state: RootState) => selectSectionIdByBlockId(state, blockId));
  const blockContent = useBlockContentWithSectionId(sectionId ?? '', blockId);

  const [displaySectionValue, setDisplaySectionValue] = useState({ opacity: 0, imageAlt: '', imageLink: '' });

  const {
    borderColor = '#ffffffff',
    currentBorderSide = [],
    borderRadius = 0,
    borderWidth = 0,
    opacity,
    imageAlt,
    imageLink,
    imageAspectRatioLock,
  } = getBlockStyle() || {};

  const { handleImageDimensionChange } = useAspectRatio({
    imageAspectRatioLock: imageAspectRatioLock ?? false,
    blockContent: blockContent,
    sectionId: sectionId ?? '',
    blockId: blockId,
  });

  useEffect(() => {
    if (selectedBlockIdByIcon) {
      setDisplaySectionValue({
        ...displaySectionValue,
        opacity: opacity ? opacity : 0,
        imageAlt: imageAlt ? imageAlt : '',
        imageLink: imageLink ? imageLink : '',
      });
    }
  }, [selectedBlockIdByIcon]);

  const handleOpacityChange = (val) => {
    setDisplaySectionValue((prev) => ({ ...prev, opacity: val }));
    displayChange(selectedBlockIdByIcon, { ...displaySectionValue, opacity: val });
  };

  const handleImageWidthChange = (val: number) => {
    handleImageDimensionChange('width', val);
  };

  const handleImageHeightChange = (val: number) => {
    handleImageDimensionChange('height', val);
  };

  const handleImageAltChange = (val) => {
    setDisplaySectionValue((prev) => ({ ...prev, imageAlt: val }));
    displayChange(selectedBlockIdByIcon, { ...displaySectionValue, imageAlt: val });
  };

  const handleImageLinkChange = (val: string) => {
    setDisplaySectionValue((prev) => ({ ...prev, imageLink: val }));
    displayChange(selectedBlockIdByIcon, { ...displaySectionValue, imageLink: val });
  };

  const handleImageLinkBlur = (val: string) => {
    const newVal = cleanUrl(val);
    setDisplaySectionValue((prev) => ({ ...prev, imageLink: newVal }));
    displayChange(selectedBlockIdByIcon, { ...displaySectionValue, imageLink: newVal });
  };

  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  return (
    <SecondaryMenu
      testId={'image-block-settings-component'}
      menuTitle={t('document.grid.image.design.title')}
      backButtonOnClick={handleBackButtonClick}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.image.design.display')}</SecondaryMenuSectionTitle>
        <NumInputBoxTwoCol
          title={t('document.grid.image.design.opacity')}
          ariaLabel="%"
          inputValue={displaySectionValue.opacity}
          onChange={handleOpacityChange}
          limitedNum={100}
          disableNegativeNum
          testId="image-opacity-input"
        />

        <Stack gap={1} direction="row" alignItems="end">
          <Stack direction="row" gap={2}>
            <NumberInputBoxSingleCol
              title={t('document.grid.image.design.width')}
              onChange={handleImageWidthChange}
              value={blockContent?.blockConfig.width}
              endAdornmentText="px"
              testId="image-width-input"
            />
            <NumberInputBoxSingleCol
              title={t('document.grid.image.design.height')}
              onChange={handleImageHeightChange}
              value={blockContent?.blockConfig.height}
              endAdornmentText="px"
              testId="image-height-input"
            />
          </Stack>

          <IconButton data-testid="aspect-ratio-lock-button" onClick={() => changeAspectRatioLock(!imageAspectRatioLock)}>
            {imageAspectRatioLock ? <IcoLock /> : <IcoLockOpen />}
          </IconButton>
        </Stack>

        <TextInputBoxSingleCol
          title={t('document.grid.image.design.image_alt_text')}
          descriptionText={t('document.grid.image.design.image_alt_text_dec')}
          onChange={handleImageAltChange}
          value={displaySectionValue.imageAlt}
          testId="image-alt-input"
        />

        <TextInputBoxSingleCol
          title={t('document.grid.image.design.link')}
          descriptionText={t('document.grid.image.design.link_dec')}
          onChange={handleImageLinkChange}
          onBlur={handleImageLinkBlur}
          value={displaySectionValue.imageLink}
          startAdornmentText={'https://'}
          testId="image-link-input"
        />
      </Stack>
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.image.design.border')}</SecondaryMenuSectionTitle>
        <ColorPickerSection currentColor={borderColor} handleColorChange={changeBlockBorderColor} />
        <DropDownSection
          currentSelection={currentBorderSide}
          handleSelectionChange={(borderSideStr) => {
            changeBlockBorderSide(getBlockSidesFromString(borderSideStr));
          }}
          testId={'border-dropdown'}
        />
        <NumInputBoxTwoCol
          inputValue={borderWidth}
          onChange={changeBorderWidth}
          title={t('document.grid.text.design.border_width')}
          testId={'border-width'}
          disableNegativeNum
        />
        <NumInputBoxTwoCol
          title={t('document.grid.text.design.border_radius')}
          inputValue={borderRadius}
          disableNegativeNum
          onChange={changeBorderRadius}
          testId={'border-radius'}
        />

        <SecondaryMenuTrigger
          menuTriggerTitle={t('document.grid.text.advanced_spacing.title')}
          menTriggerDesc={t('document.grid.text.advanced_spacing.padding')}
          handleClick={() => {
            dispatch(setIsAdvancedSpacingModelOpen(true));
          }}
        />
      </Stack>
    </SecondaryMenu>
  );
}
