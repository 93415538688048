export class InvalidUrl extends Error {
  constructor() {
    super();
  }
}

export class InvalidSource extends Error {
  constructor() {
    super();
  }
}

export class InvalidVideo extends Error {
  constructor() {
    super();
  }
}
