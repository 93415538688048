import html2canvas from 'html2canvas';

export const drawMagnifying = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
  offscreenCanvas: HTMLCanvasElement,
  e: MouseEvent | React.MouseEvent
) => {
  context.clearRect(0, 0, canvas.width, canvas.height);
  const x = e.clientX;
  const y = e.clientY;

  context.save();
  context.beginPath();
  const magnifyingRadius = canvas.height * 0.1;

  const imageX = x - magnifyingRadius / 2;
  const imageY = y - magnifyingRadius / 2;

  const sourceX = imageX;
  const sourceY = imageY;

  const destinationX = x - magnifyingRadius;
  const destinationY = y - magnifyingRadius;

  context.arc(x, y, magnifyingRadius, 0, Math.PI * 2, true);
  context.strokeStyle = 'white';
  context.lineWidth = 6;

  context.stroke();
  context.closePath();
  context.clip();

  context.drawImage(
    offscreenCanvas,
    sourceX,
    sourceY,
    magnifyingRadius,
    magnifyingRadius,
    destinationX,
    destinationY,
    2 * magnifyingRadius,
    2 * magnifyingRadius
  );

  context.restore();
};

export const renderBackgroundImage = async (): Promise<HTMLCanvasElement> => {
  return await html2canvas(document.body, { scale: 1 });
};
