import { useContext } from 'react';
import { RootState, useAppDispatch } from '../../grid/reduxStore/Store';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { setActiveSidePanel, setIsAdvancedSpacingModelOpen } from '../../grid/reduxStore/blockStyleSettingsSlice';
import { useSectionId } from '../../Sections/SectionIdProvider';
import { useBlockStyleSettings } from '../../hooks/useBlockSettings';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { useSelector } from 'react-redux';

export function useOpenBlockSettings() {
  const dispatch = useAppDispatch();
  const { selectedBlockInfoByIcon, setSelectedBlockInfoByIcon } = useContext(SelectionContext);
  const { activeSidePanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const { addBlock } = useBlockStyleSettings();
  const sectionId = useSectionId();

  const openBlockSettings = (type: GridBlockType, blockId: string | null) => {
    if (selectedBlockInfoByIcon.blockId === blockId && activeSidePanel === GridBlockType[type]) {
      return;
    }

    setSelectedBlockInfoByIcon({ blockId, sectionId });
    dispatch(setActiveSidePanel({ type: GridBlockType[type] }));
    dispatch(setIsAdvancedSpacingModelOpen(false));
    if (blockId) addBlock(blockId);
  };

  return {
    openBlockSettings,
  };
}
