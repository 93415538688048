import { BlockContent } from 'components/editor/grid/reduxStore/editorSlice';
import { useState } from 'react';
import {
  ALLOWED_DECIMAL_PLACES_FOR_DIMENSION,
  useBlockDimensionChangedHandlerWithoutUndoRedo,
} from 'components/editor/hooks/UseBlockDimensionChangedHandler';
import { getRoundedValue } from '../../../../GridDndEditor/gridHelper.tsx';

export function useAspectRatio({
  imageAspectRatioLock,
  blockContent,
  sectionId,
  blockId,
}: {
  imageAspectRatioLock: boolean;
  blockContent: BlockContent | undefined;
  sectionId: string | null;
  blockId: string | null;
}) {
  const { width, height } = blockContent?.blockConfig || { width: 0, height: 0 };
  const [originalAspectRatio] = useState<number | null>(width / height);
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();

  const handleImageDimensionChange = (changedDimension: 'width' | 'height', updatedDimensionValue: number) => {
    if (sectionId && blockId) {
      let newWidth = width;
      let newHeight = height;
      const isWidthChanged = changedDimension === 'width';

      if (imageAspectRatioLock && originalAspectRatio) {
        if (isWidthChanged) {
          newWidth = updatedDimensionValue;
          newHeight = updatedDimensionValue / originalAspectRatio;
        } else {
          newHeight = updatedDimensionValue;
          newWidth = updatedDimensionValue * originalAspectRatio;
        }
      } else {
        if (isWidthChanged) {
          newWidth = updatedDimensionValue;
        } else {
          newHeight = updatedDimensionValue;
        }
      }

      newWidth = getRoundedValue(newWidth, ALLOWED_DECIMAL_PLACES_FOR_DIMENSION);
      newHeight = getRoundedValue(newHeight, ALLOWED_DECIMAL_PLACES_FOR_DIMENSION);
      if (sectionId && blockId) {
        blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, {
          widthPx: newWidth,
          heightPx: newHeight,
        });
      }
    }
  };

  return { originalAspectRatio, handleImageDimensionChange };
}
