import { useTranslation } from 'react-i18next';
import { SelectInputTwoCol } from 'components/editor/SidePanel/component';
import { BlockSides } from '../../../GridDndEditor/Block/Helper/BlockSides';
import { SelectChangeEvent } from '@mui/material';

export type DropDownSectionProps = {
  currentSelection: string[];
  handleSelectionChange: (currentSelection: string[]) => void;
  testId: string;
};

export const DropDownSection = ({ currentSelection = [BlockSides.ALL_SIDES], handleSelectionChange, testId }: DropDownSectionProps) => {
  const { t } = useTranslation();

  const handleSelectionUpdate = (event: SelectChangeEvent<string | string[]>) => {
    const newSelection = Array.isArray(event.target.value) ? event.target.value : [event.target.value];

    if (newSelection.includes(BlockSides.ALL_SIDES)) {
      if (newSelection.length > currentSelection.length || !currentSelection.includes(BlockSides.ALL_SIDES)) {
        handleSelectionChange([BlockSides.TOP, BlockSides.BOTTOM, BlockSides.LEFT, BlockSides.RIGHT]);
      } else {
        handleSelectionChange([]);
      }
    } else {
      handleSelectionChange(newSelection);
    }
  };

  return (
    <SelectInputTwoCol
      menuItems={Object.values(BlockSides)}
      onChange={handleSelectionUpdate}
      testId={testId}
      label={t('document.grid.text.design.border')}
      value={currentSelection}
      isMultiple={true}
    />
  );
};
