import React from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSocketClient } from '../../../providers/SocketContext';
import { AcknowledgmentResponseStatus, ErrorCode, SectionEvents } from '../../../services/socket/SocketEvents';
import { openNotification } from '../../notification';
import { addSection, deleteSection } from '../grid/reduxStore/editorSlice';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { RootState, useAppDispatch } from '../grid/reduxStore/Store';
import { EditorConfig } from '../helpers/config';
import LazyLoadComponent from '../LazyLoad/LazyLoadComponent';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { SectionIdProvider } from './SectionIdProvider';
import { SectionToolbar } from './SectionToolbar';

const Canvas = React.lazy(() => import('./Canvas'));

interface EditorContentProps {
  documentId: string;
  setRef: (node: FroalaEditor | null) => void;
  editorConfig: EditorConfig;
  toolbarButtons?: any;
}

const EditorContent: React.FC<EditorContentProps> = ({ documentId, editorConfig }) => {
  const allSections = useSelector((state: RootState) => state.gridBlockReducer.sections);
  const { socketClient } = useSocketClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { checkDocumentLockStatus } = useDocumentLockedModal();

  const sectionsWithRef = allSections.map((section) => ({
    id: section.id,
    ref: React.createRef<HTMLDivElement>(),
  }));

  /**
   * @deprecated order parameter is no longer used and will be removed in a future version
   */
  const handleAddButtonOnClick = (sectionId: string, order: number) => {
    socketClient.publish(
      SectionEvents.SECTION_ADD,
      { afterSectionId: sectionId, title: t('editor.default_content_section_title'), order },
      (response) => {
        if (checkDocumentLockStatus(response.errorCode)) {
          dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED }));
          return;
        }

        dispatch(
          addSection({
            sectionId: response.content.sectionId,
            title: t('editor.default_content_section_title'),
            afterSectionId: sectionId,
          })
        );
      }
    );
  };

  const enableDelete = sectionsWithRef.length > 1;

  const handleDeleteButtonOnClick = (sectionId: string) => {
    socketClient.publish(SectionEvents.SECTION_DELETE, { sectionId: sectionId }, (response) => {
      if (response.status === AcknowledgmentResponseStatus.OK) {
        dispatch(deleteSection({ sectionId: sectionId }));
      } else {
        if (checkDocumentLockStatus(response.errorCode)) {
          dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED }));
          return;
        }
        openNotification({
          type: 'error',
          title: t('editor.sections.delete_failed'),
          description: '',
          placement: 'top',
        });
      }
    });
  };

  return (
    <>
      {sectionsWithRef.map((section, index) => (
        <SectionIdProvider key={section.id} sectionId={section.id}>
          <LazyLoadComponent className={`lazy-loaded-section lazy-loaded-section-${section.id}`}>
            <Canvas
              data-testid={`section-${section.id}`}
              enableDelete={enableDelete}
              addButtonOnClick={() => handleAddButtonOnClick(section.id, index + 1)}
              key={section.id}
              configOptions={editorConfig}
              deleteButtonOnClick={() => handleDeleteButtonOnClick(section.id)}
              documentId={documentId}
              gridRef={section.ref}
              sectionOrder={index}
            />
          </LazyLoadComponent>
        </SectionIdProvider>
      ))}

      <SectionToolbar
        enableDelete={false}
        addButtonOnClick={() => handleAddButtonOnClick(sectionsWithRef[sectionsWithRef.length - 1].id, sectionsWithRef.length)}
      />
    </>
  );
};

export default EditorContent;
