/**
 * Creates a throttled function that only invokes the provided function at most once per
 * every `wait` milliseconds.
 *
 * @param func The function to throttle
 * @param wait The number of milliseconds to throttle invocations to
 * @returns A throttled version of the provided function
 */
export function throttle<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;
  let lastArgs: Parameters<T> | null = null;
  let lastCall = 0;

  return function throttled(this: any, ...args: Parameters<T>) {
    const now = Date.now();

    if (lastCall && now < lastCall + wait) {
      // If the function was called within the wait period,
      // store the arguments and set up a timeout
      lastArgs = args;
      if (!timeout) {
        timeout = setTimeout(() => {
          lastCall = now;
          timeout = null;
          func.apply(this, lastArgs as Parameters<T>);
          lastArgs = null;
        }, wait);
      }
    } else {
      // If enough time has passed, call the function immediately
      lastCall = now;
      func.apply(this, args);
    }
  };
}
