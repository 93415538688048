import useFeatureFlag from 'hooks/useFeatureFlag';
import { useMemo } from 'react';
import { type FeatureFlag } from 'utils/featureFlags';

type FeatureFlaggedProps<T> = {
  flags: FeatureFlag[];
  Component?: React.ComponentType<T>;
  children?: React.ReactNode;
  hideChildrenToo?: boolean;
} & T;

export default function FeatureFlagged<T>({ flags, Component, children, hideChildrenToo = false, ...props }: FeatureFlaggedProps<T>) {
  const featureFlags = useFeatureFlag(flags);
  const isEnabled = useMemo(() => {
    return flags.length > 0 && flags.every((flag) => featureFlags[flag]);
  }, [flags, featureFlags]);

  if (!isEnabled) {
    return hideChildrenToo ? null : children ?? null;
  }

  if (!Component) {
    return children;
  }

  return <Component {...(props as T)}>{children}</Component>;
}
