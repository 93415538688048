import SettingsTheme from 'muiTheme/SettingsTheme';
import { Box, Card, CardContent, Stack, ThemeProvider, Link, Typography } from '@mui/material';
import PageLoader from 'components/page-loader/page-loader';
import ProposifyLogo from '../../images/proposify-logo.png';
import BillingCardHeader from 'pages/settings/utils/BillingCardHeader';
import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import goToCinder from 'utils/goToCinder';
import { CINDER_URLS } from 'utils/cinder-urls';
import AcceptInvitationForm from './AcceptInvitationForm';
import { AcceptInvitationFormFieldValues } from './useAcceptInvitationForm';
import { NotificationsContext } from 'components/notifications/NotificationsProvider';

const AcceptInvitationPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [defaultData, setDefaultData] = useState<AcceptInvitationFormFieldValues>();
  const { setNotifications } = useContext(NotificationsContext);

  const queryParams = new URLSearchParams(search);
  // TODO: Confirm if we are able to request this data easily, or if it opens up too many issues
  // const { data } = useInvitationApiData({ token: queryParams.get('token') || '' });

  useEffect(() => {
    setDefaultData({
      email: queryParams.get('email') || '',
      firstName: '',
      lastName: '',
      password: '',
      receiveEmails: false,
      token: queryParams.get('token') || '',
    });
  }, [search]);

  const hasDocument = !!queryParams.get('documentId');

  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <Box data-testid="accept-invitation-page-container" px={3}>
          <img
            src={ProposifyLogo}
            data-testid="proposify-logo"
            width="120px"
            alt="Proposify Logo"
            style={{ margin: '0 auto', display: 'block', marginTop: '36px', marginBottom: '24px' }}
          />
          <Card
            data-testid="accept-invitation-page-content"
            sx={{
              borderRadius: '24px',
              boxShadow: 'none',
              padding: '40px 40px 16px',
              margin: '0 auto 36px',
              maxWidth: '854px',
            }}
          >
            <BillingCardHeader
              title={t('signup.accept_invitation.create_free_account')}
              subheader={
                <Trans
                  i18nKey="signup.accept_invitation.invite"
                  components={{
                    bold: <strong />,
                  }}
                  values={{
                    name: 'Someone',
                    company_name: 'Company',
                  }}
                />
              }
              subheaderColor="text.primary"
            />
            <CardContent sx={{ pt: 2, px: 0 }}>
              <Stack direction={{ sm: 'column', md: hasDocument ? 'row' : 'column' }} gap={6}>
                <AcceptInvitationForm
                  initialData={defaultData}
                  onSuccess={() => {
                    goToCinder(CINDER_URLS.dashboard);
                  }}
                  onFailure={(response) => {
                    setNotifications({
                      severity: 'error',
                      content: response.message || t('signup.accept_invitation.generic_error'),
                      autoHide: false,
                    });
                  }}
                />
              </Stack>
            </CardContent>
          </Card>

          <Box sx={{ textAlign: 'center', margin: '40px auto 0', pb: 5 }}>
            <Typography variant="body1" color="text.primary">
              <Trans
                i18nKey="signup.accept_invitation.already_have_account"
                components={{
                  signIn: <Link href="/login" title="Sign in" target="_self" />,
                }}
              />
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </PageLoader>
  );
};

export default AcceptInvitationPage;
