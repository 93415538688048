import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material';
import { defaultFontFamily } from 'muiTheme/lightTheme';

const AccordionSummeryStyled = styled(AccordionSummary)(() => ({
  padding: '4px 0',
  '&-content.Mui-expanded': {
    margin: '10px 0',
  },
  '& .MuiAccordionSummary-content': {
    margin: '10px 0',
  },
  '&.Mui-expanded': {
    margin: '0 0 4px',
    minHeight: '48px',
  },
}));

const AccordionStyled = styled(Accordion)(() => ({
  border: 'none',
  boxShadow: 'none',
  width: '100%',
  marginBottom: '0',
  '&.Mui-expanded::after': {
    position: 'absolute',
    left: 0,
    bottom: '0px',
    right: 0,
    height: '1px',
    content: '""',
    opacity: 1,
    backgroundColor: 'rgba(0, 40, 58, 0.15)',
    transition: 'opacity 150mscubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150mscubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&.Mui-expanded': {
    margin: 0,
    '&::before': {
      opacity: 1,
    },
  },
}));

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginLeft: 'auto',
  color: 'rgba(0, 40, 58, 0.60)',
}));

const BoxItemStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '16px',
}));

const AccordionDetailsStyled = styled(AccordionDetails)(() => ({
  padding: '0',
}));

const fontSettings = {
  fontFamily: defaultFontFamily,
  fontSize: '14px',
  lineHeight: '20px',
};

const TypographyStyledTitle = styled(Typography)(() => ({
  ...fontSettings,
  color: '#71787E',
}));

const TypographyStyledLabel = styled(Typography)(() => ({
  ...fontSettings,
  color: '#353636',
}));

type AccordionProps = {
  accordion: {
    title: string;
    content: {
      componentTitle: string;
      components: React.ReactNode;
    }[];
  };
  expanded: string;
  onChange: (value: string) => void;
};

const SidePanelAccordion = ({ accordion, expanded, onChange }: AccordionProps) => {
  const accordionTitle = accordion.title.toLowerCase().replace(/\s/g, '-');

  return (
    <AccordionStyled expanded={expanded === accordionTitle} onChange={() => onChange(accordionTitle)}>
      <AccordionSummeryStyled expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        <TypographyStyledTitle data-testid={accordionTitle}>{`${accordion.title}`}</TypographyStyledTitle>
      </AccordionSummeryStyled>
      <AccordionDetailsStyled>
        {accordion?.content?.map((item, indexItem) => (
          <BoxItemStyled key={indexItem}>
            <TypographyStyledLabel data-testid="accordion-item-title">{`${item.componentTitle}`}</TypographyStyledLabel>
            <BoxStyled>{item.components}</BoxStyled>
          </BoxItemStyled>
        ))}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

export default SidePanelAccordion;
