import { useTextBlockSelected } from './useTextBlockSelected';

export function EditorFroalaToolbar() {
  const isSelectedBlockText = useTextBlockSelected();

  const shouldShowToolbar = isSelectedBlockText ? 'active' : 'hidden';

  return (
    <div
      data-testid="editor-froala-toolbar"
      id="editor-froala-toolbar"
      style={{ width: '100%' }}
      className={`editor-froala-toolbar ${shouldShowToolbar}`}
    />
  );
}
