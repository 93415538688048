import { createContext, useContext, useState } from 'react';

type ProviderType = {
  children: React.ReactElement;
  contentId: string;
};

type Values = {
  contentId: string;
  imageBlockContent?: string[];
  addImageBlockContent?: (image: string) => void;
};

export const EditorContentContext = createContext<Values>({} as Values);

export function useEditorContent(): Values {
  const values = useContext(EditorContentContext);
  if (values === undefined) {
    throw new Error('useEditorContent must be used within a EditorContentProvider');
  }
  return values;
}

export function EditorContentProvider({ contentId, children }: ProviderType) {
  const [imageBlockContent, setImageBlockContent] = useState<string[]>([]);
  const addImageBlockContent = (image: string) => {
    if (!imageBlockContent.includes(image)) {
      setImageBlockContent([...imageBlockContent, image]);
    }
  };
  const context = { contentId, imageBlockContent, addImageBlockContent };
  return <EditorContentContext.Provider value={context}>{children}</EditorContentContext.Provider>;
}
