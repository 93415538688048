import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store.ts';
import { CSSObject } from '@emotion/react';
import {
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_FAMILY,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_SIZE,
  HEADING_FONT_SIZES,
} from './defaultTextDefaultValue.ts';

type TextDefaultStylingType = CSSObject;
export type TextDefaultStylingState = Partial<Record<TextDefaultTypes, TextDefaultStylingType>>;

enum TextDefaultTypes {
  HEADING_1 = 'h1',
  HEADING_2 = 'h2',
  HEADING_3 = 'h3',
  HEADING_4 = 'h4',
  NORMAL = 'p',
}

const defaultSpotStyle: TextDefaultStylingType = {
  italic: false,
  underline: false,
};

const sharedStyles: TextDefaultStylingType = {
  fontFamily: DEFAULT_FONT_FAMILY,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: DEFAULT_LINE_HEIGHT,
  color: DEFAULT_FONT_COLOR,
  textAlign: 'left',
};

export const tiptapTextDefaultStyle: TextDefaultStylingState = {
  [TextDefaultTypes.NORMAL]: {
    ...sharedStyles,
    ...defaultSpotStyle,
    fontSize: DEFAULT_SIZE,
  },
  [TextDefaultTypes.HEADING_1]: {
    ...sharedStyles,
    fontWeight: 'bold',
    ...defaultSpotStyle,
    fontSize: HEADING_FONT_SIZES[1],
  },
  [TextDefaultTypes.HEADING_2]: {
    ...sharedStyles,
    fontWeight: 'bold',
    ...defaultSpotStyle,
    fontSize: HEADING_FONT_SIZES[2],
  },
  [TextDefaultTypes.HEADING_3]: {
    ...sharedStyles,
    ...defaultSpotStyle,
    fontSize: HEADING_FONT_SIZES[3],
  },
  [TextDefaultTypes.HEADING_4]: {
    ...sharedStyles,
    ...defaultSpotStyle,
    fontSize: HEADING_FONT_SIZES[4],
  },
};

const textDefaultStylingSlice = createSlice({
  name: 'editor-text-default-styling',
  initialState: tiptapTextDefaultStyle,
  reducers: {
    setTextDefaultStyling: (state, action: PayloadAction<TextDefaultStylingState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setTextDefaultStyling } = textDefaultStylingSlice.actions;
export const textDefaultStylingReducer = textDefaultStylingSlice.reducer;
export const selectAllTextDefaultStyles = (state: RootState) => state.textDefaultStylingReducer;
