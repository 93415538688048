import { Grid, styled } from '@mui/material';
import { COLORS, SPACING } from '../../../../../../muiTheme/MuiDataGrid';
import TooltipInputRedux from '../components/TooltipInputRedux';
import { TableRowType } from 'components/editor/grid/reduxStore/table.types';
import TextEditor from '../../../TiptapEditor/components/TextEditor.tsx';
import { TipTapPricingFooterTitle } from '../../../TiptapEditor/components/TipTapPricingFooterTitle.tsx';
import { TITLE_KEY } from './PricingTableTotalsFooter.tsx';

type SharedToggledInputProps = {
  children?: React.ReactNode;
  displayedValue: string;
  clickedCell: string | null;
  inputRefs: React.MutableRefObject<any>;
  inputType?: 'text' | 'number';
  isHidden?: boolean;
  isRowActive: boolean;
  onCellClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onInputChange: (value: string) => void;
  row: TableRowType;
  value: string | number;
};

export const ToggledInputWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isHidden',
})(({ isHidden = false }: { isHidden?: boolean }) => ({
  backgroundColor: isHidden ? COLORS.greyedBackgroundColor : 'transparent',
  lineHeight: isHidden ? 'inherit' : 1,
  paddingRight: SPACING.sm,
  paddingLeft: SPACING.sm,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& > div': {
    width: '100%',
  },
}));

export const TitleTextWrapper = styled('span')({
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
});

const ToggledInput = ({
  children,
  displayedValue,
  clickedCell,
  inputRefs,
  inputType = 'number',
  isHidden = false,
  isRowActive,
  onCellClick,
  onInputChange,
  row,
  value,
}: SharedToggledInputProps) => {
  const isTipTapEditorActive = inputType === 'text';

  const renderComponent = () => {
    if (isTipTapEditorActive) {
      return (
        <div data-testid="tiptap-wrapper-footer">
          {clickedCell === `${row.id}-${TITLE_KEY}` ? (
            <TipTapPricingFooterTitle id={row.id} hasFocus={clickedCell === `${row.id}-${TITLE_KEY}`} />
          ) : (
            <TextEditor editable={false} hasFocus={clickedCell === `${row.id}-${TITLE_KEY}`} value={displayedValue} />
          )}
        </div>
      );
    } else {
      return isRowActive ? (
        <TooltipInputRedux
          inputRefs={inputRefs}
          inputType={inputType}
          isHidden={isHidden}
          onInputChange={onInputChange}
          rowId={row.id}
          rowType={row.rowType}
          value={inputType === 'number' ? Number(value) : value}
        >
          {children}
        </TooltipInputRedux>
      ) : (
        <TitleTextWrapper>{displayedValue}</TitleTextWrapper>
      );
    }
  };

  return (
    <ToggledInputWrapper
      className={isRowActive ? 'py-active-row' : ''}
      data-testid={`${row.title?.toLowerCase()}-row-tooltip-wrapper`}
      isHidden={isHidden}
      onClick={onCellClick}
    >
      {renderComponent()}
    </ToggledInputWrapper>
  );
};

export default ToggledInput;
